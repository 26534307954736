import { CopyButtonProps, VisibilityProps } from '@/shared/props';
import { StyledCol, StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledClipboardLayout = styled(StyledCol)`
  width: 100%;
`;

export const StyledClipboardContent = styled(StyledCol)`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.cardsBgColor};
  border: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  width: 100%;
  align-items: flex-start;
  padding: 24px;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  box-sizing: border-box;
`;

export const StyledCopyButton = styled.button<CopyButtonProps>`
  background: ${(props) => props.theme.copyButtonBG};
  border: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  color: ${(props) => (props.isCopied ? props.theme.successStatus : props.theme.textColor)};
  cursor: ${(props) => (props.isCopied ? 'default' : 'pointer')};
  text-align: center;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  padding: 16px;
`;

export const StyledEditIcons = styled(StyledRow)<VisibilityProps>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  position: absolute;
  justify-content: end;
  width: 100%;
  padding: 24px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
`;
