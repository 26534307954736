import React, { ReactElement, useEffect, useState } from 'react';
import { Tab, U1TabsProps } from './Tabs.consts';
import { U1Tabs } from '@u1/react-a11y-hooks';
import { isMobileOnly } from 'react-device-detect';
import { StyledTabs } from './Tabs.styles';

export const Tabs: React.FC<U1TabsProps> = ({
  tabs,
  onChange,
  $customStyle,
  children,
}: U1TabsProps) => {
  const getActiveKey = () => {
    const path = location.pathname.split('/').pop();
    const index = tabs.findIndex((tab) => tab.key === path) + 1;
    return String(index > 0 ? index : 1);
  };

  const [activeKey, setActiveKey] = useState(getActiveKey());

  useEffect(() => {
    if (isMobileOnly) {
      // quickfix for mobile only - prevent Antd from blocking scrolling
      const containerToRemove = document.querySelector('.ant-tabs-nav-wrap');
      containerToRemove?.replaceWith(...containerToRemove.childNodes);

      // only for mobile - scroll to active tab
      const activeElement = document.querySelector(
        '.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span'
      );
      activeElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, []);

  useEffect(() => {
    setActiveKey(getActiveKey());
  }, [location.pathname, tabs]);

  const setCurrentTab = (tab: Tab) => {
    window.history.pushState({}, '', tab.to);
    const currActiveKey = tabs?.findIndex((currTab) => currTab.key === tab.key) + 1;
    setActiveKey(String(currActiveKey));
  };

  const tabItems = tabs.map((tab, i) => ({
    key: String(i + 1),
    label: React.cloneElement(tab.label as ReactElement, {
      onClick: () => setCurrentTab(tab),
    }),
    children: tab.children,
  }));

  return (
    <U1Tabs>
      <StyledTabs
        activeKey={activeKey}
        items={tabItems}
        onChange={onChange}
        $customStyle={$customStyle}
      />
      {children}
    </U1Tabs>
  );
};
