import { PAGES } from '../consts';
import { Icon } from './Icon';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../styles';
/* UnComment when Nir will decide about the UPPER/Pascal case */
// import { u1Logo } from '@/assets/images/landingPage/icons';
import { IconProps } from '../props';
import { U1Icon } from '@/assets/images/icons';
import { buildLangPath } from '../utils';

export const U1LandingPageLogo: React.FC<IconProps> = ({ $maxWidth, $height }: IconProps) => {
  const { t } = useTranslation();

  return (
    <StyledLink href={buildLangPath(PAGES.SHARED.DEFAULT)} aria-label={t('landing_page.logo')}>
      {/* UnComment when Nir will decide about the UPPER/Pascal case */}
      {/* <Icon $maxWidth={$maxWidth} $height={$height} $src={u1Logo} $alt={t('landing_page.logo')} /> */}
      <Icon $maxWidth={$maxWidth} $height={$height} $src={U1Icon} $alt={t('landing_page.logo')} />
    </StyledLink>
  );
};
