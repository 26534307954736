import { SendCredentialsProps } from '@/Views/AfterLogin/Main/SearchPanel/Panel.consts';
import { ConfirmModal } from '@/shared/components/Modals/ConfirmModal/ConfirmModal';
import { StyledSecondaryText } from '@/shared/styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useVendorsStore } from '@/stores/VendorsStore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SendCredentials: React.FC<SendCredentialsProps> = ({
  deal,
  trigger,
}: SendCredentialsProps) => {
  const dealStore = useDealStore();
  const vendor = useVendorsStore();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (vendor?.credentialsSent) {
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  }, [vendor.credentialsSent]);

  return (
    <ConfirmModal
      onConfirm={() => {
        const id = deal?.id ?? dealStore?.id;
        id && vendor.sendCredentials(id);
      }}
      title={t('vendor.deals.create_deal.payment.view_deal.action.send_credentials')}
      confirmBtnText={t('vendor.deals.create_deal.payment.view_deal.action.send_credentials.send')}
      cancelBtnText={t('cancel')}
      isOpen={isOpen}
      onCancel={() => setIsOpen(false)}
      trigger={React.cloneElement(trigger, {
        onClick: () => setIsOpen(true),
      })}
    >
      <StyledSecondaryText fontWeight={400} fontSize={16}>
        {vendor.credentialsSent ? (
          <span data-u1-status>{t('sent_successfully')}</span>
        ) : (
          `${t('vendor.deals.create_deal.payment.view_deal.action.send_credentials.content')} ${
            deal?.admin ?? dealStore.client?.email
          }`
        )}
      </StyledSecondaryText>
    </ConfirmModal>
  );
};
