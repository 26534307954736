import { ButtonProps } from '@/shared/props';
import { v4 as uuidv4 } from 'uuid';
import { StyledGradientButton } from './U1Button.styles';
import { U1Link } from '@u1/react-a11y-hooks';

export const GradientBorderButton: React.FC<ButtonProps> = ({
  text,
  $width,
  $height,
  $padding,
  onClick,
  disabled,
  htmlType,
  dataTestId,
  triggerRef: a11yRef,
  isLink,
  isrounded,
}: ButtonProps) => {
  const dialogTrigger = a11yRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': a11yRef } : {};

  const props = {
    'data-testid': dataTestId,
    key: uuidv4(),
    data: text,
    $width,
    $height,
    $padding,
    onClick,
    disabled,
    htmlType,
    isrounded,
    isLink,
    className: `ant-custom-${isLink ? `link` : `btn`}`,
    ...dialogTrigger,
  };

  return isLink ? (
    <U1Link>
      <StyledGradientButton data-u1-link="link" {...props} />
    </U1Link>
  ) : (
    <StyledGradientButton {...props} />
  );
};
