import { EditAdminForm } from '@/shared/components/Forms/AdminsForms/EditAdmin/EditAdmin';
import { AdminPermission } from '../../../Admin.consts';
import { EditUserModal } from '@/shared/components/Modals/UserActionModals/EditUserModal';
import { DeleteUserModal } from '@/shared/components/Modals/UserActionModals/DeleteUserModal';
import { USER_TYPE } from '@/shared/components/Modals/UserActionModals/UserModals.consts';
import { AdminDetails } from '@/stores/Stores.consts';

export type AdminActionsProps = {
  admin: AdminDetails;
  getAdmins: () => AdminDetails[];
};

export const getAdminActions = ({ admin, getAdmins }: AdminActionsProps) => [
  {
    permission: AdminPermission.UPDATE_ADMIN,
    component: <EditUserModal form={<EditAdminForm selectedUser={admin} />} />,
  },
  {
    permission: AdminPermission.DELETE_ADMIN,
    component: (
      <DeleteUserModal
        id={admin.id}
        userName={admin.userName}
        userType={USER_TYPE.ADMIN}
        getUsers={getAdmins}
      />
    ),
  },
];
