import React from 'react';
import { useAdminsStore } from '@/stores/AdminsStore';
import { AdminActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { ProtectedActionsDropdown } from '@/shared/components/Dropdown/ProtectedActionsDropdown';
import { getAdminActions } from './AdminActions.consts';

export const AdminActionsDropdown: React.FC<AdminActionsProps> = ({
  setIsOpen,
  admin,
}: AdminActionsProps) => {
  const getAdmins = useAdminsStore((state) => state.getAdmins);

  return (
    <ProtectedActionsDropdown
      actions={getAdminActions({ admin, getAdmins })}
      setIsOpen={setIsOpen}
    />
  );
};
