import useDeviceDetect from '@/hooks/useDeviceDetect';
import { ThemeSwitcher } from '@/shared/components/ToggleButton/ThemeSwitcher/ThemeSwitcher';
import { StyledRow } from '@/shared/styles';
import { LangSwitch } from '@/Views/BeforeLogin/Header/Lang/LangSwitch';
import { StyledLPHeader, StyledLPHeaderContent } from './Header.styles';
import { StyledLandingContainer } from '../../LandingPages.styles';
import { U1LandingPageLogo } from '@/shared/components/U1LandingPageLogo';
import { MainMenu } from '@/Views/BeforeLogin/Header/Menu/Menu';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';

export const LandingPageHeader: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const { getLPMenuItems } = useMenuItems();

  return (
    <StyledLPHeader>
      <StyledLandingContainer $size={'lg'}>
        <StyledLPHeaderContent>
          <U1LandingPageLogo $maxWidth={isMobile ? 111 : 190} $height={isMobile ? 38 : 66} />
          <StyledRow $gap={isMobile ? 16 : 32} $align="center">
            <MainMenu menuItems={getLPMenuItems()} />
            <ThemeSwitcher />
            {!isMobile && <LangSwitch />}
          </StyledRow>
        </StyledLPHeaderContent>
      </StyledLandingContainer>
    </StyledLPHeader>
  );
};
