import { VerticalDropdownIcon } from '@/assets/images/icons';
import { Dropdown } from 'antd';
import React from 'react';
import { U1Listbox } from '@u1/react-a11y-hooks';
import { StyledButton } from '@/shared/styles/dropdownMenu.styles';
import { UserActionsProps } from '../Panel.consts';
import { Image } from '@/shared/components/Image/Image';
import { useTranslation } from 'react-i18next';

export const UserActions: React.FC<UserActionsProps> = ({
  name,
  actionsDropdown,
  isOpen,
  setIsOpen,
  dataTestId,
}: UserActionsProps) => {
  const { t } = useTranslation();
  return (
    <U1Listbox>
      <Dropdown open={isOpen} dropdownRender={() => actionsDropdown}>
        <StyledButton
          aria-label={`${t('users.user_actions')}${name}`}
          onClick={() => setIsOpen(!isOpen)}
          className="listbox-trigger"
          data-testid={dataTestId}
        >
          {<Image $src={VerticalDropdownIcon} />}
        </StyledButton>
      </Dropdown>
    </U1Listbox>
  );
};
