import { NUM_OF_DAYS } from '@/shared/consts';
import { getRemainingDays } from '@/shared/utils';
import { ExtraSelectionType, PackagesStore, SubscriptionDetails } from '@/stores/Stores.consts';
import { PackageExtra } from '../Checkout/Checkout.consts';

export const calcUpgradePrice = (userSelection: ExtraSelectionType): number => {
  const totalPrice =
    userSelection.totalPrice.extraFrameworks +
    userSelection.totalPrice.extraDomains +
    userSelection.totalPrice.additionalService -
    (userSelection.discount?.value ?? 0);
  userSelection.totalPrice.total = totalPrice;
  return totalPrice;
};

export const calcUpgradeFrameworks = (
  userSelection: ExtraSelectionType,
  packages: PackagesStore,
  currentPlan?: SubscriptionDetails
): number => {
  let totalPrice = 0;
  if (currentPlan && userSelection.frameworks.length) {
    const frameworkPrice = packages.getPricing(
      currentPlan.name,
      currentPlan.pricingMethod,
      PackageExtra.FRAMEWORK
    );
    totalPrice = userSelection.frameworks.length * frameworkPrice;
    totalPrice = calcRelativePrice(totalPrice, currentPlan);
  }
  userSelection.totalPrice.extraFrameworks = totalPrice;
  return totalPrice;
};

export const calcUpgradeDomains = (
  userSelection: ExtraSelectionType,
  packages: PackagesStore,
  currentPlan: SubscriptionDetails
): number => {
  const domainPrice = packages.getPricing(
    currentPlan.name,
    currentPlan.pricingMethod,
    PackageExtra.DOMAIN
  );
  let totalPrice = userSelection.domains * domainPrice;
  totalPrice = calcRelativePrice(totalPrice, currentPlan);
  userSelection.totalPrice.extraDomains = totalPrice;
  return totalPrice;
};

export const getUpgradeDomainsPerFW = (
  userSelection: ExtraSelectionType,
  subscription: SubscriptionDetails
): number => {
  const numOfExtraFrameworks = Math.max(
    userSelection.frameworks.length - subscription.frameworks.length,
    0
  );
  return numOfExtraFrameworks * subscription.domains;
};

export const calcRelativePrice = (totalPrice: number, currentPlan: SubscriptionDetails) => {
  const remainingDays = currentPlan.isTrial
    ? NUM_OF_DAYS[currentPlan.pricingMethod]
    : getRemainingDays(currentPlan.expiryDate);
  return Math.round((totalPrice / NUM_OF_DAYS[currentPlan.pricingMethod]) * remainingDays);
};
