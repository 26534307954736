import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledTabletRow } from '@/shared/styles';
import styled, { css } from 'styled-components';

const InfoCardCss = css`
  background-color: ${(props) => props.theme.sameColorBox};
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.lpCardBorder};
  flex: 1 1 0;
  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      align-items: ${isMobile ? 'center' : 'stretch'};
    `;
  }}
`;

export const StyledBorderedCard = styled.div`
  ${InfoCardCss}
  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      display: flex;
      flex-direction: ${isMobile ? 'column' : 'row'};
      justify-content: space-between;
      gap: 16px;
      min-height: ${isMobile ? 'auto' : '400px'};
      width: 100%;
      box-sizing: border-box;
    `;
  }}
`;

export const StyledElongatedCard = styled(StyledTabletRow)`
  ${InfoCardCss}
  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      gap: 24px;
      justify-content: space-between;

      img {
        max-width: ${isMobile ? '295px' : '350px'};
        align-self: center;
      }
    `;
  }}
`;
