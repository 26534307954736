import { PaginationData } from '@/shared/dto';

export const TABLE_PAGINATION_LIMIT = 5;

export type LeadDetails = {
  key: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  linkedinUrl: string;
  companyName: string;
  country?: string;
  message?: string;
  techStack?: string[];
  createdAt: string;
  updatedAt: string;
};

export type LeadStore = {
  leads: LeadDetails[];
  isLoading: boolean;
  currentPage: number;
  currentLimit: number;
  searchColumn: string;
  searchValue?: string;
  pagination?: PaginationData;
  getLeads: () => void;
  setCurrentPage: (value: number) => void;
  setSearchValue: (value: string) => void;
};

export type LeadTechStack = {
  code: string;
  title: string;
};

export type LeadTechStackGroup = {
  code: string;
  title: string;
  items: LeadTechStack[];
};

export type LeadTechStackStore = {
  techStackGroups: LeadTechStackGroup[];
  fetchLeadTechStackGroups: () => void;
};
