import { StyledLangSwitch } from './LangSwitch.styles';
import { SimpleSelect } from '@/shared/components/Select/SimpleSelect';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useLocation } from 'react-router-dom';
import { GlobalOutlined } from '@ant-design/icons';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { useEffect } from 'react';
import { defaultLanguage, defaultLanguages } from '@/stores/LangStore/LangStore.consts';
import { useTranslation } from 'react-i18next';
import { isBot } from '@/shared/utils';
import { getLocalStorage, setLocalStorage } from '@/services/storageService';
import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';

export const LangSwitch: React.FC = () => {
  const { availableLangs, getLanguageCode, isLanguageSupported } = useLangStore();
  const location = useLocation();
  const { navigate, removeLanguageFromPath } = useU1Navigation();
  const { i18n } = useTranslation();

  const changeLanguageByCode = (code: string) => {
    navigate(removeLanguageFromPath(location.pathname), code);
    i18n.changeLanguage(code);
    document.documentElement.setAttribute(
      'dir',
      defaultLanguages.find((lang) => lang.code === code)?.direction ?? 'ltr'
    );
    setLocalStorage('selectedLanguage', code, CookieType.FUNCTIONAL);
  };

  const changeLanguageById = (languageId: string) => {
    const languageCode = getLanguageCode(languageId);

    if (!languageCode || languageCode === i18n.language) {
      return;
    }
    changeLanguageByCode(languageCode);
  };

  useEffect(() => {
    if (
      !isBot(navigator.userAgent || '') &&
      navigator.languages.length > 0 &&
      i18n.language === defaultLanguage
    ) {
      const storedLanguage = getLocalStorage('selectedLanguage');
      if (storedLanguage) {
        return;
      }

      for (const browserLanguage of navigator.languages) {
        if (isLanguageSupported(browserLanguage) && browserLanguage !== i18n.language) {
          // If language hasn't been changed by user before and selected language not equal to default one then select browser code
          changeLanguageByCode(browserLanguage);
          break;
        }
      }
    }
    if (!isLanguageSupported(i18n.language)) {
      // If language is not suppported redirect to default one
      changeLanguageByCode(defaultLanguage);
    }
  }, []);

  return (
    <StyledLangSwitch>
      <GlobalOutlined />
      <SimpleSelect
        label={i18n.t('header.select_language')}
        value={i18n.language.toUpperCase()}
        options={availableLangs}
        onSelect={(value: string) => changeLanguageById(value)}
      />
    </StyledLangSwitch>
  );
};
