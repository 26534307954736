import { create } from 'zustand';
import { AdminDetails, AdminsStore, VendorDetails } from './Stores.consts';
import { AdminsDto, VendorsDto } from '@/shared/dto';
import { _axios } from '@/services/apiClient';

export const useAdminsStore = create<AdminsStore>((set, get) => ({
  admins: [],
  vendors: [],
  isLoading: true,
  totalAdmins: 0,
  totalVendors: 0,
  setAdmins: (adminsDto: AdminsDto) => {
    const extractedAdmins: AdminDetails[] = [];
    adminsDto.data.forEach((adminData, i) => {
      const { id, userName, email, role, roleTitle, phone } = adminData;
      const admin: AdminDetails = {
        key: String(i),
        id,
        userName,
        email,
        role,
        phone,
        roleTitle,
      };
      extractedAdmins.push(admin);
    });
    set(() => ({ admins: extractedAdmins, totalAdmins: adminsDto.pagination.total }));
  },
  setVendors: (vendorsDto: VendorsDto) => {
    const extractedVendors: VendorDetails[] = [];
    vendorsDto.data.forEach((vendorData, i) => {
      const { id, companyName, userName, email, phone, status, dealCount, dealSum } = vendorData;
      const vendor: VendorDetails = {
        key: String(i),
        id,
        companyName,
        userName,
        email,
        phone,
        status: status.toLowerCase(),
        dealCount: dealCount > 0 ? `${dealCount}` : '-',
        dealSum: dealSum > 0 ? `$ ${dealSum}` : '-',
      };
      extractedVendors.push(vendor);
    });
    set(() => ({ vendors: extractedVendors, totalVendors: vendorsDto.pagination.total }));
  },
  setUser: (type: string, setUser: (users: any) => void, pageNumber?: number, name?: string) => {
    let query = '';

    if (pageNumber) {
      const skipAmount = (pageNumber - 1) * 3;
      query += `&skip=${skipAmount}`;
    }

    if (name) {
      query += `&userName=${name}`;
    }
    _axios.get(`${type}?limit=3${query}`).then((res) => {
      setUser(res.data);
      set((state) => ({ ...state, isLoading: false }));
    });
  },
  getAdmins: (pageNumber?: number, adminName?: string) => {
    get().setUser('admins', get().setAdmins, pageNumber, adminName);
    return get().admins;
  },
  getVendors: (pageNumber?: number, vendorName?: string) => {
    get().setUser('vendors', get().setVendors, pageNumber, vendorName);
    return get().vendors;
  },
  getAdminByName: (name: string) => {
    _axios.get(`admins?userName=${name}&limit=3`).then((res) => {
      get().setAdmins(res.data);
    });
    return get().admins;
  },
  getVendorByName: (name: string) => {
    _axios.get(`vendors?userName=${name}&limit=3`).then((res) => {
      get().setVendors(res.data);
    });
    return get().vendors;
  },
}));
