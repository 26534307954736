import { useOutsideDropdownClick } from '@/hooks/useOutsideDropdownClick';
import { UserRole } from '@/shared/consts';
import {
  StyledCapitalized,
  StyledSecondaryText,
  StyledSeparator,
  StyledText,
} from '@/shared/styles';
import React, { useRef, useState } from 'react';
import { AdminMenuProps } from '../../Users.consts';
import { StyledMenu, StyledMenuItem, StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { ConfirmModal } from '@/shared/components/Modals/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';

export const AdminMenu: React.FC<AdminMenuProps> = ({
  postChangeRole,
  deleteUser,
  userRole,
  userName,
  setIsOpen,
}: AdminMenuProps) => {
  const [changeToRole] = useState(
    userRole.toLowerCase() === UserRole.ADMIN.toLowerCase() ? UserRole.USER : UserRole.ADMIN
  );
  const { t } = useTranslation();

  const getAdjective = (role: string) => {
    return role.toLowerCase() === UserRole.USER.toLowerCase() ? t('a') : t('an');
  };

  const dropdownRef = useRef(null);
  useOutsideDropdownClick(dropdownRef, () => {
    setIsOpen(false);
  });

  return (
    <StyledMenu ref={dropdownRef} className="listbox-element" onClick={() => setIsOpen(false)}>
      <StyledMenuItemRow>
        <ConfirmModal
          title={t('user.admin_menu.change_role.title')}
          trigger={
            <StyledMenuItem data-testid="changeRoleButton" data-a11y-ref="confirm">
              {t('user.admin_menu.make')}{' '}
              <StyledCapitalized>{changeToRole.toLowerCase()}</StyledCapitalized>
            </StyledMenuItem>
          }
          onConfirm={() =>
            postChangeRole({
              data: {
                role: changeToRole,
              },
            })
          }
        >
          <StyledSecondaryText fontSize={16} fontWeight={400}>
            {t('user.admin_menu.change_role.confirm')} <StyledText>{userName}</StyledText>{' '}
            {getAdjective(changeToRole)} {changeToRole.toLowerCase()}?
          </StyledSecondaryText>
        </ConfirmModal>
      </StyledMenuItemRow>
      <StyledSeparator />
      <ConfirmModal
        trigger={
          <StyledMenuItem data-testid="removeUserButton" data-a11y-ref="confirm">
            {t('user.admin_menu.remove_user')}
          </StyledMenuItem>
        }
        onConfirm={() => deleteUser()}
        title={t('user.admin_menu.remove_user')}
      >
        <StyledSecondaryText fontSize={16} fontWeight={400}>
          {t('user.admin_menu.remove_user.confirm')} <StyledText>{userName}</StyledText> {t('as')}{' '}
          {getAdjective(userRole)} {userRole}?
        </StyledSecondaryText>
      </ConfirmModal>
    </StyledMenu>
  );
};
