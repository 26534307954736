import { adminSignInBgUrl } from '@/assets/images';
import styled from 'styled-components';
import { StyledMainBeforeLogin } from '../BeforeLogin.styles';
import { isMobileOnly } from 'react-device-detect';

export const StyledSignIn = styled(StyledMainBeforeLogin)`
  ${(props) =>
    isMobileOnly
      ? `
    background-color: ${props.theme.cardsColor};
    `
      : `
    background-image: url(${adminSignInBgUrl});
    background-size: cover;`}

  min-height: 100vh;
  box-sizing: border-box;
`;
