import { StyledCard, StyledDivider } from '@/shared/styles';
import { StyledDealRow } from './ViewDeal.styles';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { downloadItem } from '@/shared/utils';
import { SendCredentials } from '../SendCredentials/SendCredentials';
import { useTranslation } from 'react-i18next';

export const DealActions: React.FC = () => {
  const deal = useDealStore();
  const { t } = useTranslation();

  const downloadReceipt = () => {
    downloadItem(`/deals/${deal.id}/receipts/download`);
  };

  return (
    <StyledCard $width="100%">
      <StyledDealRow>
        <SendCredentials
          trigger={
            <PinkGradientLink
              text={t('vendor.deals.create_deal.payment.view_deal.action.send_credentials')}
              weight={100}
              triggerRef="confirm"
              className="ant-trigger"
            />
          }
        />
        <StyledDivider />
        <PinkGradientLink
          text={t(
            'vendor.deals.create_deal.payment.view_deal.action.send_credentials.download_receipt'
          )}
          onClick={downloadReceipt}
          weight={100}
          isButton={true}
        />
      </StyledDealRow>
    </StyledCard>
  );
};
