import { Form } from 'antd';
import { StyledForm } from '../Forms.styles';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { LeadInnerForm } from './InnerForm';
import { FormError } from '../FormError';
import { extractErrors, trimRequestData } from '@/shared/utils';
import { useFormMessages } from '@/hooks/useFormMessages';
import { useTranslation } from 'react-i18next';
import { useLeadsStore } from '@/stores/LeadStore/LeadStore';
import { LeadFormFields, LeadFormProps } from './LeadForm.consts';
import { RES_STATUS } from '@/shared/consts';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js/min';

export const LeadForm: React.FC<LeadFormProps> = ({ selectedLead, onCancel }: LeadFormProps) => {
  const [form] = Form.useForm();
  const getLeads = useLeadsStore((state) => state.getLeads);
  const { message, setMessage, serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ response, error }, proceedLead] = useAxios(
    {
      url: '/leads' + (selectedLead ? `/${selectedLead.id}` : ''),
      method: selectedLead ? 'put' : 'post',
    },
    { manual: true }
  );

  const closeAndResetForm = (onCancel: () => void) => {
    onCancel();
    form.resetFields();
    getLeads();
    clearAllMessages();
  };

  const onCountryChange = (countryCode: unknown) => {
    try {
      if (typeof countryCode === 'string') {
        const code = `+${getCountryCallingCode(countryCode as CountryCode)}`;
        form.setFieldValue('phone', code);
      } else {
        form.setFieldValue('phone', '');
      }
    } catch (error) {
      form.setFieldValue('phone', '');
    }
  };

  useEffect(() => {
    if (
      response?.status &&
      [RES_STATUS.OK, RES_STATUS.CREATED].includes(response?.status) &&
      onCancel
    ) {
      setMessage(t('admin.leads.lead_' + (selectedLead ? 'updated' : 'added')));
      setTimeout(() => {
        closeAndResetForm(onCancel);
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    if (error && error.response?.data.properties) {
      const errors = extractErrors(error.response);
      setError(errors);
    }
  }, [error]);

  useEffect(() => {
    if (selectedLead) {
      form.setFieldsValue(selectedLead);
    }
  }, [selectedLead]);

  const onFinish = (values: any) => {
    proceedLead({
      data: trimRequestData<LeadFormFields>(values),
    });
  };

  const closeDialog = (e: Event) => {
    e.preventDefault();
    onCancel && onCancel();
  };

  return message ? (
    <StyledSecondaryText fontWeight={400} fontSize={16}>
      {message}
    </StyledSecondaryText>
  ) : (
    <StyledForm name="basic" onFinish={onFinish} autoComplete="off" form={form}>
      {serverError && <FormError message={serverError} />}
      <StyledCol>
        <StyledTitle $size={18}>
          {t(selectedLead ? 'admin.leads.edit_lead' : 'admin.leads.add_lead')}
        </StyledTitle>
        <StyledSecondaryText fontWeight={400} fontSize={16} flex="start">
          {t('admin.leads.form.content')}
        </StyledSecondaryText>
      </StyledCol>
      <LeadInnerForm
        onCancel={(e) => closeDialog(e)}
        onCountryChange={onCountryChange}
        submitBtnText={t(selectedLead ? 'admin.leads.edit_lead' : 'admin.leads.add_lead')}
      />
    </StyledForm>
  );
};
