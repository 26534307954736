import { Loading } from '@/shared/components/Loading/Loading';
import { PAGES, UserRole } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { useAxios } from '@/services/apiClient';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React, { useEffect, useState } from 'react';
import { UserDetails } from './Users.consts';
import { StyledUsersLayout } from './Users.styles';
import { extractUsers } from './Users.utils';
import { UsersHeader } from './UsersHeader/UsersHeader';
import { UsersTable } from './UsersTable/UsersTable';
import { useUserStore } from '@/stores/UserStore';

export const Users: React.FC = () => {
  const userDetails = useUserStore();
  const subscriptionStatus = useSubscriptionStore((state) => state.subscriptionStatus);
  const [users, setUsers] = useState<UserDetails[] | []>([]);

  const [{ data: usersData, loading: usersLoading }, getUsersData] = useAxios({
    method: 'get',
    url: '/users',
  });

  useEffect(() => {
    if (usersData) {
      const usersList = extractUsers(usersData);
      setUsers(usersList);
    }
  }, [usersData]);

  return subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION &&
    userDetails.role !== UserRole.ENTERPRISE ? (
    usersLoading ? (
      <Loading />
    ) : (
      <StyledUsersLayout>
        <UsersHeader usersData={users} getUsersData={getUsersData} />
        <UsersTable usersData={users} getUsersData={getUsersData} />
      </StyledUsersLayout>
    )
  ) : (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
