import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { StyledForm, StyledLabel } from '../Forms.styles';
import { StyledCol, StyledInput, StyledPasswordInput } from '@/shared/styles';
import { U1Button } from '../../Button/U1Button';
import { emailExp } from '@/shared/regex';
import { SignInFormProps } from '../Forms.consts';
import { useAuth } from '@/hooks/Auth/useAuth';
import { PinkGradientLink } from '../../Link/PinkGradientLink';
import { SIGN_IN_VIEW } from '@/Views/BeforeLogin/SignIn/SignIn.consts';
import { FormError } from '../FormError';
import { extractErrors } from '@/shared/utils';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

export const SignInForm: React.FC<SignInFormProps> = ({ userType, setView }: SignInFormProps) => {
  const [serverErr, setServerErr] = useState('');
  const { postLogin, loginAuthErr, loginTokenData } = useAuth();
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    postLogin({
      data: { userType, email: values.email, password: values.password },
    });
  };

  useEffect(() => {
    if (loginTokenData && !loginTokenData.passed2FA) {
      setView(SIGN_IN_VIEW.MFA);
    }
  }, [loginTokenData]);

  useEffect(() => {
    if (loginAuthErr) {
      const errorsText = extractErrors(loginAuthErr.response);
      setServerErr(errorsText);
    }
  }, [loginAuthErr]);

  return (
    <StyledForm $marginTop={'20px'} name="basic" onFinish={onFinish} autoComplete="off">
      {serverErr && <FormError message={serverErr} $margin={'0px 0px 20px 0px'} $width={'100%'} />}
      <StyledCol $gap={8} $width="100%">
        <StyledCol $gap={8}>
          <StyledLabel>{t('form.email')}</StyledLabel>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `${t('form.email')} ${t('form.required')}`,
                pattern: emailExp,
              },
            ]}
          >
            <StyledInput
              $width={isMobileOnly ? '100%' : '440px'}
              placeholder={t('form.placeholder.email')}
            />
          </Form.Item>
        </StyledCol>

        <StyledCol $gap={8}>
          <StyledLabel>{t('form.password')}</StyledLabel>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: `${t('form.password')} ${t('form.required')}`,
              },
            ]}
          >
            <StyledPasswordInput
              $width={isMobileOnly ? '100%' : '440px'}
              placeholder={t('form.placeholder.password')}
            />
          </Form.Item>
        </StyledCol>
        <PinkGradientLink
          text={t('form.forgot_password')}
          onClick={() => setView(SIGN_IN_VIEW.RESET_PASSWORD)}
        />
      </StyledCol>
      <Form.Item>
        <U1Button text={t('header.sign_in')} htmlType="submit" />
      </Form.Item>
    </StyledForm>
  );
};
