import { StyledCol, StyledInput, StyledRow, StyledSelect, StyledSeparator } from '@/shared/styles';
import { StyledForm, StyledFormItemNoWidth, StyledLabel } from '../Forms.styles';
import { Form } from 'antd';
import { emailExp, fullNameExp, phoneNumberRegex } from '@/shared/regex';
import { WhiteBorderButton } from '../../Button/WhiteBorderButton';
import { U1Button } from '../../Button/U1Button';
import { DEALS_VIEW } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.consts';
import { useEffect, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { getClientObject } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/CreateDeal/Steps/ClientDetails/ClientDetails.utils';
import { useVendorsStore } from '@/stores/VendorsStore';
import { useFormMessages } from '@/hooks/useFormMessages';
import { FormError } from '../FormError';
import { extractErrors } from '@/shared/utils';
import { DealSteps } from '@/stores/DealStore/DealsStore.consts';
import { useTranslation } from 'react-i18next';

export const ClientDetailsForm: React.FC = () => {
  const deals = useDealStore();
  const countries = useMemo(() => countryList().getData(), []);
  const setDealsView = useVendorsStore((state) => state.setDealsView);
  const [isDraft, setDraft] = useState(false);
  const [form] = Form.useForm();
  const { serverError, setError } = useFormMessages();
  const { t } = useTranslation();

  const [{ response, error }, putUpdateDeal] = useAxios({ method: 'patch' }, { manual: true });

  useEffect(() => {
    if (response?.status === RES_STATUS.OK) {
      if (isDraft) {
        setDealsView(DEALS_VIEW.MAIN);
      } else {
        deals.setDealStep(DealSteps.PAYMENT);
      }
    }
  }, [response]);

  useEffect(() => {
    if (error?.response?.status === RES_STATUS.UNPROCESSABLE) {
      const errors = extractErrors(error?.response);
      setError(errors);
    }
  }, [error]);

  const saveStep2 = () => {
    form.validateFields().then((client) => {
      deals.setClient(client);
      if (deals.id) {
        putUpdateDeal({ url: `/deals/${deals.id}`, data: getClientObject(client) });
      }
    });
  };

  const goNext = () => {
    setDraft(false);
    saveStep2();
  };

  const goBack = () => {
    setDraft(false);
    deals.setDealStep(DealSteps.PRODUCT);
  };

  const saveAsDraft = () => {
    setDraft(true);
    saveStep2();
  };

  return (
    <StyledForm form={form}>
      {serverError && <FormError message={serverError} $width="fit-content" />}
      <StyledRow $width="100%" $gap={32}>
        <StyledCol $justify="center" $gap={8} $width="100%">
          <StyledLabel>{t('form.company_name')}</StyledLabel>
          <Form.Item
            name="company"
            initialValue={deals.client?.company}
            rules={[
              {
                required: true,
                message: `${t('form.company_name')} ${t('form.required')}`,
              },
            ]}
          >
            <StyledInput placeholder={t('form.company_name')} />
          </Form.Item>
          <StyledLabel>{t('form.email')}</StyledLabel>
          <Form.Item
            name="email"
            initialValue={deals.client?.email}
            rules={[
              {
                required: true,
                message: `${t('form.valid_email')} ${t('form.required')}`,
                pattern: emailExp,
              },
            ]}
          >
            <StyledInput placeholder="example@gmail.com" />
          </Form.Item>
          <StyledLabel>{t('vendor.deals.country')}</StyledLabel>
          <Form.Item
            name="country"
            initialValue={deals.client?.country}
            rules={[
              {
                required: true,
                message: `${t('vendor.deals.country')} ${t('form.required')}`,
              },
            ]}
          >
            <StyledSelect placeholder={t('vendor.deals.select_country')} options={countries} />
          </Form.Item>
        </StyledCol>
        <StyledCol $width="100%" $gap={8}>
          <StyledLabel>{t('form.full_name')}</StyledLabel>
          <Form.Item
            name="fullname"
            initialValue={deals.client?.fullname}
            rules={[
              {
                required: true,
                message: `${t('form.full_name')} ${t('form.required')}`,
                pattern: fullNameExp,
              },
            ]}
          >
            <StyledInput placeholder={t('form.first_and_last_name')} />
          </Form.Item>
          <StyledLabel>{t('vendor.deals.phone_number')}</StyledLabel>
          <Form.Item
            name="phone"
            initialValue={deals.client?.phone}
            rules={[
              {
                required: true,
                message: t('vendor.deals.phone_number.required'),
                pattern: phoneNumberRegex,
              },
            ]}
          >
            <StyledInput placeholder={t('vendor.deals.phone_number.placeholder')} />
          </Form.Item>
        </StyledCol>
      </StyledRow>
      <StyledSeparator $width="100%" />
      <StyledRow $justify="end" $width="100%" $gap={24}>
        <StyledFormItemNoWidth>
          <WhiteBorderButton
            text={t('vendor.deals.save_draft')}
            $height="50px"
            $width="180px"
            onClick={saveAsDraft}
          />
        </StyledFormItemNoWidth>
        <StyledFormItemNoWidth>
          <WhiteBorderButton text={t('back')} $height="50px" $width="180px" onClick={goBack} />
        </StyledFormItemNoWidth>
        <StyledFormItemNoWidth>
          <U1Button
            text={t('next')}
            $height="50px"
            $width="180px"
            htmlType="submit"
            onClick={goNext}
          />
        </StyledFormItemNoWidth>
      </StyledRow>
    </StyledForm>
  );
};
