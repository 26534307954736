import { ProtectedRouteOption } from '@/shared/components/Auth/ProtectedRoute.consts';
import { AdminDocs } from './AdminDocs/AdminDocs';
import { AdminsSection } from './AdminsPanel/Admins';
import { VendorsSection } from './VendorsPanel/Vendors';
import { PAGES } from '@/shared/consts';
import { DocumentationPage } from '../Docs/DocumentationPage/DocumentationPage';
import { ConfigurationPage } from '../Docs/ConfigurationPage/ConfigurationPage';
import { AdminNotFound } from '@/shared/pages/NotFound/AdminNotFound';
import { VersionsControl } from './VersionsControl/VersionsControl';
import { LeadsSection } from './LeadsPanel/Leads';

export enum AdminRole {
  ADMIN = 'admin',
  LEGAL = 'legal',
  SALES = 'sales',
  BIZ_DEV = 'biz_dev',
  R_D = 'r_d',
  CUSTOMER_SUCCESS = 'customer_success',
  BDR = 'bdr',
}

export enum AdminPermission {
  READ_ADMIN = 'read_admin',
  EXPORT_ADMIN = 'export_admin',
  CREATE_ADMIN = 'create_admin',
  UPDATE_ADMIN = 'update_admin',
  DELETE_ADMIN = 'delete_admin',
  MANAGE_ADMINS = 'manage_admins',

  READ_ADMIN_ROLE = 'read_admin-role',
  UPDATE_ADMIN_ROLE = 'update_admin-role',
  MANAGE_ADMIN_ROLES = 'manage_admin-roles',

  READ_PACKAGE_VERSION = 'read_package-version',

  READ_VENDOR = 'read_vendor',
  EXPORT_VENDOR = 'export_vendor',
  CREATE_VENDOR = 'create_vendor',
  UPDATE_VENDOR = 'update_vendor',
  DELETE_VENDOR = 'delete_vendor',
  MANAGE_VENDORS = 'manage_vendors',

  READ_TRUSTED_SOURCE = 'read_trusted-source',
  CREATE_TRUSTED_SOURCE = 'create_trusted-source',
  UPDATE_TRUSTED_SOURCE = 'update_trusted-source',
  DELETE_TRUSTED_SOURCE = 'delete_trusted-source',
  MANAGE_TRUSTED_SOURCES = 'manage_trusted-sources',

  READ_TERMS = 'read_terms',
  CREATE_TERMS = 'create_terms',
  UPDATE_TERMS = 'update_terms',
  DELETE_TERMS = 'delete_terms',
  MANAGE_TERMS = 'manage_terms',

  READ_PROJECT_ACCESS_TOKEN = 'read_project-access-token',
  CREATE_PROJECT_ACCESS_TOKEN = 'create_project-access-token',
  UPDATE_PROJECT_ACCESS_TOKEN = 'update_project-access-token',
  DELETE_PROJECT_ACCESS_TOKEN = 'delete_project-access-token',
  MANAGE_PROJECT_ACCESS_TOKENS = 'manage_project-access-tokens',

  READ_FRAMEWORK = 'read_framework',
  CREATE_FRAMEWORK = 'create_framework',
  UPDATE_FRAMEWORK = 'update_framework',
  DELETE_FRAMEWORK = 'delete_framework',
  MANAGE_FRAMEWORKS = 'manage_frameworks',

  READ_TRANSLATION = 'read_translation',
  CREATE_TRANSLATION = 'create_translation',
  UPDATE_TRANSLATION = 'update_translation',
  DELETE_TRANSLATION = 'delete_translation',
  MANAGE_TRANSLATIONS = 'manage_translations',

  READ_LANGUAGE = 'read_language',
  CREATE_LANGUAGE = 'create_language',
  UPDATE_LANGUAGE = 'update_language',
  DELETE_LANGUAGE = 'delete_language',
  MANAGE_LANGUAGES = 'manage_languages',

  SHARE_DOCUMENTATION = 'share_documentation',
  MANAGE_ALL = 'manage_all',

  READ_LEAD = 'read_lead',
  EXPORT_LEAD = 'export_lead',
  CREATE_LEAD = 'create_lead',
  UPDATE_LEAD = 'update_lead',
  DELETE_LEAD = 'delete_lead',
  MANAGE_LEAD = 'manage_leads',
}

export const adminRoutes: ProtectedRouteOption[] = [
  {
    path: PAGES.AFTER_LOGIN.ADMIN_DOCS,
    element: <AdminDocs />,
    permission: AdminPermission.READ_FRAMEWORK,
  },
  {
    path: PAGES.AFTER_LOGIN.ADMINS,
    element: <AdminsSection />,
    permission: AdminPermission.READ_ADMIN,
  },
  {
    path: PAGES.AFTER_LOGIN.ADMIN_BDR,
    element: <LeadsSection />,
    permission: AdminPermission.READ_LEAD,
  },
  {
    path: PAGES.AFTER_LOGIN.VENDORS,
    element: <VendorsSection />,
    permission: AdminPermission.READ_VENDOR,
  },
  {
    path: PAGES.AFTER_LOGIN.ADMIN_DOCUMENTATION,
    element: <DocumentationPage />,
    permission: AdminPermission.READ_FRAMEWORK,
  },
  {
    path: PAGES.AFTER_LOGIN.ADMIN_CONFIGURATION,
    element: <ConfigurationPage />,
    permission: AdminPermission.READ_FRAMEWORK,
  },
  {
    path: PAGES.AFTER_LOGIN.ADMIN_VERSIONS,
    element: <VersionsControl />,
    permission: AdminPermission.READ_PACKAGE_VERSION,
  },
  {
    path: PAGES.SHARED.NOT_FOUND,
    element: <AdminNotFound $homePath={PAGES.SHARED.DEFAULT} />,
  },
];
