import { PropsWithChildren } from 'react';
import { StyledSecondaryText } from '../styles';

export const U1Alert = ({ children }: PropsWithChildren) => {
  return (
    <StyledSecondaryText fontSize="15" fontWeight={400}>
      <div role="alert">{children}</div>
    </StyledSecondaryText>
  );
};
