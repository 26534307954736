import { StyledMainLoggedIn } from '../Main.styles';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { ProtectedRoutes } from '@/shared/components/Auth/ProtectedRoute';
import { Routes } from 'react-router-dom';
import { useUserStore } from '@/stores/UserStore';
import { detectDefaultRoute } from '@/shared/utils/auth';
import { adminRoutes } from './Admin.consts';
import { useEffect } from 'react';

export const AdminView: React.FC = () => {
  const { availableLangs } = useLangStore();
  const userPermissions = useUserStore((state) => state.permissions);
  const userType = useUserStore((state) => state.type);

  useEffect(() => {
    const defaultPage = detectDefaultRoute(userType, userPermissions, adminRoutes);

    if (defaultPage) {
      adminRoutes.push(defaultPage);
    }
  }, [userType, userPermissions]);

  return (
    <StyledMainLoggedIn>
      <Routes>
        {/* default routes */}
        {ProtectedRoutes(adminRoutes, '')}
        {/* lang routes */}
        {availableLangs.map((lang) => ProtectedRoutes(adminRoutes, lang.code))}
      </Routes>
    </StyledMainLoggedIn>
  );
};
