import { StyledCol, StyledRow, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { Loading } from '@/shared/components/Loading/Loading';
import { Pagination } from '@/shared/components/Pagination/Pagination';
import { donwloadIcon } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { downloadItem } from '@/shared/utils';
import { DEFAULT_PAGE_SIZE, PanelProps, userNameSearch } from './Panel.consts';
import { SearchInput } from '@/shared/components/Search/Search';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Panel: React.FC<PanelProps> = ({
  isLoading,
  title,
  name,
  createButton,
  table,
  totalResults,
  canDownloadTable = true,
  pageSize,
  panelType,
  searchBy = 'name',
  getSearch,
  changePage,
}: PanelProps) => {
  const baseExportQuery = `/${panelType}/export`;
  const [downloadQuery, setQuery] = useState(baseExportQuery);
  const { t } = useTranslation();

  useEffect(() => {
    const searchName = userNameSearch[panelType];
    setQuery(name ? `${baseExportQuery}?${searchName}=${name}` : baseExportQuery);
  }, [name]);

  return isLoading ? (
    <Loading />
  ) : (
    <StyledCol $gap={32}>
      <StyledTitle $size={28}>{title}</StyledTitle>
      <StyledRow $justify="space-between">
        <SearchInput
          onSearch={(name) => getSearch(name)}
          label={t('vendor.deals.search_by_' + searchBy)}
          placeholder={t('vendor.deals.search_by_' + searchBy)}
          $width="300px"
        />
        {createButton}
      </StyledRow>
      <StyledCol $gap={32} id="tableResults">
        {table}
        <StyledRow $justify="space-between">
          <StyledSecondaryText fontSize={14} fontWeight={400}>
            {t('vendor.deals.results')} - {totalResults}
          </StyledSecondaryText>
          <Pagination
            totalAmount={totalResults}
            onChange={changePage}
            pageSize={pageSize || DEFAULT_PAGE_SIZE}
          />
          <PinkGradientLink
            text={t('vendor.deals.download_table')}
            inRow={true}
            onClick={() => canDownloadTable && downloadItem(downloadQuery)}
            isButton={true}
          >
            <Icon $src={donwloadIcon} />
          </PinkGradientLink>
        </StyledRow>
      </StyledCol>
    </StyledCol>
  );
};
