import { Icon } from '@/shared/components/Icon';
import {
  StyledText,
  StyledRowOffset,
  StyledSecondaryText,
  StyledTitle,
  StyledCol,
  StyledCapitalized,
} from '@/shared/styles';
import React, { useEffect } from 'react';
import { CreditCardDictionary } from '../Billing.consts';
import { StyledBillingCard, StyledCreditCard, StyledEditableIcons } from '../Billing.styles';
import { v4 as uuidv4 } from 'uuid';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { getDateTemplate, getPriceFormat } from '@/shared/utils';
import { LOCALES } from '@/shared/consts';
import { UpdatePaymentModal } from '@/shared/components/Modals/UpdatePaymentModal/UpdatePaymaneModal';
import { usePayment } from '@/hooks/usePayment';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const PaymentMethod: React.FC = () => {
  const subscriptionPackage = useSubscriptionStore();
  const { getTimedOutPayment, cardDetails } = usePayment();
  const paymentStatus = usePaymentStore((state) => state.paymentStatus);
  const { t } = useTranslation();

  useEffect(() => {
    if (paymentStatus === Payment_Status.CHANGE_CARD || paymentStatus === Payment_Status.UPDATED) {
      getTimedOutPayment();
    }
  }, [paymentStatus]);

  return (
    <StyledBillingCard $padding={40}>
      <StyledCol $gap={24} $width="100%">
        <StyledCol $gap={10}>
          <StyledTitle level={2} $size={20} $weight={600} $flex="start">
            {t('billing.payment_method.title')}
          </StyledTitle>
          <StyledCreditCard key={uuidv4()}>
            <StyledSecondaryText fontWeight={400} fontSize={'14'} data-testid="cardEnding">
              <Icon $src={CreditCardDictionary[cardDetails.brand]} $right={15} />
              <StyledCapitalized>{cardDetails.brand}</StyledCapitalized>{' '}
              {t('billing.payment_method.ending_in')} {cardDetails.last4}
            </StyledSecondaryText>
            <StyledEditableIcons data-testid="updatePaymentMethodButton">
              <UpdatePaymentModal />
            </StyledEditableIcons>
          </StyledCreditCard>
        </StyledCol>
        {subscriptionPackage.isActive && subscriptionPackage.autoPayment && (
          <StyledRowOffset>
            <StyledText>
              {t('billing.payment_method.charge_date')}{' '}
              <StyledText fontWeight={400} data-testid="chargeDate">
                {getDateTemplate(subscriptionPackage.expiryDate, LOCALES.DAY_MONTH_YEAR)}
              </StyledText>
            </StyledText>

            <StyledText data-testid="nextPaymentAmount">
              ${getPriceFormat(subscriptionPackage.nextPaymentAmount)}
            </StyledText>
          </StyledRowOffset>
        )}
      </StyledCol>
    </StyledBillingCard>
  );
};
