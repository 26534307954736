import { TwoMonthsFree } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { ToggleButton } from '@/shared/components/ToggleButton/ToggleButton';
import { SUBSCRIPTION_METHOD, getSubscriptionType } from '@/shared/consts';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { IntroProps } from '../Pricing.consts';
import {
  StyledContainer,
  StyledDiscountIcon,
  StyledPricingMethod,
  StyledPricingText,
  StyledToggleGroup,
} from './Intro.styles';
import { useTranslation } from 'react-i18next';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const PackagesIntro: React.FC<IntroProps> = ({
  pricingMethod,
  setPricingMethod,
  children,
}: IntroProps) => {
  const switchPricing = () => {
    const method =
      pricingMethod == SUBSCRIPTION_METHOD.YEAR
        ? SUBSCRIPTION_METHOD.MONTH
        : SUBSCRIPTION_METHOD.YEAR;
    setPricingMethod(method);
  };
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {children}
      <StyledPricingMethod>
        <StyledToggleGroup>
          <StyledPricingText aria-hidden="true">{getSubscriptionType().MONTH}</StyledPricingText>
          <ToggleButton onSwitch={switchPricing} label={t('pricing.intro.toggle_alt')} />
          <StyledPricingText aria-hidden="true">{getSubscriptionType().YEAR}</StyledPricingText>
        </StyledToggleGroup>
        <StyledDiscountIcon>
          <Icon
            $src={TwoMonthsFree}
            $size={isMobile ? 75 : 120}
            $alt={t('pricing.intro.2_months_free_alt')}
            $invert={!isDarkMode}
          />
        </StyledDiscountIcon>
      </StyledPricingMethod>
    </StyledContainer>
  );
};
