import { Icon } from '@/shared/components/Icon';
import { StyledCol } from '@/shared/styles';
import { getPriceFormat } from '@/shared/utils';
import { TopSectionProps } from '../../Pricing.consts';
import { StyledCustomTopCard, StyledTitle } from '../Package.styles';
import { StyledPackageTitle } from '@/shared/styles';

export const FreeTopSection: React.FC<TopSectionProps> = ({
  name,
  price,
  imageUrl,
}: TopSectionProps) => {
  return (
    <StyledCustomTopCard>
      <StyledCol $align="center">
        <StyledTitle>{name}</StyledTitle>
        <StyledPackageTitle>${getPriceFormat(price as number)}</StyledPackageTitle>
        <Icon $src={imageUrl} $height={390} $width={300} $position="absolute" />
      </StyledCol>
    </StyledCustomTopCard>
  );
};
