import { dottedBgUrl } from '@/assets/images';
import { CardProps } from '@/shared/props';
import {
  StyledCard,
  StyledCol,
  StyledColOffset,
  StyledRow,
  StyledRowOffset,
} from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import styled from 'styled-components';

export const StyledBillingCard = styled(StyledCard)<CardProps>`
  width: 100%;
  min-height: 240px;
  box-sizing: border-box;
  height: ${(props) => (props.$height ? `${props.$height}` : `fit-content`)};
  ${(props) => props.$padding && `padding: ${props.$padding}px`};
  ${(props) =>
    !useThemeStore.getState().isDarkMode && `border: 1px solid ${props.theme.marginsBorder}`}
`;

export const BillingSections = styled(StyledCol)`
  gap: 44px;
`;

export const StyledCreditCard = styled(StyledRowOffset)`
  border: 1px solid ${(props) => props.theme.inputBorder};
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const StyledDetailsText = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.secondaryTextColor};
  font-weight: 400;
  margin: 7px 0px;
  text-transform: capitalize;
`;

export const StyledEditableIcons = styled(StyledRow)`
  gap: 10px;
`;

export const StyledNoRecords = styled(StyledCol)`
  align-items: center;
  background-image: url(${dottedBgUrl});
  border-radius: 15px;
  padding: 100px;
`;

export const StyleNoPackages = styled(StyledColOffset)`
  min-height: 185px;
  box-sizing: border-box;
  width: 100%;
`;
