import React, { useEffect, useState } from 'react';
import { StyledMenu, StyleMenuWrapper } from './SideMenu.styles';
import { U1SideMenu } from '@u1/react-a11y-hooks';
import { SideMenuProps } from '../Sider.consts';
import { useLocation } from 'react-router-dom';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { MenuProps } from 'antd';

export const SideMenu: React.FC<SideMenuProps> = ({
  beforeOnClick,
  defaultPage,
}: SideMenuProps) => {
  const currentLocation = useLocation();
  const { userMenuItems } = useMenuItems();
  const { navigate } = useU1Navigation();

  const onClick: MenuProps['onClick'] = (e) => {
    if (beforeOnClick) {
      beforeOnClick(e);
    }
    const menuItem = userMenuItems.find((item) => item && item.key == e.key);

    if (menuItem?.href) {
      navigate(menuItem.href);
    }
  };

  const getCurrentTab = () => {
    const path = currentLocation.pathname.replace(/^\/[a-z]{2}\//, '/');
    return [path === '/' ? defaultPage : path.split('/')[1]];
  };

  const [selectedItem, setSelectedItem] = useState(getCurrentTab());

  useEffect(() => {
    setSelectedItem(getCurrentTab());
  }, [currentLocation]);

  return (
    <U1SideMenu>
      <StyleMenuWrapper>
        <StyledMenu
          data-testid="sideNavigation"
          onClick={onClick}
          selectedKeys={selectedItem}
          mode="inline"
          items={userMenuItems}
          id="side-menu"
        />
      </StyleMenuWrapper>
    </U1SideMenu>
  );
};
