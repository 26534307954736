import { StyledCapitalized, StyledCard, StyledCol, StyledTitle } from '@/shared/styles';
import { ReactNode, useEffect, useState } from 'react';
import { Tabs } from '@/shared/components/Tabs/U1Tabs';
import { StyledTabPanel, VersionTabsStyle } from './VersionsControl.styles';
import Markdown from 'markdown-to-jsx';
import { useVersionsStore } from '@/stores/VersionsStore/VersionsStore';
import { PackageType } from '@/stores/VersionsStore/VersionsStore.consts';
import { useTranslation } from 'react-i18next';

export const VersionsControl: React.FC = () => {
  const { fetchChangelog, changelog } = useVersionsStore();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<{ key: string; label: ReactNode }[]>([]);

  const constructTabs = () => {
    const tabs = Object.keys(PackageType).map((packageKey: string) => ({
      key: packageKey,
      label: <StyledCapitalized>{packageKey.toLowerCase()}</StyledCapitalized>,
    }));
    setTabs(tabs);
  };

  useEffect(() => {
    constructTabs();
    fetchChangelog(PackageType.FIXERS);
  }, []);

  const onChange = (key: string) => {
    const packageType = Object.keys(PackageType)[Number(key) - 1] as keyof typeof PackageType;
    fetchChangelog(PackageType[packageType]);
  };

  return (
    <StyledCol $gap={32}>
      <StyledTitle>{t('side_menu.versions')}</StyledTitle>
      <StyledCard>
        <Tabs tabs={tabs} $customStyle={VersionTabsStyle} onChange={(e) => onChange(e)}>
          <StyledTabPanel>
            <Markdown>{changelog}</Markdown>
          </StyledTabPanel>
        </Tabs>
      </StyledCard>
    </StyledCol>
  );
};
