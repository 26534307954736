import React, { useEffect, useState } from 'react';
import { GradientCopyButton } from '@/shared/components/Button/GradientCopyButton';
import { StyledCellInnerRow, StyledTextEditor } from '../../DocumentationPage.styles';
import { ApiTableCellProps, ColumnType } from '../../../../Client/Projects/Projects.consts';
import { StyledRow } from '@/shared/styles';
import { EditIcons } from './EditIcons';
import { StyledCellData } from './ApiTable.styles';
import { USER_TYPE } from '@/shared/consts';
import { useUserStore } from '@/stores/UserStore';

export const ApiTableCell: React.FC<ApiTableCellProps> = ({
  index,
  columnName,
  cellData,
  objkeys,
}) => {
  const [iconsVisible, setIsVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableData, setData] = useState(cellData);
  const userType = useUserStore((state) => state.type);

  useEffect(() => {
    if (!editableData) {
      setIsEditMode(true);
      setIsVisible(false);
    }
  }, [editableData]);

  return (
    <StyledCellInnerRow>
      <StyledRow
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        $align="center"
        $gap={10}
      >
        {isEditMode ? (
          <StyledTextEditor
            value={editableData}
            onChange={(e) => {
              setData(e.target.value);
            }}
          />
        ) : columnName === 'property' ? (
          <StyledCellData>{cellData}</StyledCellData>
        ) : (
          cellData
        )}
        {userType === USER_TYPE.ADMIN && (
          <EditIcons
            index={index}
            objkeys={objkeys}
            isVisible={iconsVisible}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            editableData={editableData}
          />
        )}
      </StyledRow>
      {columnName == ColumnType.PROPERTY && (
        <GradientCopyButton textToCopy={cellData.replace(' (Optional)', '').replace(',', '')} />
      )}
    </StyledCellInnerRow>
  );
};
