import { StyledCol } from '@/shared/styles';
import { FooterLink } from '../FooterLink';
import { LinksSectionProps } from '../FooterSections.consts';
import { v4 as uuidv4 } from 'uuid';
import { PageProps } from '@/stores/PageGroupsStore/PageGroupsStore.consts';
import { scrollToTop } from '@/shared/utils';
import { usePageGroupsStore } from '@/stores/PageGroupsStore/PageGroupsStore';

export const LinksSection: React.FC<LinksSectionProps> = ({
  links,
  pageGroupName,
}: LinksSectionProps) => {
  const { fetchPageContent, getActiveCodeName } = usePageGroupsStore();

  const onClick = (order: any) => {
    const activeCodeName = getActiveCodeName(links, order);
    activeCodeName && fetchPageContent(activeCodeName, pageGroupName);
    scrollToTop();
  };
  return (
    links && (
      <StyledCol $gap={16}>
        {links.map((link: PageProps) => (
          <FooterLink
            key={uuidv4()}
            onClick={() => onClick(link.order)}
            name={link.name}
            to={link.to}
          />
        ))}
      </StyledCol>
    )
  );
};
