import { useState } from 'react';
import { StyledEditText } from '../../../../Client/Projects/Projects.styles';
import { EditContentProps } from '../../../../Client/Projects/Projects.consts';
import { getLines } from '../../DocumentationPage.utils';
import { StyledCompText, StyledTextEditor } from '../../DocumentationPage.styles';
import { StyledCol, StyledRow, StyledTitle } from '@/shared/styles';
import { EditIcons } from './EditIcons';
import { USER_TYPE } from '@/shared/consts';
import { useUserStore } from '@/stores/UserStore';

export const DocsSection: React.FC<EditContentProps> = ({
  index,
  title,
  secondTitle,
  objkeys,
  content,
  $isValid,
}: EditContentProps) => {
  const [editableText, setText] = useState(content ?? '');
  const [iconsVisible, setIsVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const userType = useUserStore((state) => state.type);

  return (
    <StyledEditText
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <StyledRow>
        <StyledCol $gap={12}>
          {title && (
            <StyledTitle level={2} $weight={600} $size={18}>
              {title}
            </StyledTitle>
          )}
          {secondTitle && (
            <StyledTitle level={3} $weight={600} $size={18}>
              {secondTitle}
            </StyledTitle>
          )}
        </StyledCol>
        {userType === USER_TYPE.ADMIN && editableText && (
          <EditIcons
            index={index}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isVisible={iconsVisible}
            objkeys={objkeys}
            editableContent={editableText}
            setContent={setText as React.Dispatch<React.SetStateAction<string | string[]>>}
            originalContent={content ?? ''}
          />
        )}
      </StyledRow>
      <StyledCol>
        {isEditMode ? (
          <StyledTextEditor
            value={editableText}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        ) : (
          content && <StyledCompText $isValid={$isValid}>{getLines(content)}</StyledCompText>
        )}
      </StyledCol>
    </StyledEditText>
  );
};
