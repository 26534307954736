import React, { useEffect, useState } from 'react';
import { U1Button } from '@/shared/components/Button/U1Button';
import { NewProjectForm } from '@/shared/components/Forms/NewProject/NewProjectForm';
import { StyledTitle } from '@/shared/styles';
import {
  StyledFormHorizontalDivider,
  StyledFormRow,
  StyledNewProjectFormCard,
  StyledFormLeftSection,
  StyledFormRightSection,
  StyledFrameworkRow,
  StyledFormHorizontalDividerWrapper,
  StyledNewProjectFormError,
} from './CreateNewProject.styles';
import { PAGES } from '@/shared/consts';
import { FrameworkCardItems } from './FrameworkCardItems';
import { extractDomainValidationErrors, getNewProjectFormError } from '../../Projects.utils';
import { useNewProjectStore } from '@/stores/ProjectStore/ProjectStore';
import { useAxios } from '@/services/apiClient';
import { Project } from '@/shared/dto';
import { useSubscription } from '@/hooks/useSubscription';
import { Loading } from '@/shared/components/Loading/Loading';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const CreateNewProject: React.FC = () => {
  const { navigate } = useU1Navigation();
  const { getSubscription, subscriptionLoading } = useSubscription();
  const newProjectStore = useNewProjectStore();
  const { projectFramework, projectName, projectDomain } = newProjectStore;
  const [formError, setFormError] = useState('');
  const { t } = useTranslation();

  const [{ data: projectData, error: projectError }, postProject] = useAxios(
    {
      method: 'post',
      url: '/projects',
    },
    { manual: true }
  );

  // Navigate to the projects list after creating a new project
  useEffect(() => {
    if (projectData) navigate(PAGES.AFTER_LOGIN.PROJECTS);
  }, [projectData]);

  // Get the form errors from the backend
  useEffect(() => {
    if (projectError) {
      const domainValidationErrors = extractDomainValidationErrors(
        projectError.response?.data?.properties
      );
      setFormError(domainValidationErrors);
    }
  }, [projectError]);

  // Get the subscription data from the database
  useEffect(() => {
    getSubscription();
  }, []);

  const validateForm = () => {
    const error = getNewProjectFormError(newProjectStore);
    if (error) {
      setFormError(error);
    } else {
      const frameworkName = projectFramework;
      if (frameworkName) {
        const newProjectDTO: Project = {
          name: projectName,
          framework: frameworkName,
        };
        if (frameworkName.includes('ANDROID')) newProjectDTO.appId = projectDomain;
        else newProjectDTO.domain = projectDomain;
        newProjectDTO &&
          postProject({
            data: { ...newProjectDTO },
          });
      }
    }
  };

  return subscriptionLoading ? (
    <Loading />
  ) : (
    <StyledNewProjectFormCard>
      <StyledTitle level={2} $size={24} $weight={600}>
        {t('projects.new_project.title.h2')}
      </StyledTitle>
      <StyledFormRow>
        <StyledFormLeftSection>
          <NewProjectForm />
        </StyledFormLeftSection>
        <StyledFormHorizontalDividerWrapper>
          <StyledFormHorizontalDivider />
        </StyledFormHorizontalDividerWrapper>
        <StyledFormRightSection>
          <StyledTitle level={2} $size={16} $weight={400}>
            {t('projects.new_project.project_details.choose_framework')}
          </StyledTitle>
          <StyledFrameworkRow>
            <FrameworkCardItems />
          </StyledFrameworkRow>
        </StyledFormRightSection>
      </StyledFormRow>
      {formError && <StyledNewProjectFormError message={formError} />}
      <StyledFormRow>
        <U1Button
          dataTestId="createProjectButton"
          onClick={validateForm}
          text={t('projects.create_project')}
          $width="100%"
          $height="50px"
          $padding="8px 0px"
          htmlType="submit"
        />
      </StyledFormRow>
    </StyledNewProjectFormCard>
  );
};
