import { SubscriptionDetails } from '@/stores/Stores.consts';
import { ReactNode } from 'react';

export type PackageSectionType = {
  title: string;
  optional: boolean;
  content: string;
  info?: string;
  extraComp?: ReactNode;
};

export enum PackageExtra {
  DEFAULT = 'default',
  DOMAIN = 'domain',
  FRAMEWORK = 'framework',
  MOBILE = 'mobile',
  TRAINING = 'training',
}

export type PaymentSummaryProps = {
  currentPlan?: SubscriptionDetails;
  isFree?: boolean;
};

export type ExtraSummaryProps = {
  isUpgrade?: boolean;
};

export const FrameworkCodeDic: Record<string, string> = {
  'React Native SDK': 'REACT_NATIVE_SDK',
  'Java Script': 'JAVASCRIPT',
  Angular: 'ANGULAR',
  React: 'REACT',
  'IOS SDK': 'IOS_SDK',
  'Flutter SDK': 'FLUTTER_SDK',
  'Android XML SDK': 'ANDROID_XML_SDK',
};
