import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import React, { useState } from 'react';
import { U1Button } from '../../Button/U1Button';
import { StyledModal } from '../Modals.styles';
import { LeadFormProps } from './LeadModals.consts';
import { StyledMenuItem } from '@/shared/styles/dropdownMenu.styles';

export const LeadModal: React.FC<LeadFormProps> = ({
  form,
  action,
  btnText,
  btnDisabled,
}: LeadFormProps) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <U1Dialog name={ComponentName.ADD_DIALOG}>
      {action === 'add' ? (
        <U1Button
          text={btnText}
          $height="50px"
          $width="188px"
          onClick={showModal}
          disabled={btnDisabled || false}
          triggerRef={action + '-lead'}
        />
      ) : (
        <StyledMenuItem
          onClick={showModal}
          data-a11y-dialog="trigger"
          data-a11y-ref={action + '-lead'}
        >
          {btnText}
        </StyledMenuItem>
      )}

      <StyledModal
        data-a11y-ref={action + '-lead'}
        open={open}
        onCancel={handleCancel}
        footer={[]}
        $width={557}
      >
        {React.cloneElement(form, {
          onCancel: handleCancel,
        })}
      </StyledModal>
    </U1Dialog>
  );
};
