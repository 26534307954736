import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { StyledOrangeGradText } from '@/shared/styles';
import { StyledPolicyContainer } from '@/shared/pages/Policies/Policies.styles';
import { BeforeLoginMainProps, SubTitleProps, MainTitleProps } from './BeforeLogin.consts';
import Title from 'antd/lib/typography/Title';

export const StyledMain = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  min-height: 80%;
  color: ${(props) => props.theme.textColor};
  ${() => isMobile && `padding: 16px;`};
`;

export const StyledBgLayout = styled.div`
  width: 100%;
  height: 100%;

  ${StyledPolicyContainer} {
    padding: 60px;
  }
`;

export const StyledMainBeforeLogin = styled(StyledMain)<BeforeLoginMainProps>`
  color: ${(props) => props.theme.textColor};
  display: flex;
  flex-direction: column;
  gap: ${isMobile ? `100` : `120`}px;
  ${!isMobile && `padding: 60px;`};

  background-color: ${(props) => props.theme.backgroundColor};
  ${(props) => props.$bg && `background-image: url('${props.$bg}');`}
  background-repeat: no-repeat;

  @media only screen and (min-width: 2000px) {
    height: 120%;
  }
`;

export const StyledOrangeGradDot = styled(StyledOrangeGradText)`
  font-weight: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
`;

export const StyledHeadline = styled(Title)<MainTitleProps>`
  color: ${(props) => props.theme.textColor} !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;

  ${(props) => props.$weight && `font-weight: ${props.$weight} !important`};
  ${(props) => props.$flex && `align-self: ${props.$flex} !important`};
  ${(props) => props.$align && `text-align: ${props.$align} !important`};
  ${(props) => props.$width && `width: ${props.$width} !important`};
  ${(props) => props.$padding && `padding: ${props.$padding} !important`};
  ${(props) =>
    props.$gap &&
    `
      display: flex;
      gap: ${props.$gap}px;
    `};
  margin: ${(props) => props.$margin ?? `0`} !important;
  ${(props) =>
    props.$isRtl &&
    `text-align: end !important;
  `};
`;

export const StyledSubTitle = styled(StyledHeadline)<SubTitleProps>`
  font-size: ${(props) =>
    props.$size
      ? `${props.$size}`
      : props.$visualSizeLevel === 1
      ? '20px'
      : props.$visualSizeLevel === 2
      ? '18px'
      : props.$visualSizeLevel === 3
      ? '16px'
      : '16px'} !important;
`;

export const StyledMainTitle = styled(StyledHeadline)<MainTitleProps>`
  font-size: ${(props) =>
    props.$size
      ? `${props.$size}`
      : props.$visualSizeLevel === 1
      ? '44px'
      : props.$visualSizeLevel === 2
      ? '32px'
      : props.$visualSizeLevel === 3
      ? '28px'
      : props.$visualSizeLevel === 4
      ? '24px'
      : '20px'} !important;
`;
