import React from 'react';
import { StyledProjectsListCard, StyledProjectsListWrapper } from '../ProjectsPage.styles';
import { ProjectsListItem } from './ProjectsListItem';
import { ProjectsListProps } from '../../Projects.consts';

export const ProjectsList: React.FC<ProjectsListProps> = ({ projectsList }: ProjectsListProps) => {
  return (
    <StyledProjectsListCard>
      <StyledProjectsListWrapper role="list">
        {projectsList &&
          projectsList.map((el, index) => <ProjectsListItem key={index} projectsListItem={el} />)}
      </StyledProjectsListWrapper>
    </StyledProjectsListCard>
  );
};
