import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { FlexboxProps, TextProps } from '@/shared/props';
import { StyledAdjustableRow, StyledCol, StyledRow, StyledText } from '@/shared/styles';
import { StyledLinkNoStyle } from '@/shared/components/Link/Links.styles';
import { FooterContainerProps } from './Footer.consts';
import { StyledSubTitle } from '../BeforeLogin.styles';
import { isMobile, isMobileOnly } from 'react-device-detect';

export const StyledLandingPageFooter = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  width: 100%;
  background-color: ${(props) => props.theme.sameColorBG};
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.sameColorText};
`;

export const StyledLandingPageFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledMobileAdaptiveRow = styled(StyledAdjustableRow)`
  ${() => {
    const { isMobile } = useDeviceDetect();

    return (
      isMobile &&
      `
        flex-direction: ${isMobile ? 'column' : 'row'};
        gap: 60px;`
    );
  }}
`;

export const StyledTabletAdaptiveRow = styled(StyledAdjustableRow)`
  ${() => {
    const { isWideDesktop } = useDeviceDetect();

    return `
        flex-direction: ${isWideDesktop ? 'row' : 'column'} !important;
    `;
  }}
`;

export const StyledFooterUserDetails = styled(StyledCol)`
  gap: 24px;
`;

export const StyledFooterList = styled(StyledRow)<FlexboxProps>`
  ${(props) => {
    const { isMobile, isTablet, isWideDesktop } = useDeviceDetect();
    const { $width, theme } = props;

    return `
      flex: 1 1 auto;
      ${(!isMobile || !isTablet) && `justify-content: space-between;`}
      width: ${$width ?? (isWideDesktop ? 'fit-content' : '100%')};
      gap: ${isMobile ? '60' : isTablet ? '9' : '32'}px;
      font-size: 16px;
      color: ${theme.sameColorText};
      font-weight: 400;
    `;
  }}
`;

export const StyledFooterCol = styled(StyledCol)<FlexboxProps>`
  gap: 24px;
  a {
    color: ${(props) => props.theme.sameColorText} !important;
  }
  ${() => {
    const { isMobile } = useDeviceDetect();
    return `width: ${isMobile ? '100%' : 'fit-content'};`;
  }}
`;

export const StyledFooterTitle = styled(StyledSubTitle)`
  color: currentColor !important;
  word-break: auto-phrase;
`;

export const StyledFooterContent = styled(StyledText)`
  font-weight: inherit !important;
  font-size: inherit !important;
  color: currentColor !important;
`;

export const StyledFooterTrigger = styled.button`
  font-weight: inherit !important;
  font-size: inherit !important;
  color: currentColor !important;
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledFooterLinkNoStyle = styled(StyledLinkNoStyle)`
  font-weight: inherit !important;
  font-size: inherit !important;
  color: currentColor !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledDivider = styled.hr`
  border-top: 1px solid;
  border-color: ${(props) => props.theme.marginsColor};
  width: 100%;
`;

export const StyledLinksSection = styled(StyledCol)`
  gap: 16px;

  a {
    text-decoration: none;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  a:hover,
  button:hover {
    cursor: pointer !important;
  }
`;

export const StyledMobileIcons = styled.div`
  display: flex;
  gap: 16px;
  ${() => {
    const { isTablet } = useDeviceDetect();

    return `flex-direction: ${isTablet ? 'column' : 'row'};`;
  }}
`;

export const StyledFooterContainer = styled.div<FooterContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  ${(props) => (props.$gap ? `gap: ${props.$gap}px` : '')};
  ${() => {
    const { isMobile } = useDeviceDetect();

    return isMobile ? 'padding: 0 16px' : 'padding: 0 24px';
  }};

  max-width: ${(props) => {
    const { isMobile, isTablet } = useDeviceDetect();
    const { $size } = props;

    switch ($size) {
      case 'lg':
        return isMobile ? '100%' : isTablet ? '1288px' : '1768px';
      case 'md':
        return isMobile ? '100%' : isTablet ? '1288px' : '1568px';
      case 'sm':
        return isMobile ? '100%' : isTablet ? '888px' : '1168px';
      case 'full':
        return '100%';
      default:
        return isMobile ? '100%' : isTablet ? '1288px' : '1568px';
    }
  }};
`;

export const StyledPolicies = styled(StyledAdjustableRow)`
  gap: ${isMobileOnly ? '32' : '64'}px;
  justify-content: center;
`;

export const StyledFooterLink = styled(StyledFooterLinkNoStyle)<TextProps>`
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight} !important;`}
  ${!isMobile && `padding:  10px 20px;`}
`;
