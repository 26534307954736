import { U1Table } from '@/shared/components/Table/U1Table';
import { FormError } from '@/shared/components/Forms/FormError';
import { PanelTableProps } from './Panel.consts';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '@/stores/UserStore';
import { checkPermission } from '@/shared/utils/auth';

export const PanelTable: React.FC<PanelTableProps> = ({
  data,
  columns,
  columnsPermissions,
}: PanelTableProps) => {
  const { t } = useTranslation();
  const userPermissions = useUserStore((state) => state.permissions);
  const userType = useUserStore((state) => state.type);
  let tableColumns = [...columns];

  if (columnsPermissions) {
    tableColumns = tableColumns.filter(
      ({ key }) =>
        typeof key !== 'string' ||
        !columnsPermissions[key] ||
        checkPermission(userType, userPermissions, columnsPermissions[key])
    );
  }

  return (
    <U1Table
      columns={tableColumns}
      dataSource={[...data]}
      noDataMsg={
        <FormError message={t('admin.table.no_records')} $width="fit-content" $align="center" />
      }
    />
  );
};
