import { StyledCol, StyledInput, StyledRow, StyledSelect, StyledTextArea } from '@/shared/styles';
import { StyledLabel } from '../Forms.styles';
import { Form } from 'antd';
import { emailExp, linkedinUrlRegex, phoneNumberRegex } from '@/shared/regex';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { U1Button } from '@/shared/components/Button/U1Button';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { useLeadTechStackStore } from '@/stores/LeadStore/LeadTechStackStore';
import countryList from 'react-select-country-list';
import { LeadInnerFormProps } from './LeadForm.consts';

export const LeadInnerForm: React.FC<LeadInnerFormProps> = ({
  onCancel,
  onCountryChange,
  submitBtnText,
}: LeadInnerFormProps) => {
  const { t } = useTranslation();
  const { techStackGroups, fetchLeadTechStackGroups } = useLeadTechStackStore();
  const countries = useMemo(() => countryList().getData(), []);

  const techStackOptions = useMemo(
    () =>
      techStackGroups?.map(({ title, items }) => ({
        label: title,
        options: items.map(({ title, code }) => ({ label: title, value: code })),
      })),
    [techStackGroups]
  );

  useEffect(() => {
    fetchLeadTechStackGroups();
  }, [fetchLeadTechStackGroups]);

  return (
    <>
      <StyledCol $justify="center" $width="100%" $gap={8}>
        <StyledLabel>{t('form.first_name')}*</StyledLabel>
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: `${t('form.first_name')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput placeholder={t('form.first_name')} />
        </Form.Item>
        <StyledLabel>{t('form.last_name')}*</StyledLabel>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: `${t('form.last_name')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput placeholder={t('form.last_name')} />
        </Form.Item>
        <StyledLabel>{t('form.company_name')}*</StyledLabel>
        <Form.Item
          name="companyName"
          rules={[
            {
              required: true,
              message: `${t('form.company_name')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput placeholder={t('form.company_name')} />
        </Form.Item>
        <StyledLabel>{t('form.email')}*</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('form.valid_email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput placeholder="example@mail.com" />
        </Form.Item>
        <StyledLabel>{t('form.linkedin_url')}*</StyledLabel>
        <Form.Item
          name="linkedinUrl"
          rules={[
            {
              required: true,
              message: `${t('form.linkedin_url')} ${t('form.required')}`,
              pattern: linkedinUrlRegex,
            },
          ]}
        >
          <StyledInput placeholder={t('form.linkedin_url')} />
        </Form.Item>
        <StyledLabel>{t('vendor.deals.country')}</StyledLabel>
        <Form.Item name="country">
          <StyledSelect
            onChange={onCountryChange}
            showSearch={true}
            optionFilterProp="label"
            placeholder={t('vendor.deals.select_country')}
            options={countries}
          />
        </Form.Item>
        <StyledLabel>{t('vendor.deals.phone_number')}</StyledLabel>
        <Form.Item
          name="phone"
          rules={[
            {
              message: t('vendor.deals.phone_number.required'),
              pattern: phoneNumberRegex,
            },
          ]}
        >
          <StyledInput placeholder="+1 (XXX) XXX-XXXX" />
        </Form.Item>
        <StyledLabel>{t('form.tech_stack')}</StyledLabel>
        <Form.Item name="techStack">
          <StyledSelect
            mode="tags"
            placeholder={t('form.select_tech_stack')}
            tokenSeparators={[',']}
            options={techStackOptions}
          />
        </Form.Item>
        <StyledLabel>{t('form.message')}</StyledLabel>
        <Form.Item name="message">
          <StyledTextArea data-testid="messageField" placeholder={t('form.message')} />
        </Form.Item>
      </StyledCol>

      <StyledRow $justify="space-between" $width="100%" $gap={24}>
        <Form.Item>
          <GradientBorderButton
            text={t('cancel')}
            $height="50px"
            $width="100%"
            onClick={(e: Event) => onCancel(e)}
          />
        </Form.Item>
        <Form.Item>
          <U1Button text={submitBtnText ?? t('save_changes')} htmlType="submit" />
        </Form.Item>
      </StyledRow>
    </>
  );
};
