import { EditUserModal } from '@/shared/components/Modals/UserActionModals/EditUserModal';
import { AdminPermission } from '../../../Admin.consts';
import { EditVendorForm } from '@/shared/components/Forms/VendorForms/EditVendor';
import { ResendDetailsModal } from '@/shared/components/Modals/UserActionModals/ResendDetailsModal';
import { DeleteUserModal } from '@/shared/components/Modals/UserActionModals/DeleteUserModal';
import { VendorDetails } from '@/stores/Stores.consts';
import { USER_TYPE } from '@/shared/components/Modals/UserActionModals/UserModals.consts';

export type VendorActionsProps = {
  vendor: VendorDetails;
  getVendors: () => VendorDetails[];
};

export const getVendorActions = ({ vendor, getVendors }: VendorActionsProps) => [
  {
    permission: AdminPermission.UPDATE_VENDOR,
    component: <EditUserModal form={<EditVendorForm selectedUser={vendor} />} />,
  },
  {
    permission: AdminPermission.CREATE_VENDOR,
    component: <ResendDetailsModal id={vendor.id} userName={vendor.userName} />,
  },
  {
    permission: AdminPermission.DELETE_VENDOR,
    component: (
      <DeleteUserModal
        id={vendor.id}
        userName={vendor.companyName}
        userType={USER_TYPE.VENDOR}
        getUsers={getVendors}
      />
    ),
  },
];
