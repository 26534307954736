import { ColumnsType } from 'antd/lib/table';
import { ReactElement, ReactNode } from 'react';
import { DealsDetails } from '@/stores/Stores.consts';
import { TestingProps } from '@/shared/props';

export const DEFAULT_PAGE_SIZE = 3;

export type PanelProps = {
  isLoading: boolean;
  title: string;
  name: string;
  totalResults: number;
  canDownloadTable?: boolean;
  pageSize?: number;
  searchBy?: 'name' | 'email';
  createButton: ReactNode;
  table: ReactNode;
  panelType: PanelType;
  getSearch: (name: string) => void;
  changePage: (currentPage: number) => void;
};

export enum PanelType {
  ADMINS = 'admins',
  VENDORS = 'vendors',
  DEALS = 'deals',
  LEADS = 'leads',
}

export enum SearchNames {
  USER_NAME = 'userName',
  COMPANY_NAME = 'companyName',
}

export const userNameSearch: Record<string, SearchNames> = {
  admins: SearchNames.USER_NAME,
  vendors: SearchNames.USER_NAME,
  deals: SearchNames.COMPANY_NAME,
};

export type PanelTableProps = {
  data: any[];
  columns: ColumnsType<any>;
  columnsPermissions?: Record<string, string>;
};

export type UserActionsProps = TestingProps & {
  name: string;
  actionsDropdown: ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type SendCredentialsProps = {
  trigger: ReactElement;
  deal?: DealsDetails;
};
