import { DocsProps } from '../../../../Client/Projects/Projects.consts';
import { Page1 } from './Page1';
import { Page2 } from './Page2';
import { Page3 } from './Page3';

export const ComponentDoc: React.FC<DocsProps> = ({ page }: DocsProps) => {
  const renderPage = () => {
    switch (page) {
      case 1:
        return <Page1 />;
      case 2:
        return <Page2 />;
      case 3:
        return <Page3 />;
      default:
        return <Page1 />;
    }
  };

  return <>{renderPage()}</>;
};
