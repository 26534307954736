import { scrollToTop } from '@/shared/utils';
import { StyledFooterLinkNoStyle } from '../Footer.styles';
import { FooterLinkProps } from './FooterSections.consts';

export const FooterLink: React.FC<FooterLinkProps> = ({ name, to, onClick }: FooterLinkProps) => {
  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(e);
    scrollToTop();
  };
  return (
    <StyledFooterLinkNoStyle aria-label={name} to={to} onClick={onLinkClick}>
      {name}
    </StyledFooterLinkNoStyle>
  );
};
