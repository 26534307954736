import { StyledCard } from '@/shared/styles';
import styled, { css } from 'styled-components';

export const StyledDatepicker = styled(StyledCard)`
  padding: 40px;
`;

export const StyledNoStyleButton = styled.button`
  border: none;
  background: none;
`;

export const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 420px;

  .selected-day {
    background: ${(props) => props.theme.selectedDay};
    border-radius: 5px;
  }

  tr {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }

  td,
  th {
    color: ${(props) => props.theme.textColor};
    width: fit-content;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  td.disabled-day {
    color: ${(props) => props.theme.secondaryTextColor};
  }
`;

export const StyledTd = styled.td`
  &.disabled-day {
    color: ${(props) => props.theme.secondaryTextColor};
  }
  &.current-day {
    border: 2px solid ${(props) => props.theme.selectedDay};
    border-radius: 5px;
  }
  &.start-day {
    background: ${(props) => props.theme.selectedRange};
    border-radius: 26px 5px 5px 26px !important;
  }
  &.end-day {
    background: ${(props) => props.theme.selectedRange};
    border-radius: 5px 26px 26px 5px !important;
  }
`;

export const datepickerGlobalCss = css`
  .ant-dropdown.datepicker {
    top: 190px !important;
    box-shadow: 0px 24px 60px 0px rgba(15, 14, 22, 0.4) !important;
  }
`;
