/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {
  AbstractProps,
  AccordionProps,
  ButtonProps,
  CanvasProps,
  CarouselProps,
  CheckboxProps,
  ComboboxProps,
  DatepickerProps,
  DialogProps,
  FormProps,
  GridProps,
  LinkProps,
  ListboxProps,
  MenuPropsV2 as MenuProps,
  PaginationProps,
  RadioProps,
  TableProps,
  TabsProps,
  TooltipProps,
} from '@u1/fixers';

export enum Framework {
  REACT = 'react',
}

export enum LibraryName {
  ANTD = 'antd',
  U1 = 'u1',
}

export enum ComponentName {
  ACCORDION = 'accordion',
  BUTTON = 'button',
  CAROUSEL = 'carousel',
  CHECKBOX = 'checkbox',
  COMBOBOX = 'combobox',
  DATEPICKER = 'datepicker',
  DIALOG = 'dialog',
  CONTACT_US_DIALOG = 'contactUsDialog',
  CONFIRM_DIALOG = 'confirmDialog',
  ADD_DIALOG = 'addDialog',
  EDIT_DIALOG = 'editDialog',
  INVITE_DIALOG = 'inviteUsersDialog',
  COOKIES_DIALOG = 'cookiesDialog',
  CANCEL_SUBSCRIPTION_DIALOG = 'cancelSubscriptionDialog',
  RENEW_SUBSCRIPTION_DIALOG = 'renewSubscriptionDialog',
  UPDATE_PAYMENT_DIALOG = 'updatePaymentDialog',
  MOBILE_MENU_DIALOG = 'mobileMenuDialog',
  DROPDOWN = 'dropdown',
  POPUP = 'popup',
  COLOR_PICKER = 'colorPicker',
  FORM = 'form',
  GRID = 'grid',
  LINK = 'link',
  LISTBOX = 'listbox',
  MENU = 'menu',
  SIDMENU = 'sideMenu',
  RADIO = 'radio',
  TABLE = 'table',
  TABS = 'tabs',
  TREE = 'tree',
  CANVAS = 'canvas',
  TOOLTIP = 'tooltip',
  SEARCH = 'search',
  PAGINATION = 'pagination',
}

export type LibraryData = {
  selector: string;
  libraryName: LibraryName;
  componentName: string;
  props: AbstractProps;
  handleSpecialCases?: SpecialCases;
};

export type Library = {
  -readonly [key in keyof ComponentMapper]?: Component<ComponentMapper[key]>;
};

export type Libraries = {
  [Framework.REACT]: {
    [key in LibraryName]?: Library;
  };
};

export type Component<TProps extends AbstractProps> = {
  selector: string;
  props: TProps;
  handleSpecialCases?: SpecialCases<TProps>;
};

type SpecialCases<TProps extends AbstractProps> = (
  props: TProps,
  el: HTMLElement,
  refId: RefId
) => void;

// TODO: check if <any> type not causing issues
export type RefId = any;

export class ComponentMapper {
  accordion: AccordionProps;
  button: ButtonProps;
  search: ButtonProps;
  carousel: CarouselProps;
  checkbox: CheckboxProps;
  combobox: ComboboxProps;
  datepicker: DatepickerProps;
  dialog: DialogProps;
  confirmDialog: DialogProps;
  inviteUsersDialog: DialogProps;
  cookiesDialog: DialogProps;
  cancelSubscriptionDialog: DialogProps;
  renewSubscriptionDialog: DialogProps;
  updatePaymentDialog: DialogProps;
  contactUsDialog: DialogProps;
  addDialog: DialogProps;
  editDialog: DialogProps;
  mobileMenuDialog: DialogProps;
  dropdown: DialogProps;
  popup: DialogProps;
  form: FormProps;
  grid: GridProps;
  link: LinkProps;
  listbox: ListboxProps;
  menu: MenuProps;
  sideMenu: MenuProps;
  radio: RadioProps;
  table: TableProps;
  canvas: CanvasProps;
  tabs: TabsProps;
  tree: TabsProps;
  tooltip: TooltipProps;
  pagination: PaginationProps;
  colorPicker: DialogProps;
}

export type ComponentType = {
  -readonly [key in keyof ComponentMapper]?: ComponentMapper[key];
};
