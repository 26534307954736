import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { StyledText, StyledCol } from '@/shared/styles';
import React from 'react';
import {
  StyledProjectDashedBorderContainer,
  StyledProjectYellowOrangeTitle,
  StyledTextFrame,
} from '../ProjectsPage.styles';
import { PAGES } from '@/shared/consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const EmptyProjectsView: React.FC = () => {
  const { navigate } = useU1Navigation();
  const isActive = useSubscriptionStore((state) => state.isActive);

  return (
    <StyledProjectDashedBorderContainer>
      <StyledCol $gap={12} $align="center">
        <StyledProjectYellowOrangeTitle level={2}>
          Get started with U1
        </StyledProjectYellowOrangeTitle>
        <StyledTextFrame>
          <StyledText fontSize="16px" textAlign="center">
            {isActive
              ? 'You need to create a new project to have access to the package installation'
              : 'To create a new project you will need an active subscription'}
          </StyledText>
        </StyledTextFrame>
      </StyledCol>
      <GradientBorderButton
        onClick={() => {
          navigate(`${isActive ? PAGES.AFTER_LOGIN.NEW_PROJECT : PAGES.AFTER_LOGIN.BILLING}`);
        }}
        text={`${isActive ? `Create New Project` : `Renew subscription`}`}
      />
    </StyledProjectDashedBorderContainer>
  );
};
