import { getColumns, getColumnsPermissions } from './LeadsTable.consts';
import { PanelTable } from '../../../SearchPanel/PanelTable';
import { useLeadsStore } from '@/stores/LeadStore/LeadStore';

export const LeadsTable: React.FC = () => {
  const leads = useLeadsStore((state) => state.leads);

  return (
    <PanelTable data={leads} columns={getColumns()} columnsPermissions={getColumnsPermissions()} />
  );
};
