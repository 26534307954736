import { Form } from 'antd';
import styled from 'styled-components';
import { FormProps, FormStyleProps } from './Forms.consts';

export const StyledFormError = styled.div<FormStyleProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: ${(props) => props.theme.errorColor};
  font-weight: 400;
  font-size: 14px;
  background: ${(props) => props.theme.errorBG};
  padding: 8px;
  border-radius: 5px;
  align-items: end;
  width: ${(props) => props.$width ?? `fit-content`};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.$align && `align-self: ${props.$align}`};
`;

export const StyledErrorIcon = styled.div`
  display: flex;
`;

export const StyledHelperText = styled.div`
  color: ${(props) => props.theme.secondaryTextColor};
  font-weight: 400;
  font-size: 14px;
`;

export const StyledNewProjectForm = styled(Form)`
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  .ant-form-item {
    width: 100%;
  }
`;

export const StyledForm = styled(Form)<FormProps>`
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .ant-select-single {
    height: fit-content !important;
  }

  ${(props) => props.$marginTop && `margin-top: ${props.$marginTop};`}

  .ant-form-item {
    width: 100%;
  }

  .ant-form-item-control {
    max-width: inherit;
    width: 100%;
  }

  .ant-col-offset-8 {
    margin-left: 0px;
  }

  .ant-form-item:has(button:not(.remove-avatar)) {
    margin-bottom: 0px;
  }

  .ant-form-item-has-error input::placeholder,
  .ant-form-item-has-error textarea::placeholder {
    color: ${(props) => props.theme.errorColor};
    font-weight: 400;
    opacity: 1;
  }

  .ant-form-item-explain-error {
    padding: 5px 0px 5px 0px;
    font-weight: 400;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${(props) => props.theme.textColor};
    transition: background-color 5000s ease-in-out 0s;
  }

  ${(props) =>
    props.$darkMode &&
    `
input, textarea, select, .ant-input-affix-wrapper {
  background-color: ${props.theme.darkInputColor} !important;
}  
  `}
`;

export const StyledLabel = styled.label`
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  font-size: 16px !important;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: ${(props) => props.theme.textColor};
  }

  .ant-form-item-label > label::after {
    content: unset !important;
  }

  .ant-form-item-label > label {
    flex-direction: row-reverse;
  }
`;

export const StyleFormItemNoPadding = styled(Form.Item)`
  margin-bottom: 0px !important;

  .ant-form-item-explain-error {
    display: none;
  }
`;

export const StyledFormItemNoWidth = styled(Form.Item)`
  width: fit-content !important;
`;
