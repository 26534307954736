import { dottedBgUrl } from '@/assets/images';
import styled from 'styled-components';

export const StyledSelectOptions = styled.div`
  color: ${(props) => props.theme.disabledItemColor};
  font-weight: 400;
  text-align: center;
  background-image: url(${dottedBgUrl});
  border-radius: 15px;
  padding: 15px;
  margin-top: 15px;
`;
