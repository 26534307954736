import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { StyledCol, StyledInput } from '@/shared/styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledForm, StyledLabel } from '../../Forms.styles';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { useAxios } from '@/services/apiClient';
import { ResendTimer } from './ResendTimer';
import { RES_STATUS } from '@/shared/consts';
import { useAuth } from '@/hooks/Auth/useAuth';
import { MfaLoading } from './MfaLoading';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

export const MfaForm: React.FC = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const [{ response: userDetailsRes }] = useAxios({
    method: 'get',
    url: '/2fa-verification',
  });

  const [{}, postSendNotification] = useAxios(
    { method: 'post', url: '/2fa-verification/send' },
    { manual: true }
  );

  const [{ response: verifyResponse }, postVerifyEmail] = useAxios(
    { method: 'post', url: '/2fa-verification/verify' },
    { manual: true }
  );

  const onFinish = (values: any) => {
    postVerifyEmail({ data: { code: Number(values.code) } });
  };

  useEffect(() => {
    if (userDetailsRes) {
      setEmail(userDetailsRes.data.email);
      postSendNotification({ data: { notificationType: 'SMS' } });
    }
  }, [userDetailsRes]);

  useEffect(() => {
    if (verifyResponse && verifyResponse.status === RES_STATUS.CREATED) {
      login(verifyResponse.data);
    }
  }, [verifyResponse]);

  return email ? (
    <StyledForm name="basic" onFinish={onFinish} autoComplete="off">
      <StyledCol $gap={8}>
        <StyledLabel>{t('2fa.label')}</StyledLabel>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: `${t('2fa.code')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput
            $width={isMobileOnly ? '100%' : '440px'}
            placeholder={t('2fa.placeholder')}
          />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <StyledCol $gap={16} $align="start">
          <ResendTimer
            resendButton={
              <PinkGradientLink
                text={t('2fa.send_again')}
                onClick={() => {
                  postSendNotification({ data: { notificationType: 'SMS' } });
                }}
              />
            }
          />
          <ResendTimer
            resendButton={
              <PinkGradientLink
                text={`${t('2fa.send_to')} ${email}`}
                onClick={() => {
                  postSendNotification({ data: { notificationType: 'EMAIL' } });
                }}
              />
            }
          />
          <U1Button text={t('next')} htmlType="submit" />
        </StyledCol>
      </Form.Item>
    </StyledForm>
  ) : (
    <MfaLoading />
  );
};
