import { StyledBilling } from '@/shared/pages/Pricing/Packages/Package.styles';
import { StyledPackageTitle } from '@/shared/styles';
import { StyledCol, StyledCard, StyledRow, StyledSeparator, StyledTitle } from '@/shared/styles';
import { StyledPackageContainer, StyledPackageInfo } from './PackageDetails.styles';
import { UpgradePackageProps } from '../../UpgradePackage/UpgradePackage.consts';
import { SUBSCRIPTION_METHOD, getSubscriptionType } from '@/shared/consts';
import { useSelectionStore } from '@/stores/SelectionStore';
import { getPriceFormat } from '@/shared/utils';
import { useEffect, useState } from 'react';
import { SelectionStore, SubscriptionDetails } from '@/stores/Stores.consts';
import { PackageExtraSelection } from './ExtraSelection/PackageExtraSelection';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const PackageDetails: React.FC<UpgradePackageProps> = ({
  currentPlan,
}: UpgradePackageProps) => {
  const { t } = useTranslation();
  const selection = useSelectionStore();
  const [selectedPackage, setSelected] = useState<SubscriptionDetails | SelectionStore>();
  const isRtl = useLangStore((state) => state.isRtl);

  useEffect(() => {
    if (currentPlan) {
      setSelected(currentPlan);
    } else {
      setSelected(selection);
    }
  }, []);

  return (
    <StyledCard $width={'60%'}>
      <StyledPackageContainer
        $bg={selectedPackage?.imageUrl ?? ''}
        $direction={isRtl() ? 'left' : 'right'}
      >
        <StyledPackageInfo>
          <StyledCol $gap={8}>
            <StyledTitle $size={25} data-testid="packageName">
              {selectedPackage?.name}
            </StyledTitle>
            <StyledRow>
              <StyledPackageTitle data-testid="packagePrice">
                ${getPriceFormat(selectedPackage?.price ?? 0)}
              </StyledPackageTitle>
              <StyledBilling>
                / {t('pricing.billed')}{' '}
                {getSubscriptionType()[selectedPackage?.pricingMethod ?? SUBSCRIPTION_METHOD.YEAR]}
              </StyledBilling>
            </StyledRow>
          </StyledCol>
        </StyledPackageInfo>

        <StyledSeparator />

        <PackageExtraSelection currentPlan={currentPlan} />
      </StyledPackageContainer>
    </StyledCard>
  );
};
