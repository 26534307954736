import { IconButton } from '@/shared/components/Button/IconButton';
import { StyledEditIcons } from '../../../../Client/Projects/Projects.styles';
import { cancelIcon, editIcon, saveIcon } from '@/assets/images/icons';
import { EditIconsProps } from '../../../../Client/Projects/Projects.consts';
import { useDocsStore, useConfigDataStore } from '@/stores/ProjectStore/ProjectStore';
import { useTranslation } from 'react-i18next';

export const EditIcons: React.FC<EditIconsProps> = ({
  index,
  isVisible,
  isEditMode,
  objkeys,
  editableContent,
  originalContent,
  setIsEditMode,
  setContent,
}: EditIconsProps) => {
  const updateDocsObj = useDocsStore((state) => state.updateDocsObj);
  const updateConfigObj = useConfigDataStore((state) => state.updateConfigObj);
  const { t } = useTranslation();

  const saveContent = () => {
    if (objkeys) {
      if (typeof index === 'number') {
        updateConfigObj(objkeys, editableContent, index);
      } else {
        updateDocsObj(objkeys, editableContent);
      }
      setIsEditMode(false);
    }
  };

  const cancelEdit = () => {
    setContent(originalContent);
    setIsEditMode(false);
  };

  const editContent = () => {
    setIsEditMode(true);
  };

  return (
    <StyledEditIcons isVisible={isVisible}>
      {isEditMode ? (
        <>
          <IconButton
            $alt={t('projects.docs.component.edit.cancel')}
            onClick={cancelEdit}
            $icon={cancelIcon}
          />
          <IconButton
            $alt={t('projects.docs.component.edit.save')}
            onClick={saveContent}
            $icon={saveIcon}
          />
        </>
      ) : (
        <IconButton
          $alt={t('projects.docs.component.edit.edit')}
          onClick={editContent}
          $icon={editIcon}
        />
      )}
    </StyledEditIcons>
  );
};
