import { checkPermission } from '@/shared/utils/auth';
import { useUserStore } from '@/stores/UserStore';

export const useHasPermission = (requiredPermission: string): boolean => {
  const permissions = useUserStore((state) => state.permissions);
  const type = useUserStore((state) => state.type);

  return checkPermission(type, permissions, requiredPermission);
};

export const useHasPermissions = (requiredPermissions: string[]): boolean[] => {
  const permissions = useUserStore((state) => state.permissions);
  const type = useUserStore((state) => state.type);

  return requiredPermissions.map((requiredPermission) =>
    checkPermission(type, permissions, requiredPermission)
  );
};
