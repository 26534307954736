import React from 'react';
import { useAdminsStore } from '@/stores/AdminsStore';
import { VendorActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { ProtectedActionsDropdown } from '@/shared/components/Dropdown/ProtectedActionsDropdown';
import { getVendorActions } from './VendorActions.consts';

export const VendorActionsDropdown: React.FC<VendorActionsProps> = ({
  setIsOpen,
  vendor,
}: VendorActionsProps) => {
  const getVendors = useAdminsStore((state) => state.getVendors);

  return (
    <ProtectedActionsDropdown
      actions={getVendorActions({ vendor, getVendors })}
      setIsOpen={setIsOpen}
    />
  );
};
