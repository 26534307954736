import { create } from 'zustand';
import { AdminRoleStore } from './AdminRole.consts';
import { _axios } from '@/services/apiClient';

export const useAdminRoleStore = create<AdminRoleStore>((set, get) => ({
  roles: [],
  fetchRoles: (): void => {
    if (get().roles.length > 0) return;

    _axios.get('admins/roles').then((res) => {
      set({ roles: res.data });
    });
  },
}));
