export type FlagComponent = (props: any) => JSX.Element;
export type FlagsList = Record<string, FlagComponent>;
export type FlagType = 'rectangle' | 'square';

export const loadFlags = async (types: FlagType[]) => {
  const imports = await Promise.all(
    types.map((type) =>
      type === 'rectangle'
        ? import('country-flag-icons/react/3x2')
        : import('country-flag-icons/react/1x1')
    )
  );

  const result: Partial<Record<FlagType, FlagsList>> = {};
  types.forEach((type, index) => {
    result[type] = imports[index];
  });

  return result;
};
