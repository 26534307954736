import { css } from 'styled-components';

export const simpleSelectStyles = css`
  .ant-select.simple-select-trigger:not(.ant-select-customize-input) .ant-select-selector {
    background: none !important;
    border: none !important;
    width: fit-content;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }

  .select-month,
  .select-year {
    width: fit-content !important;
  }

  .ant-select-selection-wrap {
    gap: 8px;
  }

  .ant-select-multiple .ant-select-selector {
    min-height: 50px;
    height: fit-content !important;
  }

  .ant-select-multiple .ant-select-selection-wrap {
    display: block !important;
    height: 100%;
  }

  .ant-select.simple-select-trigger .ant-select-arrow {
    margin-top: -7px;
    right: 0px;
  }

  .ant-select-item.u1st-focused {
    color: ${(props) => props.theme.focusedText} !important;
  }

  .ant-select-selection-item {
    padding-inline-end: 8px !important;
  }

  .ant-select-selection-item,
  .ant-select,
  .ant-select-selector {
    line-height: unset !important;
  }
`;
