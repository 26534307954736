import React, { ReactElement, useEffect, useState } from 'react';
import { StyledModal } from '../Modals.styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { StyledRow, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { U1Button } from '../../Button/U1Button';
import { ConfirmModalProps } from '../Modals.consts';
import { FormError } from '../../Forms/FormError';
import { useTranslation } from 'react-i18next';

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  trigger,
  title,
  confirmBtnText,
  cancelBtnText,
  children,
  onConfirm,
  isOpen,
  onCancel,
  error,
  successMsg,
  setSuccessMsg,
}: ConfirmModalProps) => {
  const [isDefaultOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const closeFunc = () => {
    return onCancel ? onCancel() : setIsOpen(false);
  };

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        closeFunc();
        setSuccessMsg && setSuccessMsg('');
      }, 1000);
    }
  }, [successMsg]);

  return (
    <U1Dialog name={ComponentName.CONFIRM_DIALOG}>
      {isOpen === undefined
        ? React.cloneElement(trigger as ReactElement, {
            onClick: () => setIsOpen(true),
            'data-a11y-ref': 'confirm',
            'data-a11y-dialog': 'trigger',
          })
        : trigger}
      <StyledModal
        data-a11y-ref="confirm"
        width={550}
        open={isOpen ?? isDefaultOpen}
        onCancel={closeFunc}
        footer={[]}
        $width={450}
        $gap={24}
        $align={'center'}
      >
        {successMsg ? (
          <StyledSecondaryText fontWeight={400} fontSize={16}>
            {successMsg}
          </StyledSecondaryText>
        ) : (
          <>
            {error && <FormError message={error} $width={'fit-content'} $align={'center'} />}
            <StyledTitle $size={18} $weight={600} level={2}>
              {title}
            </StyledTitle>
            {children && <div>{children}</div>}
            <StyledRow $justify="space-between" $width="100%" $gap={24}>
              <GradientBorderButton
                text={cancelBtnText ?? t('no')}
                $height="50px"
                $width="50%"
                onClick={closeFunc}
              />
              <U1Button
                text={confirmBtnText ?? t('yes')}
                $height="50px"
                $width="50%"
                onClick={onConfirm}
              />
            </StyledRow>
          </>
        )}
      </StyledModal>
    </U1Dialog>
  );
};
