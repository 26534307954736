import { AdminsTable } from './AdminsTable/AdminsTable';
import { useEffect, useState } from 'react';
import { useAdminsStore } from '@/stores/AdminsStore';
import { Panel } from '../../SearchPanel/Panel';
import { AddUserModal } from '@/shared/components/Modals/UserActionModals/AddUserModal';
import { AddAdminForm } from '@/shared/components/Forms/AdminsForms/AddAdmin/AddAdminForm';
import { PanelType } from '../../SearchPanel/Panel.consts';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '@/hooks/Auth/usePermissions';
import { AdminPermission } from '../Admin.consts';

export const AdminsSection = () => {
  const adminsStore = useAdminsStore();
  const [adminName, setName] = useState('');
  const { t } = useTranslation();
  const canCreateAdmin = useHasPermission(AdminPermission.CREATE_ADMIN);

  useEffect(() => {
    adminsStore.getAdmins();
  }, []);

  const getAdmin = (adminName: string) => {
    setName(adminName);
    adminsStore.getAdminByName(adminName);
  };

  const changePage = (currentPage: number) => {
    adminsStore.getAdmins(currentPage, adminName);
  };

  return (
    <Panel
      isLoading={adminsStore.isLoading}
      panelType={PanelType.ADMINS}
      title={t('admin.admins.title')}
      name={adminName}
      totalResults={adminsStore.totalAdmins}
      createButton={
        <AddUserModal
          btnText={t('admin.admins.add_admin')}
          btnDisabled={!canCreateAdmin}
          form={<AddAdminForm />}
        />
      }
      table={<AdminsTable />}
      changePage={changePage}
      getSearch={getAdmin}
    />
  );
};
