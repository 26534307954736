import styled from 'styled-components';
import { Table } from 'antd';
import { TableProps } from '@/shared/props';

export const StyledTableWrapper = styled(Table)<TableProps>`
  .ant-table-cell {
    color: ${(props) => props.theme.textColor} !important;
    font-weight: 400 !important;
    width: ${(props) => (props.cellsInRow ? `calc(100% / ${props.cellsInRow})` : `fit-content`)};
  }

  .ant-table-cell:before {
    content: none !important;
  }

  .leads-table-cell {
    min-width: 120px;
    word-wrap: break-word;
  }

  .leads-table-cell ul {
    padding: 0;
  }

  .ant-table-content table,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr.ant-table-placeholder > td,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${(props) => props.theme.cardsColor} !important;
  }

  .ant-table-content table {
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.marginsColor} !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

  tbody td {
    border-top: 1px solid ${(props) => props.theme.marginsColor};
    border-bottom: none;
  }

  .ant-table-thead > tr {
    box-shadow: 3px 19px 26px -9px ${(props) => props.theme.shadowColor};
  }

  .ant-table-thead > tr > th {
    border-width: 1px;
    border-color: ${(props) => props.theme.tableHeadBorderColor};
    border-bottom: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  }

  .ant-table-container {
    background-color: ${(props) => props.theme.cardsColor};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${(props) => props.theme.menuItemHoverColor};
  }

  table {
    background-color: ${(props) => props.theme.cardsColor};
  }

  .ant-table {
    margin: auto;
    width: 100%;
    border-radius: 5px;
    background: unset !important;
  }

  .ant-table-column-sorter {
    display: none;
  }

  .ant-dropdown-trigger {
    float: right;
  }

  .ant-empty-description {
    color: ${(props) => props.theme.textColor};
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: unset !important;
  }

  /* Scrollbars */

  /* Chrome */

  /* width */
  &&::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &&::-webkit-scrollbar-track {
    background: ${(props) => props.theme.marginsColor};
  }

  /* Handle */
  &&::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Handle on hover */
  &&::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: ${(props) => props.theme.toggleDisabled} ${(props) => props.theme.marginsColor};

  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    width: 0px !important;
  }
`;

export const StyledSortIcon = styled.img`
  margin-bottom: 2px;
  margin-left: 5px;
`;

export const StyledTableErr = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
