import { Tabs } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import styled, { RuleSet } from 'styled-components';

export const StyledTabs = styled(Tabs)<{ $customStyle?: RuleSet<object> }>`
  ${(props) => props.$customStyle}

  .ant-tabs-tab-btn span,
  .ant-tabs-tabpane {
    color: ${(props) => props.theme.textColor};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:hover,
  .ant-tabs-tab-remove:hover {
    color: transparent !important;
    text-shadow: unset !important;
  }

  .ant-tabs-tab-btn:focus {
    outline-offset: -2px !important;
  }

  .ant-tabs-tab-btn span {
    padding: 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }

  .ant-tabs-tab-btn {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span {
    color: ${(props) => props.theme.secondaryTextColor} !important;
  }

  .ant-tabs-tab-active {
    background: ${(props) => props.theme.backgroundColor};
    border-top: 3px solid ${(props) => props.theme.secondaryTextColor};
  }

  .ant-tabs-tab {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 100%;
    justify-content: center;
    padding: 0px;
  }

  .ant-tabs-ink-bar {
    background: transparent !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    background: ${(props) => props.theme.marginsColor};
    padding: ${isMobileOnly ? `0px` : `0px 60px`};
    display: flex;
    justify-content: space-around;

    ${isMobileOnly && `overflow: scroll;`}
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px;
  }

  .ant-tabs-tabpane {
    padding: ${isMobileOnly ? '20' : '60'}px;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }
`;
