import { PAGES, UserRole } from '@/shared/consts';
import { Payment } from './Payment/Payment';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { Users } from './Users/Users';
import { Route, Routes } from 'react-router-dom';
import { Billing } from './Billing/Billing';
import { NewProject } from './Projects/NewProject/NewProject';
import { UpgradePackage } from './UpgradePackage/UpgradePackge';
import { ConfigurationPage } from '../Docs/ConfigurationPage/ConfigurationPage';
import { TermsAndConditions } from '@/shared/pages/Policies/TermsAndConditions';
import { PrivacyPolicy } from '@/shared/pages/Policies/PrivacyPolicy';
import { Projects } from './Projects/Projects';
import { DocumentationPage } from '../Docs/DocumentationPage/DocumentationPage';
import { AfterLoginPricing } from './Pricing/AfterLoginPricing';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useEffect, useState } from 'react';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { Loading } from '@/shared/components/Loading/Loading';
import { isMobile } from 'react-device-detect';
import { UnavailableOnMobile } from '@/shared/mobile/UnavailableOnMobile/UnavailableOnMobile';
import { CheckoutNavigator } from './Checkout/CheckoutNavigator';
import { StyledMainLoggedIn } from '../Main.styles';
import { AccessibilityStatement } from '@/shared/pages/Policies/AccessibilityStatement';
import { useUserStore } from '@/stores/UserStore';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { Eula } from '@/shared/pages/Policies/Eula';

export const ClientView: React.FC = () => {
  const subscription = useSubscriptionStore();
  const userRole = useUserStore((state) => state.role);
  const [defaultView, setDefaultView] = useState(<></>);
  const notAllowedInMobile = [
    PAGES.AFTER_LOGIN.PROJECTS,
    PAGES.AFTER_LOGIN.BILLING,
    PAGES.AFTER_LOGIN.USERS,
    PAGES.AFTER_LOGIN.NEW_PROJECT,
    PAGES.AFTER_LOGIN.CONFIGURATION,
    PAGES.AFTER_LOGIN.DOCS,
  ];

  useEffect(() => {
    if (
      (subscription.subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED ||
        subscription.subscriptionStatus === SUB_STATUS.NO_SUBSCRIPTION ||
        subscription.isFreemium) &&
      userRole !== UserRole.ENTERPRISE
    ) {
      setDefaultView(<AfterLoginPricing />);
    } else if (
      userRole === UserRole.ENTERPRISE ||
      (subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION && !isMobile)
    ) {
      setDefaultView(<Projects />);
    } else if (subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION && isMobile) {
      setDefaultView(<UnavailableOnMobile />);
    }
  }, [subscription]);

  const { availableLangs } = useLangStore();

  return (
    <StyledMainLoggedIn>
      <Routes>
        {/* lang routes */}
        {availableLangs.map((lang) => (
          <Route key={lang.code} path={`/${lang.code}`}>
            <Route path={PAGES.SHARED.DEFAULT.replace('/', '')} element={defaultView} />
            <Route
              path={PAGES.AFTER_LOGIN.PRICING.replace('/', '')}
              element={<AfterLoginPricing />}
            />
            <Route
              path={PAGES.AFTER_LOGIN.CHECKOUT.replace('/', '')}
              element={<CheckoutNavigator />}
            />
            <Route path={PAGES.AFTER_LOGIN.UPGRADE.replace('/', '')} element={<UpgradePackage />} />
            <Route path={PAGES.AFTER_LOGIN.PAYMENT.replace('/', '')} element={<Payment />} />
            <Route
              path={PAGES.SHARED.TERMS_AND_CONDITIONS.replace('/', '')}
              element={<TermsAndConditions />}
            />
            <Route
              path={PAGES.SHARED.PRIVACY_POLICY.replace('/', '')}
              element={<PrivacyPolicy />}
            />
            <Route
              path={PAGES.SHARED.ACCESSIBILITY_STATEMENT.replace('/', '')}
              element={<AccessibilityStatement />}
            />
            <Route path={PAGES.SHARED.EULA.replace('/', '')} element={<Eula />} />
            <Route
              path={PAGES.SHARED.NOT_FOUND.replace('/', '')}
              element={
                <MainNotFound
                  $homePath={isMobile ? PAGES.SHARED.DEFAULT : PAGES.AFTER_LOGIN.PROJECTS}
                />
              }
            />

            {isMobile ? (
              notAllowedInMobile.map((path, index) => {
                return (
                  <Route
                    path={path.replace('/', '')}
                    element={<UnavailableOnMobile />}
                    key={index}
                  />
                );
              })
            ) : (
              <>
                <Route path={PAGES.AFTER_LOGIN.PROJECTS.replace('/', '')} element={<Projects />} />
                <Route path={PAGES.AFTER_LOGIN.BILLING.replace('/', '')} element={<Billing />} />
                <Route path={PAGES.AFTER_LOGIN.USERS.replace('/', '')} element={<Users />} />
                <Route
                  path={PAGES.AFTER_LOGIN.NEW_PROJECT.replace('/', '')}
                  element={<NewProject />}
                />
                <Route
                  path={PAGES.AFTER_LOGIN.CONFIGURATION.replace('/', '')}
                  element={<ConfigurationPage />}
                />
                <Route
                  path={PAGES.AFTER_LOGIN.DOCS.replace('/', '')}
                  element={<DocumentationPage />}
                />
              </>
            )}
          </Route>
        ))}
        {/* default routes */}
        <Route path={PAGES.SHARED.DEFAULT} element={defaultView} />
        <Route path={PAGES.AFTER_LOGIN.PRICING} element={<AfterLoginPricing />} />
        <Route path={PAGES.AFTER_LOGIN.CHECKOUT} element={<CheckoutNavigator />} />
        <Route path={PAGES.AFTER_LOGIN.UPGRADE} element={<UpgradePackage />} />
        <Route path={PAGES.AFTER_LOGIN.PAYMENT} element={<Payment />} />
        <Route path={PAGES.SHARED.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
        <Route path={PAGES.SHARED.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={PAGES.SHARED.ACCESSIBILITY_STATEMENT} element={<AccessibilityStatement />} />
        <Route
          path={PAGES.SHARED.NOT_FOUND}
          element={
            <MainNotFound
              $homePath={isMobile ? PAGES.SHARED.DEFAULT : PAGES.AFTER_LOGIN.PROJECTS}
            />
          }
        />

        {isMobile ? (
          notAllowedInMobile.map((path, index) => (
            <Route path={path} element={<UnavailableOnMobile />} key={index} />
          ))
        ) : (
          <>
            <Route path={PAGES.AFTER_LOGIN.PROJECTS} element={<Projects />} />
            <Route path={PAGES.AFTER_LOGIN.BILLING} element={<Billing />} />
            <Route path={PAGES.AFTER_LOGIN.USERS} element={<Users />} />
            <Route path={PAGES.AFTER_LOGIN.NEW_PROJECT} element={<NewProject />} />
            <Route path={PAGES.AFTER_LOGIN.CONFIGURATION} element={<ConfigurationPage />} />
            <Route path={PAGES.AFTER_LOGIN.DOCS} element={<DocumentationPage />} />
          </>
        )}
      </Routes>
    </StyledMainLoggedIn>
  );
};
