import { U1Button } from '@/shared/components/Button/U1Button';
import { WhiteBorderButton } from '@/shared/components/Button/WhiteBorderButton';
import { RES_STATUS } from '@/shared/consts';
import { StyledGradientText } from '@/shared/pages/Pricing/GetInTouch/GetInTouch.styles';
import { useAxios } from '@/services/apiClient';
import { StripeCardPayment } from '@/shared/stripe/StripeCardPayment';
import { StyledCard, StyledRow, StyledSeparator, StyledTitle } from '@/shared/styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useEffect } from 'react';
import { DEALS_VIEW } from '../../../../Deals.consts';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DealSteps } from '@/stores/DealStore/DealsStore.consts';
import { t } from 'i18next';

export const DealPriceSection: React.FC = () => {
  const deals = useDealStore();
  const payment = usePaymentStore();
  const setDealsView = useVendorsStore((state) => state.setDealsView);

  const [{ response: stripePaymentRes }] = useAxios({
    method: 'post',
    url: `/deals/${deals.id}/payments/sessions`,
  });

  const [{ response: paymentStepRes }, updatePaymentStep] = useAxios(
    {
      method: 'patch',
      url: `/deals/${deals.id}`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (stripePaymentRes?.status === RES_STATUS.CREATED) {
      payment.setClientSecret(stripePaymentRes.data.token);
    }
  }, [stripePaymentRes]);

  useEffect(() => {
    if (paymentStepRes?.status === RES_STATUS.OK) {
      setDealsView(DEALS_VIEW.MAIN);
    }
  }, [paymentStepRes]);

  const goBack = (e: Event) => {
    e.preventDefault();
    deals.setDealStep(DealSteps.CLIENT);
  };

  const saveAsDraft = (e: Event) => {
    e.preventDefault();
    updatePaymentStep({ data: { step: 'PAYMENT' } });
  };

  return (
    <StyledCard $width="50%" $padding={40} $align="start" $gap={32}>
      <StyledRow $justify="space-between" $width="100%">
        <StyledTitle $size={18} level={2}>
          {t('vendor.deals.create_deal.payment.deal_price')}
        </StyledTitle>
        <StyledGradientText fontSize={18}>${deals.price}</StyledGradientText>
      </StyledRow>
      <StyledSeparator $width="100%" />
      <StripeCardPayment>
        <StyledSeparator $width="100%" />
        <StyledRow $gap={24}>
          <WhiteBorderButton
            text={t('vendor.deals.save_draft')}
            $width="100%"
            onClick={(e: Event) => saveAsDraft(e)}
          />
          <WhiteBorderButton
            text={t('vendor.deals.create_deal.payment.back')}
            $width="100%"
            onClick={(e: Event) => goBack(e)}
          />
          <U1Button text={t('vendor.deals.create_deal.payment.confirm_pay')} htmlType="submit" />
        </StyledRow>
      </StripeCardPayment>
    </StyledCard>
  );
};
