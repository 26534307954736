import { StyledCard, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { StyledBuildPackage } from '../Package.styles';
import { FrameworksObj, buildPackageSections } from './BuildPackage.consts';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useAxios } from '@/services/apiClient';
import { useEffect, useState } from 'react';
import { PackgeLoading } from './PackageLoading';
import {
  getAdditionalHoursOptions,
  getFrameworksOptions,
  getSubscriptionTypes,
} from '../../../../Deals.utils';
import { useTranslation } from 'react-i18next';

export const BuildPackages: React.FC = () => {
  const [subscriptinTypes, setSubscriptionTypes] = useState<string[]>([]);
  const [additionalHours, setAdditionalHours] = useState<number[]>([]);
  const [frameworks, setFrameworks] = useState<FrameworksObj>({
    titles: [],
    map: [],
    icons: [],
  });
  const deals = useDealStore();
  const { t } = useTranslation();

  const [{ data, loading }] = useAxios({ method: 'get', url: '/deals/settings' });

  useEffect(() => {
    if (data) {
      setSubscriptionTypes(getSubscriptionTypes(data));
      setFrameworks(getFrameworksOptions(data));
      setAdditionalHours(getAdditionalHoursOptions(data));
    }
  }, [data]);

  return (
    <StyledCard $width="100%" $padding={32} $align="start" $gap={32}>
      <StyledTitle level={2} $size={16} $weight={600}>
        {t('vendor.deals.create_deal.package.build_package')}
      </StyledTitle>

      {loading ? (
        <PackgeLoading />
      ) : (
        buildPackageSections().map((section, i) => {
          return (
            <StyledBuildPackage key={i}>
              <StyledTitle level={3} $weight={400} $size={16}>
                {section.title}
                {section.optional && (
                  <StyledSecondaryText fontWeight={400} fontSize={14} flex="start">
                    ({t('optional')})
                  </StyledSecondaryText>
                )}
              </StyledTitle>
              {section.text && (
                <StyledSecondaryText fontWeight={400} fontSize={14} flex="start" textAlign="left">
                  {section.text}
                </StyledSecondaryText>
              )}
              {section.render(deals, frameworks, subscriptinTypes, additionalHours)}
            </StyledBuildPackage>
          );
        })
      )}
    </StyledCard>
  );
};
