import { FRAMEWORK_PLATFORMS, FRAMEWORKS_ENUM, PLATFORM_PREFIXES } from '@/shared/consts';
import { ProjectListItemModel } from '@/Views/AfterLogin/Main/Client/Projects/Projects.consts';
import { appIdExp, comaRegex, domainRegex } from '@/shared/regex';
import { t } from 'i18next';

export const getNewProjectFormError = (project: ProjectListItemModel) => {
  const projectDomain =
    project.projectFramework === FRAMEWORKS_ENUM.ANDROID_XML_SDK
      ? t('projects.new_project.form.app_id')
      : t('projects.new_project.form.domain');
  let err = '';
  if (!project.projectDomain) {
    err += `${t('projects.new_project.form.insert')} ${projectDomain}, `;
  } else if (
    project.projectFramework === FRAMEWORKS_ENUM.ANDROID_XML_SDK &&
    !appIdExp.test(project.projectDomain)
  ) {
    err += `${t('projects.new_project.form.app.error')}, `;
  } else if (
    project.projectFramework !== FRAMEWORKS_ENUM.ANDROID_XML_SDK &&
    !domainRegex.test(project.projectDomain)
  ) {
    err += `${t('projects.new_project.form.domain.error')}, `;
  }
  if (!project.projectName) {
    err += `${t('projects.new_project.form.project.error')}, `;
  }
  if (!project.projectFramework) {
    err += `${t('projects.new_project.form.framework.error')}, `;
  }

  return err.replace(comaRegex, '.');
};

export const FrameworkPlatformPrefixes: Record<FRAMEWORK_PLATFORMS, PLATFORM_PREFIXES> = {
  [FRAMEWORK_PLATFORMS.WEB]: PLATFORM_PREFIXES.U1,
  [FRAMEWORK_PLATFORMS.MOBILE]: PLATFORM_PREFIXES.M1,
};

export const extractDomainValidationErrors = (errorsList: Record<string, string>[]) => {
  return errorsList.map((el: any) => el.messages.join(' ')).join('\n');
};

export const getTextWithoutComments = (text: string) => {
  const commentRegex = /\/\/.*?\n/g;
  return text.replace(commentRegex, '');
};
