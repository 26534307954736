import { StyledText, StyledTitle } from '@/shared/styles';
import React from 'react';
import {
  StyledAppImg,
  StyledAppTextContainer,
  StyledRightSection,
  StyledRightSectionInnerCol,
} from './Signin.styles';
import { useTranslation } from 'react-i18next';
import { appPreviewUrl } from '@/assets/images';

export const SignInRightSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledRightSection className="right-section">
      <StyledRightSectionInnerCol>
        <StyledAppImg alt={t('homepage.app_preview.alt')} src={appPreviewUrl} />
        <StyledAppTextContainer>
          <StyledTitle level={2} $weight={600} $size={20}>
            {t('sign_in.welcome.title')}
          </StyledTitle>
          <StyledText fontWeight={400} fontSize="16px">
            {t('sign_in.welcome.content')}
          </StyledText>
        </StyledAppTextContainer>
      </StyledRightSectionInnerCol>
    </StyledRightSection>
  );
};
