import { Form } from 'antd';
import { StyledForm } from '../../Forms.styles';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { AdminInnerForm } from '../InnerForm';
import { FormProps } from '../../Forms.consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { FormError } from '../../FormError';
import { extractErrors } from '@/shared/utils';
import { useFormMessages } from '@/hooks/useFormMessages';
import { useTranslation } from 'react-i18next';

export const AddAdminForm: React.FC<FormProps> = ({ onCancel }: FormProps) => {
  const [adminForm] = Form.useForm();
  const getAdmins = useAdminsStore((state) => state.getAdmins);
  const { message, setMessage, serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ data, error }, postAddAdmin] = useAxios(
    { url: '/admins', method: 'post' },
    { manual: true }
  );

  const closeAndResetForm = (onCancel: () => void) => {
    onCancel();
    adminForm.resetFields();
    getAdmins();
    clearAllMessages();
  };

  useEffect(() => {
    if (data && onCancel) {
      setMessage(t('admin.admins.add_admin.added'));
      setTimeout(() => {
        closeAndResetForm(onCancel);
      }, 1000);
    }
  }, [data]);

  useEffect(() => {
    if (error && error.response?.data.properties) {
      const errors = extractErrors(error.response);
      setError(errors);
    }
  }, [error]);

  const onFinish = (values: any) => {
    postAddAdmin({
      data: {
        email: values.email.trim(),
        userName: values.fullName.trim(),
        role: values.role.trim(),
        phone: values.phone.trim(),
      },
    });
  };

  const closeDialog = (e: Event) => {
    e.preventDefault();
    onCancel && onCancel();
  };

  return message ? (
    <StyledSecondaryText fontWeight={400} fontSize={16}>
      {message}
    </StyledSecondaryText>
  ) : (
    <StyledForm name="basic" onFinish={onFinish} autoComplete="off" form={adminForm}>
      {serverError && <FormError message={serverError} />}
      <StyledCol>
        <StyledTitle $size={18}>{t('admin.admins.add_admin')}</StyledTitle>
        <StyledSecondaryText fontWeight={400} fontSize={16} flex="start">
          {t('admin.admins.add_admin.content')}
        </StyledSecondaryText>
      </StyledCol>
      <AdminInnerForm
        onCancel={(e) => closeDialog(e)}
        submitBtnText={t('admin.admins.add_admin')}
      />
    </StyledForm>
  );
};
