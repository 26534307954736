import { ButtonProps, LinkProps } from '@/shared/props';
import { isMobile } from 'react-device-detect';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const gradientLinkStyle = css`
  background: ${(props) => props.theme.linkGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledPinkRouterLink = styled(Link)`
  ${gradientLinkStyle}
  white-space: nowrap;
`;

export const StyledPinkGradientLink = styled.a<LinkProps>`
  ${gradientLinkStyle}
  ${(props) =>
    props.inRow &&
    `display: flex;
  flex-direction: row;`}
  ${(props) => props.weight && `font-weight: ${props.weight};`}
  gap: 5px;
  min-width: fit-content;
  width: fit-content;
  align-items: center;
`;

export const StyledLinkNoStyle = styled(NavLink)<{ $isNavLink?: boolean }>`
  color: ${(props) =>
    props.$isNavLink ? props.theme.secondaryTextColor : props.theme.textColor} !important;
  font-weight: 400;
  text-decoration: none;

  ${(props) =>
    !props.$isNavLink &&
    `
    &:hover{
    text-decoration: underline;
    }
    `}
`;

export const StyledLink = styled.a<ButtonProps>`
  background: ${(props) => (props.$bgColor ? props.$bgColor : props.theme.buttonsBG)};
  ${(props) => props.disabled && `background: ${props.theme.disabledColor} !important`};
  color: ${(props) => props.$textColor ?? props.theme.textColor} !important;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  ${(props) => props.$height && `height: ${props.$height};`}
  ${(props) => props.$padding && `padding: ${props.$padding};`}
  cursor: pointer;

  ${isMobile &&
  `
  min-height: 42px;`}

  &:hover,
  &:focus {
    background: ${(props) => (props.$bgColor ? props.$bgColor : props.theme.buttonsBG)} !important;
    color: ${(props) => (props.$textColor ? props.$textColor : props.theme.textColor)} !important;
  }
`;

export const StyledGradientLink = styled.a<LinkProps>`
  width: ${(props) => props.width ?? `fit-content`};
  height: ${(props) => props.height ?? `fit-content`};
  padding: 10px;
  background: ${(props) => props.theme.buttonGradientBG};
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  z-index: 1;
  display: inline-block !important;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.cardsColor};
    z-index: -1;
  }

  &::after {
    content: attr(data);
    font-size: 16px;
    font-weight: 600;
    background: ${(props) => props.theme.buttonGradientBG};
    -webkit-background-clip: text;
    color: transparent;
  }
`;

export const StyledPlainLink = styled.a`
  color: ${(props) => props.theme.secondaryTextColor};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.secondaryTextColor};
  }
`;
