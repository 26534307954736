import { Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled, { css, keyframes } from 'styled-components';
import {
  BgImgType,
  CardProps,
  FlexboxProps,
  FrameProps,
  IconProps,
  InputProps,
  ListProps,
  SelectProps,
  SeparatorProps,
  ShimmerProps,
  TextProps,
  TitleProps,
} from '@/shared/props';
import Title from 'antd/lib/typography/Title';
import { SyntaxHighlighterProps } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/prism';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { dottedBgUrl } from '@/assets/images';

const baseInputStyle = css`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.inputColor} !important;
  border-color: ${(props) => props.theme.inputBorder} !important;
  border-radius: 5px;

  &::placeholder {
    color: ${(props) => props.theme.placeholderColor} !important;
  }
`;

export const StyledInput = styled(Input)<InputProps>`
  ${baseInputStyle};
  ${(props) => props.$width && `width: ${props.$width}`};
  height: ${(props) => props.$height ?? `50px`};
  ${(props) =>
    props.$error &&
    `::placeholder {
    color: ${props.theme.errorColor};
    opacity: 1;
  }`}

  &.ant-input-disabled {
    background: ${(props) => props.theme.disabledInput} !important;
  }
`;

export const StyledPasswordInput = styled(Input.Password)<InputProps>`
  input {
    ${baseInputStyle};
  }
  ${baseInputStyle};

  ${(props) => props.$width && `width: ${props.$width}`};
  height: ${(props) => props.$height ?? `50px`};
  ${(props) =>
    props.$error &&
    `::placeholder {
    color: ${props.theme.errorColor};
    opacity: 1;
  }`}

  .ant-input-password-icon.anticon {
    color: ${(props) => props.theme.textColor};
  }
`;

export const StyledTextArea = styled(TextArea)<InputProps>`
  ${baseInputStyle};
  height: ${(props) => props.$height ?? '100px'} !important;
  resize: none;
`;

export const StyledSelect = styled(Select)<SelectProps>`
  ${(props) => props.$width && `width:${props.$width};`}

  .ant-select-selector {
    color: ${(props) => props.theme.textColor};
    background: ${(props) => props.theme.inputColor} !important;
    border-color: ${(props) => props.theme.inputBorder} !important;
    height: 50px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  .ant-select-selector:has(input:focus-visible) {
    outline: 2px solid var(--outline-color, transparent) !important;
  }

  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.textColor} !important;
    opacity: 0.3;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-family: 'U1Poppins';
    font-size: 13px;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: ${(props) => props.theme.textColor} !important;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
`;

export const StyledPackageTitle = styled.span<TitleProps>`
  background: ${(props) => props.theme.orangeTextGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: ${(props) => (props.$size ? `${props.$size}` : `20`)}px;
  font-weight: 600;
  width: fit-content;
`;

const flexboxProps = css<FlexboxProps>`
  ${(props) => props.$justify && `justify-content: ${props.$justify}`};
  ${(props) => props.$align && `align-items: ${props.$align}`};
  ${(props) => props.$gap && `gap: ${props.$gap}px`};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.$wrap && `flex-wrap: ${props.$wrap}`};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
  ${(props) => props.$self && `align-self: ${props.$self}`};
`;

export const StyledRow = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${(props) => (props.$direction ? props.$direction : `row`)};
  ${flexboxProps}
`;

export const StyledRowOffset = styled(StyledRow)<FlexboxProps>`
  justify-content: space-between;
  width: 100%;
  ${(props) => props.$wrap && `flex-wrap: ${props.$wrap}`}
`;

export const StyledCol = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: column;
  ${(props) => props.$width && `width: ${props.$width};`}
  ${(props) => props.$height && `height: ${props.$height}`};
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  ${(props) => props.$align && `align-items: ${props.$align}`};
  ${(props) => props.$self && `align-self: ${props.$self}`};
  ${(props) => props.$justify && `justify-content: ${props.$justify}`};
  ${(props) => props.$gap && `gap: ${props.$gap}px`};
  ${(props) => props.$padding && `padding: ${props.$padding}px`};
  ${(props) => props.$langDir && `direction: ${props.$langDir}`};
`;

export const StyledColOffset = styled(StyledCol)`
  justify-content: space-between;
  height: 100%;
`;

export const StyledSeparator = styled.hr<SeparatorProps>`
  border-top: solid ${(props) => props.$color ?? props.theme.marginsColor};
  border-width: 2px 0px 0px 0px;
  width: ${(props) => props.$width ?? `95%`};
  border-radius: 5px;
  ${(props) => props.$margin && `margin: ${props.$margin};`}
`;

export const StyledH1 = styled.h1`
  color: ${(props) => props.theme.textColor};
`;

export const StyledText = styled.span<TextProps>`
  color: ${(props) => props.color ?? props.theme.textColor};
  font-family: ${(props) => props.fontFamily ?? "'Poppins'"};
  font-weight: ${(props) => (isMobile ? '400' : props.fontWeight ?? '600')} !important;
  font-style: ${(props) => props.fontStyle ?? 'normal'};
  text-align: ${(props) => props.textAlign ?? 'start'};
  font-size: ${(props) => (props.fontSize && !isMobile ? props.fontSize : '16px')} !important;
  line-height: ${(props) => props.lineHeight};
  ${(props) =>
    props.leftBorder &&
    `
  padding-left: 1rem;
  color: ${props.theme.textColor};
  border-left: 1px solid;
  border-image:
   ${props.theme.gradientBorder} 1 100%;
  `};
  ${(props) => props.align && `align-self: ${props.align};`}
  ${(props) => props.width && `width: ${props.width};`}
`;

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledHeader = styled.header<FrameProps>`
  background-color: ${(props) =>
    props.$isClient ? props.theme.marginsColor : props.theme.secondMarginsColor};
  padding: ${() => (isMobile ? '16px' : '20px')};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-weight: 600;

  ${(props) =>
    (!useThemeStore.getState().isDarkMode || !props.$isClient) &&
    `border-bottom: 1px solid ${props.theme.marginsBorder}`};
`;

export const StyledMain = styled.div`
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background-color: ${(props) => props.theme.backgroundColor};
  padding-bottom: 55px;
`;

export const StyledCard = styled(StyledCol)<CardProps>`
  background-color: ${(props) => props.theme.cardsColor};
  height: ${(props) => props.$height ?? `fit-content`};
  align-items: ${(props) => props.$align ?? `center`};
  ${(props) => props.$padding && `padding: ${props.$padding}px`};
  ${(props) => props.$gap && `gap: ${props.$gap}px`};
  ${(props) => props.$self && `align-self: ${props.$self}`};
  border-radius: 5px;
  box-sizing: border-box;
`;

export const StyledTitle = styled(Title)<TitleProps>`
  color: ${(props) => props.theme.textColor} !important;
  font-size: ${(props) =>
    props.$size
      ? `${props.$size}`
      : props.level === 2
      ? '24'
      : props.level === 3
      ? '18'
      : '32'}px !important;
  ${(props) => props.$weight && `font-weight: ${props.$weight} !important`};
  ${(props) => props.$flex && `align-self: ${props.$flex} !important`};
  ${(props) => props.$align && `text-align: ${props.$align} !important`};
  ${(props) => props.$width && `width: ${props.$width} !important`};
  ${(props) => props.$padding && `padding: ${props.$padding} !important`};
  ${(props) =>
    props.$gap &&
    `display: flex;
  gap: ${props.$gap}px;
  `};
  margin: ${(props) => props.$margin ?? `0`} !important;
  letter-spacing: 1px;
  ${(props) =>
    props.$isRtl &&
    `text-align: right !important;
  `};
`;

export const StyledContent = styled.div<TextProps>`
  ${(props) => props.fontSize && `font-size: ${props.fontSize} !important`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight} !important`};
  color: ${(props) => props.theme.textColor} !important;
  font-weight: 400;
  width: 100%;
  margin-bottom: 1em;
  text-align: ${(props) => props.textAlign ?? `center`};
`;

export const StyledSecondaryText = styled.span<TextProps>`
  align-self: ${(props) => (props.flex ? `flex-${props.flex}` : `center`)};
  text-align: ${(props) => props.textAlign ?? 'center'};
  font-size: ${(props) => props.fontSize ?? '12'}px;
  color: ${(props) => props.theme.secondaryTextColor};
  font-weight: ${(props) => props.fontWeight ?? '600'};
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.flex && `align-self: flex-${props.flex} !important`};
`;

export const StyledDimmedText = styled.span<TextProps>`
  color: ${(props) => props.theme.disabledItemColor};
  font-size: ${(props) => props.fontSize ?? '16'}px;
  font-weight: ${(props) => props.fontWeight ?? '400'};
`;

export const StyledPackageName = styled(StyledPackageTitle)`
  font-weight: 900;
  letter-spacing: 0px;
  font-size: 20px;
`;

export const StyledBgImg = styled.div<BgImgType>`
  background-image: url(${(props) => props.$bg});
  background-repeat: no-repeat;
  padding: ${(props) => props.$padding ?? `40`}px;
  ${(props) => props.$position && `background-position: ${props.$position}`};
  ${(props) => props.$size && `background-size: ${props.$size}px`};
  height: ${(props) => props.$height ?? `100%`};
  width: 100%;
  box-sizing: border-box;
`;

export const StyledLeftSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 50%;
`;

export const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
`;

export const StyledContainer = styled(StyledCol)`
  align-items: center;
  gap: ${isMobileOnly ? '90' : '150'}px;

  ${isDesktop &&
  `
  padding: 50px 182px;

  @media (max-width: 1024px) {
    padding: 50px;
  }
    `}
`;

export const StyledDashedBorderContainer = styled(StyledCol)`
  align-items: center;
  background-image: url(${dottedBgUrl});
  border-radius: 15px;
`;

export const gradientTitle = css<TitleProps>`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0px !important;

  font-weight: 650;
  font-size: ${(props) => props.$size ?? (isMobile ? '24' : '32')}px !important;
  font-family: 'U1Poppins';
  line-height: 48px;
  width: fit-content;
  letter-spacing: 0px;

  ${(props) => props.$align && `text-align: ${props.$align};`}
`;

export const StyledOrangeGradTitle = styled(Title)<TitleProps>`
  background: ${(props) => props.theme.orangeTextGradient};
  ${gradientTitle}
`;

export const StyledPurpleTitle = styled(Title)<TitleProps>`
  color: ${(props) => props.theme.purpleText} !important;
  margin-top: 0px !important;
  margin: 0px !important;
  align-self: ${(props) => props.$align ?? 'center'};
  ${(props) => props.$align && `text-align: ${props.$align};`}
  ${(props) => props.$noBreak && `white-space: nowrap;`}
  width: ${(props) => props.$width ?? 'fit-content'} !important;
  font-weight: 600;
  font-size: ${(props) => props.$size ?? (isMobile ? '24' : '32')}px !important;
`;

export const StyledPurpleText = styled.span<TextProps>`
  color: ${(props) => props.theme.purpleText} !important;
  font-weight: ${(props) => props.fontWeight ?? '600'};
  font-size: ${(props) => props.fontSize ?? '32px'};
  word-break: keep-all;
`;

const StyledList = css<ListProps>`
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  ${() => isMobile && `padding-left: 20px;`}

  ${(props) => props.$style && `list-style: ${props.$style};`}

  li {
    padding: 5px 10px 5px 0px;
  }
`;

export const StyledbulletList = styled.ul<ListProps>`
  ${StyledList}
`;

export const StyledNumberedList = styled.ol<ListProps>`
  ${StyledList}
`;

export const StyledRomanList = styled.ol<ListProps>`
  ${StyledList}
  list-style-type: upper-roman;
`;
export const StyledUnstyledList = styled.ol<ListProps>`
  ${StyledList}
  list-style-type: none;
`;

export const StyledCapitalized = styled.span`
  text-transform: capitalize;
`;

export const StyledSyntaxHighlighter = styled(SyntaxHighlighter)<SyntaxHighlighterProps>`
  padding: 0px !important;
  margin: 0px !important;

  &&& .linenumber {
    text-align: right !important;
    width: 30px;
  }
  &&& code {
    text-shadow: none !important;
  }
  &&&& pre * {
    font-weight: 400 !important;
    font-size: 16px !important;
    font-style: normal !important;
    text-shadow: none !important;
  }
`;

const ShimmerKeyframe = keyframes`
  0% {
    background-position: 0px top;
  }
  70% {
    background-position: 300px top;
  }
  100% {
    background-position: 300px top;
  }
`;

export const StyledShimmer = styled.div<ShimmerProps>`
  width: ${(props) => props.$width ?? '200'}px;
  height: ${(props) => props.$height ?? '200'}px;
  border-radius: 5px;
  background: ${(props) => props.theme.shimmerColor};
  -webkit-animation-name: ${ShimmerKeyframe};
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
`;

export const StyledLoading = styled(StyledCol)`
  align-items: center;
  margin: ${isMobile ? '100px 0px' : '50px 0px'};
`;

export const StyledCapitalizedTitle = styled(StyledTitle)`
  text-transform: capitalize;
`;

export const StyledOrangeGradText = styled.span<TextProps>`
  background: ${(props) => props.theme.orangeTextGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-weight: 600;
  font-size: ${(props) => (isMobile ? '20' : props.fontSize ?? '32')}px !important;
  line-height: 48px;
`;

export const StyledSubTitle = styled.span<TextProps>`
  color: ${(props) => props.fontWeight ?? props.theme.textColor};
  font-weight: ${(props) => props.fontWeight ?? '600'};
  font-size: ${(props) => props.fontSize ?? '20px'};
  line-height: 30px;
`;

export const StyledImg = styled.img<IconProps>`
  cursor: pointer;
  ${(props) => props.$size && `height: ${props.$size}px;`}
  ${(props) => props.$size && `width: ${props.$size}px;`}
  ${(props) => props.$top && `margin-top: ${props.$top}px;`}
`;

export const StyledMobileDisabled = styled.div`
  background: transparent;
  color: ${(props) => props.theme.secondBorder};
  border: 2px solid ${(props) => props.theme.secondBorder};
  border-radius: 5px;
  padding: 8px 0px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

export const StyledIcon = styled.img<IconProps>`
  ${(props) => props.$height && `height: ${props.$height}px;`}
  ${(props) => props.$top && `margin-top: ${props.$top}px;`}
  ${(props) => props.$right && `margin-right: ${props.$right}px;`}
  ${(props) => props.$left && `margin-left: ${props.$left}px;`}
  ${(props) => props.$borderRadius && `border-radius: ${props.$borderRadius}px;`}
  ${(props) => props.$position && `position: ${props.$position};`}
  ${(props) => props.$width && `width: ${props.$width}px;`}
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}px;`}
  ${(props) => props.$invert && `filter: invert(100%);`}
`;

export const StyledItalicText = styled(StyledText)`
  font-style: italic;
`;

export const StyeldXSmallTitle = styled(StyledTitle)`
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: normal !important;
`;

export const StyledActiveText = styled.span`
  color: ${(props) => props.theme.successStatus};
`;

export const StyledDivider = styled.div<{ $color?: string; $align?: string }>`
  width: 2px;
  height: 24px;
  background-color: ${(props) => props.$color ?? props.theme.marginsColor};
  ${(props) => props.$align && `align-self: ${props.$align};`}
`;

export const StyledListNoBullets = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  color: ${(props) => props.theme.textColor};
`;

export const StyledBold = styled.span`
  font-weight: 900;
`;

export const StyledLink = styled.a`
  height: fit-content;
  color: ${(props) => props.theme.textColor};

  &:hover {
    color: ${(props) => props.theme.textColor};
    text-decoration: underline;
  }
`;

export const StyledTableActionLink = styled.a`
  height: fit-content;
  padding: 5px 12px;
  text-decoration: none;
  color: ${(props) => props.theme.textColor};

  &:hover {
    color: ${(props) => props.theme.textColor};
  }
`;

export const StyledAdjustableRow = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  ${flexboxProps}
`;

export const StyledAdjustableCol = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${isMobile ? 'row' : 'column'};
  ${flexboxProps}
`;

export const StyledTabletRow = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${isTablet ? 'row' : 'column'};
  ${flexboxProps}
`;

export const StyledAdjustableReversedRow = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row-reverse'};
  ${flexboxProps}
`;
