import styled from 'styled-components';
import { Typography } from 'antd';
import { TextProps } from '@/shared/props';

const { Title } = Typography;

export const StyledGetInTouch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 45px;
`;

export const StyledTitle = styled(Title)`
  color: ${(props) => props.theme.textColor} !important;
  font-size: 25px;
  font-weight: 600;
`;

export const StyledGradientText = styled.span<TextProps>`
  background: ${(props) => props.theme.orangeTextGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
`;

export const StyledEmail = styled.a`
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  align-self: flex-start;

  &:hover {
    color: ${(props) => props.theme.textColor};
  }
`;
