import {
  StyledCol,
  StyledIcon,
  StyledRow,
  StyledSecondaryText,
  StyledText,
  StyledTitle,
} from '@/shared/styles';
import { Styled2fcDetails, StyledVendorCard } from '../../../../Main.styles';
import { personalSettingsIcon } from '@/assets/images/icons';
import { useUserStore } from '@/stores/UserStore';
import { hasFlag } from 'country-flag-icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { FlagsList, loadFlags } from '@/shared/utils/flags';

export const MfaSettings: React.FC = () => {
  const user = useUserStore();
  const { t } = useTranslation();

  const [rectangleFlags, setRectangleFlags] = useState<FlagsList | undefined>({});

  useEffect(() => {
    const load = async () => {
      const { rectangle } = await loadFlags(['rectangle']);
      setRectangleFlags(rectangle);
    };

    load();
  }, []);

  const PhoneFlag = useMemo(
    () => (rectangleFlags && user.country ? rectangleFlags[user.country] : null),
    [rectangleFlags, user.country]
  );

  const isFlagExist = (): boolean => {
    return !!user.country && hasFlag(user.country);
  };

  return (
    <StyledVendorCard>
      <StyledCol $gap={12}>
        <StyledTitle level={2} $size={16} $gap={12}>
          <StyledIcon src={personalSettingsIcon} />
          {t('vendor.settings.2fa')}
        </StyledTitle>
        <StyledText fontSize={14} fontWeight={400}>
          {t('vendor.settings.2fa.description')}
        </StyledText>
      </StyledCol>
      <StyledRow $gap={12}>
        <Styled2fcDetails>
          {isFlagExist() && PhoneFlag && (
            <PhoneFlag aria-hidden="true" title={user.country} width={25} />
          )}
          {user.phone}
        </Styled2fcDetails>
        <StyledSecondaryText fontWeight={400} fontSize={16}>
          {t('vendor.settings.or')}
        </StyledSecondaryText>
        <Styled2fcDetails>{user.email}</Styled2fcDetails>
      </StyledRow>
    </StyledVendorCard>
  );
};
