import { TableContent } from '@/Views/AfterLogin/Main/Client/Projects/Projects.consts';
import { ColumnsType } from 'antd/lib/table';
import { v4 as uuidv4 } from 'uuid';
import { ApiTableCell } from './DocsContent/Api/ApiTableCell';
import { StyledTestList } from './DocumentationPage.styles';
import { StyledBold } from '@/shared/styles';

export const generateApiTableColumns = (keys: TableContent, objkeys: string[]) => {
  const columns: ColumnsType = [];
  Object.keys(keys).forEach((column) => {
    const columnTitle = column.charAt(0).toUpperCase() + column.slice(1);
    columns.push({
      key: uuidv4(),
      title: columnTitle,
      dataIndex: column,
      render: (data, _, index) => {
        return (
          <ApiTableCell
            index={index}
            cellData={data}
            columnName={column}
            objkeys={[...objkeys, column]}
          />
        );
      },
    });
  });
  return columns;
};

export const generateApiTableData = (dataTable?: TableContent[], newCell?: boolean) => {
  const data: TableContent[] = [];
  dataTable?.forEach((item: TableContent) => {
    data.push({ ...item, key: uuidv4() });
  });

  if (newCell) {
    data.push({
      property: '',
      description: '',
      key: uuidv4(),
    });
  }
  return data;
};

export const getLines = (text: string) => {
  return text.split(/{{|}}|\n/).map((line, i) => {
    const props = { key: `line${i}` };
    return line ? (
      <span {...props}>
        {editConfigText(line)}
        <br />
      </span>
    ) : (
      <br {...props} />
    );
  });
};

export const getList = (text: string) => {
  const content = JSON.parse(text);
  return (
    <StyledTestList>
      {content?.map((el: string, i: number) => (
        <li key={`test${i}`}>{el}</li>
      ))}
    </StyledTestList>
  );
};

export const editConfigText = (text: string): JSX.Element[] => {
  const regex = /\*(.*?)\*/g;
  const boldWords = text.match(regex);
  const jsxElements: JSX.Element[] = [];
  boldWords?.forEach(() => {
    const segments = text.split(regex);
    jsxElements.push(
      <>
        {segments[0]} <StyledBold>{segments[1]}</StyledBold>
        {segments[2]}
      </>
    );
  });
  return jsxElements.length ? jsxElements : [<>{text}</>];
};
