import { Select } from 'antd';
import { SelectProps } from './Select.consts';
import { ArrowDownIcon } from '@/assets/images/icons';
import { Icon } from '../Icon';
import { U1Listbox } from '@u1/react-a11y-hooks';
import { CaretDown } from '../Caret/CaretDown';

export const SimpleSelect: React.FC<SelectProps> = ({
  label,
  value,
  options,
  className,
  onSelect,
  showIcon,
  ...rest
}: SelectProps) => {
  const icon = showIcon === false ? {} : { suffixIcon: <Icon $src={ArrowDownIcon} $alt="" /> };
  return (
    <U1Listbox>
      <span className="u1st-sr-only simple-select-label">{label}</span>
      <Select
        placement="bottomRight"
        className={`${className ?? ``} simple-select-trigger`}
        popupClassName="select"
        value={value}
        onChange={onSelect}
        options={options}
        suffixIcon={<CaretDown />}
        {...rest}
        {...icon}
      />
    </U1Listbox>
  );
};
