import React, { useEffect, useState } from 'react';
import {
  StyledCol,
  StyledInput,
  StyledRowOffset,
  StyledSecondaryText,
  StyledTitle,
} from '@/shared/styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledForm, StyledFormItem, StyledLabel } from '../Forms.styles';
import { RadioGroup } from '../../Radio/Radio';
import { InfoMessage } from '../../InfoMessage/InfoMessage';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { FormProps, getCancellationReasons } from '../Forms.consts';
import { useSubscription } from '@/hooks/useSubscription';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { getDateTemplate } from '@/shared/utils';
import { LOCALES, RES_STATUS } from '@/shared/consts';
import { ConfirmModal } from '../../Modals/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';

export const CancellationForm: React.FC<FormProps> = ({ onOk, onCancel }: FormProps) => {
  const [inputValue, setInputValue] = useState('');
  const [cancelKey, setCancelKey] = useState(0);
  const { cancelSubscription, cancelResponse } = useSubscription();
  const subscriptionExpiration = useSubscriptionStore((state) => state.expiryDate);
  const { t } = useTranslation();

  useEffect(() => {
    if (cancelResponse?.status === RES_STATUS.OK && onOk) {
      onOk();
    }
  }, [cancelResponse]);

  const onConfirm = () => {
    cancelSubscription({
      data: {
        autoPayment: false,
        cancelation: {
          reason: getCancellationReasons()[cancelKey],
          comment: inputValue,
        },
      },
    });
  };

  return (
    <StyledForm name="basic" autoComplete="off" layout="vertical">
      <StyledCol $gap={20}>
        <StyledTitle $size={18} $align="center">
          {t('billing.package_details.cancel_subscription.modal.title')}
        </StyledTitle>
        <StyledSecondaryText fontSize="14" textAlign="center" fontWeight={400}>
          {t('billing.package_details.cancel_subscription.modal.subtitle')}
        </StyledSecondaryText>
        <StyledFormItem>
          <RadioGroup
            value={cancelKey}
            setValue={setCancelKey}
            radioValues={Object.values(getCancellationReasons())}
          />
        </StyledFormItem>
        <StyledFormItem
          name="comment"
          label={
            <StyledLabel>
              {t('billing.package_details.cancel_subscription.modal.comment')}
            </StyledLabel>
          }
          colon={false}
          rules={[
            {
              required: getCancellationReasons()[cancelKey] === 'Other',
              message: t('billing.package_details.cancel_subscription.modal.leave_comment'),
            },
          ]}
        >
          <StyledInput
            placeholder={t(
              'billing.package_details.cancel_subscription.modal.leave_comment.placeholder'
            )}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </StyledFormItem>
        <InfoMessage
          message={`${t(
            'billing.package_details.cancel_subscription.modal.subscription_expiration.intro1'
          )} ${getDateTemplate(subscriptionExpiration, LOCALES.DAY_MONTH_YEAR)} ${t(
            'billing.package_details.cancel_subscription.modal.subscription_expiration.intro2'
          )}`}
          $margin={'20px 0px 0px 0px'}
        />
        <StyledRowOffset $gap={20}>
          <GradientBorderButton
            text={t('billing.package_details.cancel_subscription.modal.dont_cancel')}
            $height="40px"
            $width="100%"
            $padding="0px"
            onClick={onCancel}
          />
          <StyledFormItem>
            <ConfirmModal
              title={t('billing.package_details.cancel_subscription')}
              trigger={
                <U1Button
                  dataTestId="submitCancellationFormButton"
                  text={t('billing.package_details.cancel_subscription.modal.submit_cancellation')}
                  $height="40px"
                  $width="100%"
                  $padding="0px 30px"
                  htmlType="submit"
                  triggerRef="confirm"
                />
              }
              onConfirm={onConfirm}
            >
              <StyledSecondaryText fontSize={16} fontWeight={400}>
                {t('billing.package_details.cancel_subscription.modal.confirm_cancellation')}
              </StyledSecondaryText>
            </ConfirmModal>
          </StyledFormItem>
        </StyledRowOffset>
      </StyledCol>
    </StyledForm>
  );
};
