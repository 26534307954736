import { StyledCol, StyledInput, StyledRow } from '@/shared/styles';
import { StyledLabel } from '../Forms.styles';
import { Form } from 'antd';
import { emailExp, fullNameExp, phoneNumberRegex } from '@/shared/regex';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { U1Button } from '@/shared/components/Button/U1Button';
import { InnerFormProps } from '../Forms.consts';
import { useTranslation } from 'react-i18next';

export const VendorInnerForm: React.FC<InnerFormProps> = ({
  onCancel,
  submitBtnText,
}: InnerFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledCol $justify="center" $width="100%" $gap={8}>
        <StyledLabel>{t('vendor.deals.company_name')}</StyledLabel>
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: `${t('vendor.deals.company_name')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput placeholder={t('vendor.deals.company_name')} />
        </Form.Item>
        <StyledLabel>{t('form.full_name')}</StyledLabel>
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: `${t('form.full_name')} ${t('form.required')}`,
              pattern: fullNameExp,
            },
          ]}
        >
          <StyledInput placeholder={t('form.full_name')} />
        </Form.Item>
        <StyledLabel>{t('form.email')}</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('form.valid_email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput placeholder={t('form.email.placeholder')} />
        </Form.Item>
        <StyledLabel>{t('vendor.deals.phone_number')}</StyledLabel>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: t('vendor.deals.phone_number.required'),
              pattern: phoneNumberRegex,
            },
          ]}
        >
          <StyledInput placeholder={t('vendor.deals.phone_number')} />
        </Form.Item>
      </StyledCol>

      <StyledRow $justify="space-between" $width="100%" $gap={24}>
        <Form.Item>
          <GradientBorderButton
            text={t('cancel')}
            $height="50px"
            $width="100%"
            onClick={(e: Event) => onCancel(e)}
          />
        </Form.Item>
        <Form.Item>
          <U1Button text={submitBtnText ?? t('save_changes')} htmlType="submit" />
        </Form.Item>
      </StyledRow>
    </>
  );
};
