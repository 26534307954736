import { StyledRow } from '@/shared/styles';
import { Button, Menu } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.cardsColor};
  color: ${(props) => props.theme.textColor};
  border: none;
  border-radius: 5px !important;
  padding: 0 8px;

  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.cardsColor} !important;
    border: none;
    border-radius: 5px !important;
    padding: 0 8px;
  }
`;

export const StyledMenu = styled(Menu)`
  background: ${(props) => props.theme.cardsColor};
  border: 1px solid ${(props) => props.theme.marginsColor};
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  width: 170px;

  li:hover {
    background: ${(props) => props.theme.menuItemHoverColor};
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: ${(props) => props.theme.textColor} !important;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  padding: 5px;
  margin: 0px;
  background-color: ${(props) => props.theme.cardsColor} !important;
`;

export const StyledDisabledMenuItem = styled(StyledMenuItem)`
  .ant-dropdown-menu-title-content {
    color: ${(props) => props.theme.disabledItemColor} !important;
  }
`;

export const StyledMenuItemRow = styled(StyledRow)`
  justify-content: space-between;
  color: ${(props) => props.theme.textColor};
`;
