import React from 'react';
import { usePaymentStore } from '@/stores/PaymentStore';
import { PaymentForm } from './PaymentForm';
import { StyledStripePayment } from './Stripe.styles';
import { PaymentFormProps } from './Stripe.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { useState } from 'react';
import { useEffect } from 'react';

export const StripeCardPayment: React.FC<PaymentFormProps> = ({
  paymentStatus,
  children,
}: PaymentFormProps) => {
  const clientSecret = usePaymentStore((state) => state.clientSecret);
  const isDarkMode = useThemeStore((state) => state.isDarkMode);

  const appearance = {
    theme: isDarkMode ? 'night' : 'flat',
  };

  const [stripe, setStripe] = useState<any>(null);
  const [Elements, setElements] = useState<any>(null);

  useEffect(() => {
    if (clientSecret) {
      import('@stripe/stripe-js').then((module) => {
        module.loadStripe(import.meta.env.VITE_STRIPE_PK).then(setStripe);
      });

      import('@stripe/react-stripe-js').then((module) => {
        setElements(() => module.Elements);
      });
    }
  }, [clientSecret]);

  return (
    <StyledStripePayment className="Stripe-App">
      {clientSecret && stripe && Elements && (
        <Elements options={{ clientSecret, appearance }} stripe={stripe}>
          <PaymentForm paymentStatus={paymentStatus}>{children}</PaymentForm>
        </Elements>
      )}
    </StyledStripePayment>
  );
};
