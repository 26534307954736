import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { useAuth } from './Auth/useAuth';
import { PAGES, RES_STATUS } from '@/shared/consts';
import { useAuthStore } from '@/stores/AuthStore';
import { LOGIN_STATE } from '@/stores/Stores.consts';
import { useU1Navigation } from './useU1Navigation';

export const useRedirect = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { navigate } = useU1Navigation();
  const setLoginState = useAuthStore((state) => state.setLoginState);
  const { getUser, login } = useAuth();

  const [{ data: redirectToken, loading: loadingToken, error: tokenError }, getRedirectToken] =
    useAxios(
      {},
      {
        manual: true,
      }
    );

  useEffect(() => {
    if (location && searchParams && location.pathname.includes('redirect')) {
      const queryParams = Object.fromEntries(searchParams);
      const path = `${location.pathname.replace('redirect', 'callback')}`;
      getRedirectToken({
        url: path,
        params: queryParams,
      });
    } else {
      // Suppress 401 error in console if not logged in
      getUser().catch((error) => {
        if (error.response?.status === 401) {
          return Promise.resolve();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (redirectToken) {
      login(redirectToken);
    }
  }, [redirectToken]);

  useEffect(() => {
    if (tokenError?.response?.status === RES_STATUS.FORBIDDEN) {
      setLoginState(LOGIN_STATE.SECURITY_ALERT);
      navigate(PAGES.BEFORE_LOGIN.SECURITY_ALERT);
    }
  }, [tokenError]);

  return { loadingToken };
};
