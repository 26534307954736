import { useAuth } from '@/hooks/Auth/useAuth';
import { StyledSecondaryText } from '@/shared/styles';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { PROMPT_BEFORE_IDLE, INACTIVITY_TIMEOUT_MS } from './InactivityModal.consts';
import { useTranslation } from 'react-i18next';

export const InactivityModal = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(INACTIVITY_TIMEOUT_MS);
  const { logout } = useAuth();
  const { t } = useTranslation();

  const onIdle = () => {
    logout();
  };

  const onActive = () => {
    setShowPopup(false);
  };

  const onPrompt = () => {
    setShowPopup(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: INACTIVITY_TIMEOUT_MS,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStillHere = () => {
    activate();
    setShowPopup(false);
  };

  return (
    <ConfirmModal
      title={t('inactivity.title')}
      confirmBtnText={t('inactivity.confirm')}
      cancelBtnText={t('inactivity.cancel')}
      onConfirm={handleStillHere}
      isOpen={showPopup}
      onCancel={logout}
    >
      <StyledSecondaryText fontSize="14px" fontWeight={400}>
        {t('inactivity.expire_message_before_remaining_time')} {remainingTime}{' '}
        {t('inactivity.expire_message_after_remaining_time')}
      </StyledSecondaryText>
    </ConfirmModal>
  );
};
