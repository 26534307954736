import React, { useEffect, useState } from 'react';
import { StyledLineChart } from './LineChart.styles';
import { LineChartProps } from './LineChart.consts';
import { useLineChart } from './useLineChart';
import { useTranslation } from 'react-i18next';

export const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const { series, options, setIsHovered } = useLineChart({ data });
  const { t } = useTranslation();
  const [Chart, setChart] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const ReactApexChart = (await import('react-apexcharts')).default;
      setChart(() => ReactApexChart);
    })();
  }, []);

  if (!Chart) return <div>Loading chart...</div>; // Prevents rendering before the chart is loaded

  return (
    <StyledLineChart>
      <Chart
        options={options}
        series={series}
        height={464}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="img"
        aria-label={t('vendor.stats.total_deals_progress.line_chart.aria_label')}
      />
    </StyledLineChart>
  );
};
