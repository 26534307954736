import { create } from 'zustand';
import { PageGroupName, PageGroupsStore, PageProps } from './PageGroupsStore.consts';
import { _axios } from '@/services/apiClient';
import { getPages } from './PageGroupStore.utils';

export const usePageGroupsStore = create<PageGroupsStore>((set) => ({
  fetchGoupPages: () => {
    _axios.get(`/public/groups`).then((res) => {
      const mobilePlatformPages = getPages(res.data, PageGroupName.MOBILE_PALTFORM);
      const webPlatformPages = getPages(res.data, PageGroupName.WEB_PALTFORM);
      const compliancePages = getPages(res.data, PageGroupName.COMPLIANCE);
      const certificationsPages = getPages(res.data, PageGroupName.CERTIFICATIONS);
      set((state) => ({
        ...state,
        mobilePlatformPages,
        webPlatformPages,
        compliancePages,
        certificationsPages,
      }));
    });
  },
  fetchPageContent: async (activeCode: string, pageGroupName: string) => {
    const pageContent = await _axios
      .get(`/public/groups/${pageGroupName}/pages/${activeCode}`)
      .then((res) => {
        const { title, content, pdfLink } = res.data;
        return {
          title,
          content,
          pdfLink,
        };
      });
    set((state) => ({ ...state, pageContent, pageGroupName }));
  },
  getActiveCodeName: (pages?: PageProps[], activeKey?: string) => {
    if (pages) {
      if (activeKey) {
        return pages?.find((item) => item.order === Number(activeKey))?.code;
      } else {
        const currentPathName = location.pathname.split('/').filter(Boolean).pop();
        return pages?.find((item) => item.code === currentPathName)?.code;
      }
    }
  },
}));
