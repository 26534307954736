import React from 'react';
import { StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { StyledSeparator } from '@/shared/styles';
import { ProtectedActionsDropdownProps } from './ActionsDropdown.consts';
import { ActionsDropdown } from './ActionsDropdown';
import { useUserStore } from '@/stores/UserStore';
import { checkPermission } from '@/shared/utils/auth';

export const ProtectedActionsDropdown: React.FC<ProtectedActionsDropdownProps> = ({
  actions,
  setIsOpen,
}: ProtectedActionsDropdownProps) => {
  const permissions = useUserStore((state) => state.permissions);
  const type = useUserStore((state) => state.type);

  actions = actions.filter(
    ({ permission: requiredPermission, component }) =>
      checkPermission(type, permissions, requiredPermission) && component !== null
  );

  if (actions.length === 0) return null;

  return (
    <ActionsDropdown setIsOpen={setIsOpen}>
      {actions.reduce<React.ReactNode[]>((acc, { component }, index) => {
        const menuItem = (
          <StyledMenuItemRow key={`menu-item-${index}`}>{component}</StyledMenuItemRow>
        );
        if (index === 0) {
          return [menuItem];
        }
        return [...acc, <StyledSeparator key={`sep-${index}`} />, menuItem];
      }, [])}
    </ActionsDropdown>
  );
};
