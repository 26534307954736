import {
  StyledCard,
  StyledCol,
  StyledDashedBorderContainer,
  StyledOrangeGradTitle,
  StyledRow,
  StyledText,
  StyledSubTitle,
} from '@/shared/styles';
import styled from 'styled-components';

export const StyledProjectLayout = styled(StyledCol)`
  width: 100%;
  gap: 32px;
`;

export const StyledHeaderLayout = styled(StyledRow)`
  justify-content: space-between;
`;

export const StyledProjectDashedBorderContainer = styled(StyledDashedBorderContainer)`
  padding: 44px 16px;
  gap: 32px;
`;

export const StyledTextFrame = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 392px;
`;

export const StyledProjectYellowOrangeTitle = styled(StyledOrangeGradTitle)`
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  margin: 0px;
`;

export const StyledProjectsListCard = styled(StyledCard)`
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const StyledProjectsListWrapper = styled(StyledCol)`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 24px;
  width: 100%;
`;

export const StyledProjecListItemWrapper = styled(StyledRow)`
  align-items: center;
  padding: 0px 20px;
  border-radius: 5px 0px 0px 5px;
  align-self: stretch;
`;

export const IconFrameWrapper = styled(StyledCol)<{ $isRtl: boolean }>`
  align-items: center;
  padding: 0px 20px;
  background: ${(props) => props.theme.cardsBgColor};
  border: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  border-radius: ${(props) => (props.$isRtl ? `0px 5px 5px 0px` : ` 5px 0px 0px 5px;`)};
  width: 72px;
  height: 72px;
  justify-content: center;
  box-sizing: border-box;
`;

export const StyledProjecListItemRightSideWrapper = styled(StyledRow)<{ $isRtl: boolean }>`
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  gap: 10px;
  border: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  border-radius: ${(props) => (props.$isRtl ? `5px 0px 0px 5px` : `0px 5px 5px 0px`)};
  width: 100%;
  height: 72px;
  box-sizing: border-box;
`;

export const StyledProjecListItemLinkesWrapper = styled(StyledRow)`
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`;

export const StyledProjecListItemDetailsWrapper = styled(StyledRow)`
  align-items: center;
  padding: 0px;
  gap: 10px;
`;

export const StyledProjectPageBodyText = styled(StyledText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledProjecListItemName = styled(StyledProjectPageBodyText)`
  color: ${(props) => props.theme.textColor};
  font-weight: 400 !important;
  white-space: nowrap;
`;

export const StyledProjecListItemDomain = styled(StyledProjectPageBodyText)`
  color: ${(props) => props.theme.disabledItemColor};
  font-weight: 400 !important;
  white-space: nowrap;
`;

export const StyledFreeTextWrapper = styled(StyledRow)`
  align-items: flex-start;
`;

export const StyledFreeVersionContainer = styled(StyledDashedBorderContainer)`
  width: 49%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 24px;
  height: 266px;
`;

export const StyledUpgradeFrameContainer = styled(StyledDashedBorderContainer)`
  width: 49%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px;
  gap: 32px;
  height: 266px;
`;

export const StyledFreemiumRow = styled(StyledRow)`
  justify-content: space-between;
  margin-top: 24px;
`;

export const StyledOrangeGradSubTitle = styled(StyledSubTitle)`
  background: ${(props) => props.theme.orangeTextGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  font-weight: 600;
`;

export const StyledUpgradeModalBody = styled(StyledCol)`
  align-items: center;
  gap: 44px;
  text-align: center;
`;

export const StyledUpgradeModalTextFrame = styled(StyledCol)`
  align-items: center;
  gap: 8px;
  text-align: center;
`;

export const StyledRightSectionItems = styled(StyledRow)`
  width: 50%;
  justify-content: space-between;
  align-items: center;
`;
