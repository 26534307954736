import { Icon } from '@/shared/components/Icon';
import { getSubscriptionType } from '@/shared/consts';
import { StyledPackageTitle, StyledRow } from '@/shared/styles';
import { getPriceFormat } from '@/shared/utils';
import { TopSectionProps } from '../../Pricing.consts';
import { StyledBilling, StyledTitle, StyledTopCard } from '../Package.styles';
import { useTranslation } from 'react-i18next';

export const MainTopSection: React.FC<TopSectionProps> = ({
  name,
  price,
  imageUrl,
  pricingMethod,
}: TopSectionProps) => {
  const { t } = useTranslation();
  return (
    <StyledTopCard data-testid="packageCard">
      <StyledTitle>{name}</StyledTitle>
      <StyledRow>
        <StyledPackageTitle data-testiid="billingPrice">
          ${getPriceFormat(price as number)}
        </StyledPackageTitle>
        <StyledBilling>
          / {t('pricing.billed')} {pricingMethod && getSubscriptionType()[pricingMethod]}
        </StyledBilling>
      </StyledRow>
      <Icon $src={imageUrl} $position="absolute" $height={390} $width={300} />
    </StyledTopCard>
  );
};
