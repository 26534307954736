import { create } from 'zustand';
import { _axios } from '@/services/apiClient';
import { LeadTechStackStore } from './LeadStore.consts';

export const useLeadTechStackStore = create<LeadTechStackStore>((set, get) => ({
  techStackGroups: [],
  techStackItems: [],
  fetchLeadTechStackGroups: (): void => {
    if (get().techStackGroups.length > 0) return;

    _axios.get('leads/tech-stack/groups').then((res) => {
      set({ techStackGroups: res.data });
    });
  },
}));
