import { PAGES } from '@/shared/consts';
import { t } from 'i18next';
import { buildLangPath } from '@/shared/utils';
import {
  lpfAndroidImgUrl,
  lpfFacebookImgUrl,
  lpfFlutterImgUrl,
  lpfIOSImgUrl,
  lpfLinkedInImgUrl,
  lpfReactImgUrl,
  lpfTwitterImgUrl,
  lpfYoutubeImgUrl,
} from '@/assets/images';

export const getAboutSections = () => [
  { name: t('header.menu.about_us'), to: PAGES.BEFORE_LOGIN.ABOUT_US },
  { name: t('header.menu.our_team'), to: PAGES.BEFORE_LOGIN.OUR_TEAM },
  { name: t('header.menu.pricing'), to: PAGES.BEFORE_LOGIN.PRICING },
  { name: t('header.menu.vendor'), to: PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN },
  { name: t('header.menu.faq'), to: PAGES.BEFORE_LOGIN.FAQ },
  {
    name: t('landing_page.footer.about.enterprise_solution'),
    to: PAGES.BEFORE_LOGIN.ENTERPRISE_LANDING_PAGE,
  },
  {
    name: t('pricing.package.contact_us'),
  },
];

export const socialMedia = [
  { name: 'twitter', href: 'https://twitter.com/user1st', icon: lpfTwitterImgUrl },
  { name: 'facebook', href: 'https://www.facebook.com/user1st/', icon: lpfFacebookImgUrl },
  { name: 'linkedin', href: 'https://www.linkedin.com/company/user1st/', icon: lpfLinkedInImgUrl },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/@AccessibilityUser1st',
    icon: lpfYoutubeImgUrl,
  },
];

export const mobilePlatformsImages = [
  { src: lpfIOSImgUrl, alt: 'IOS' },
  { src: lpfAndroidImgUrl, alt: 'android' },
  { src: lpfReactImgUrl, alt: 'react' },
  { src: lpfFlutterImgUrl, alt: 'flutter' },
];

export type FooterContainerProps = {
  $size?: 'lg' | 'md' | 'sm' | 'full';
  $gap?: number;
};

export const getStatements = () => [
  {
    name: 'footer.accessibility_statement',
    path: buildLangPath(PAGES.SHARED.ACCESSIBILITY_STATEMENT),
  },
  {
    name: 'footer.privacy_policy',
    path: buildLangPath(PAGES.SHARED.PRIVACY_POLICY),
  },
  { name: 'footer.terms', path: buildLangPath(PAGES.SHARED.TERMS_AND_CONDITIONS) },
  { name: 'footer.eula', path: buildLangPath(PAGES.SHARED.EULA) },
];
