import { DialogProps, DialogType } from '@u1/fixers';
import { Component, RefId } from '../../../../../types';

export const handleSpecialCases = (props: DialogProps, el: HTMLElement, refId: RefId) => {
  const uuid = `[id='${el.id}']`;
  const scope = refId ? '' : uuid;
  const refVal = el.querySelector('[data-a11y-ref]')?.getAttribute('data-a11y-ref');
  props.selectors.trigger = `${scope} [data-a11y-dialog='trigger'][data-a11y-ref="${refVal}"]`;
  props.selectors.dialog = `.ant-picker-popup-${refVal}`;
};

export const colorPicker: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref^="color-picker"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.NON_MODAL,
    },
    selectors: {
      trigger: `[u1st-trigger-element]`,
      dialog: `.ant-picker-popup`,
    },
  },
};
