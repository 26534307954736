import { StyledCol, StyledText, StyledTitle } from '@/shared/styles';
import React from 'react';
import { StyledNewProjectLayout } from './NewProject.styles';
import { CreateNewProject } from './CreateNewProject/CreateNewProject';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { PAGES } from '@/shared/consts';
import { useTranslation } from 'react-i18next';

export const NewProject: React.FC = () => {
  const { isActive } = useSubscriptionStore();
  const { t } = useTranslation();

  return isActive === undefined ? (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  ) : (
    <StyledNewProjectLayout>
      <StyledCol $gap={10}>
        <StyledTitle $weight={600} $size={28}>
          {t('projects.new_project.title.h1')}
        </StyledTitle>
        <StyledText fontWeight={400} fontSize={'18px'}>
          {t('projects.create_new_project')}
        </StyledText>
      </StyledCol>
      <CreateNewProject />
    </StyledNewProjectLayout>
  );
};
