import { U1Logo } from '@/shared/components/U1Logo';
import { StyledCol } from '@/shared/styles';
import React from 'react';
import { signInMethods } from './SignIn.constants';
import {
  StyledLeftSection,
  StyledLogoWrapper,
  StyledSignInText,
  StyledSignInTitle,
} from './Signin.styles';
import { SigninMethodsList } from './SigninMethodsList';
import { SignInProps } from '@/shared/props';
import { useTranslation } from 'react-i18next';

export const SignInLeftSection: React.FC<SignInProps> = ({ isFromInvite }) => {
  const { t } = useTranslation();
  return (
    <StyledLeftSection className="left-section" $gap={117}>
      <StyledLogoWrapper>
        <U1Logo $size={40} />
      </StyledLogoWrapper>
      <StyledCol $gap={32} $width="100%">
        <StyledCol $gap={8}>
          <StyledSignInTitle>{t('sign_in.title')}</StyledSignInTitle>
          <StyledSignInText fontWeight={400}>
            {isFromInvite ? t('sign_in.invite') : t('sign_in.content')}
          </StyledSignInText>
        </StyledCol>
        <SigninMethodsList signInMethods={signInMethods} />
      </StyledCol>
    </StyledLeftSection>
  );
};
