import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledLeftSection = styled(StyledRow)`
  justify-content: start;
  margin-left: 20px;
  width: 100%;

  [data-component='menu'] {
    width: 100%;
  }
`;

export const StyledRightSection = styled(StyledRow)`
  justify-content: end;
  gap: 24px;
  align-items: center;
`;
