import { TabInfo } from '@/Views/BeforeLogin/TabsInfo/TabsInfo.consts';
import { ReactElement } from 'react';

export type PagePropsDto = {
  name: string;
  code?: string;
  order?: number;
  pdfLink?: string;
};

export type PageGroupDto = {
  code: string;
  title: string;
  pages: PageProps[];
};

export type PageProps = PagePropsDto & {
  to: string;
};

export type PageGroup = {
  key: string;
  label: ReactElement;
  to: string;
  children?: ReactElement;
  name?: string;
};

export enum PageGroupName {
  MOBILE_PALTFORM = 'mobile-platforms',
  WEB_PALTFORM = 'web-platforms',
  COMPLIANCE = 'compliance',
  CERTIFICATIONS = 'certifications',
}

export type PageGroupsStore = {
  compliancePages?: PageProps[];
  webPlatformPages?: PageProps[];
  mobilePlatformPages?: PageProps[];
  certificationsPages?: PageProps[];
  pageContent?: TabInfo;
  pageGroupName?: string;
  fetchGoupPages: () => void;
  fetchPageContent: (activeCode: string, pageGroupName: string) => void;
  getActiveCodeName: (pages?: PageProps[], activeKey?: string) => string | undefined;
};
