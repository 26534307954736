export const stepCardItemBGLeft: Record<string, string> = {
  PURPLE: `url("data:image/svg+xml,%3Csvg width='286' height='169' viewBox='0 0 286 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='286' height='169' rx='5' fill='url(%23paint0_linear_4839_5844)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4839_5844' x1='65' y1='-17' x2='128.094' y2='77.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237914C5' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  ORANGE: `url("data:image/svg+xml,%3Csvg width='287' height='170' viewBox='0 0 287 170' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='286' height='169' rx='5' fill='url(%23paint0_linear_4839_5864)'/%3E%3Cpath d='M287 1H6C3.23858 1 1 3.23857 1 6V170' stroke='url(%23paint1_linear_4839_5864)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4839_5864' x1='66' y1='-16' x2='129.094' y2='78.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E3655B' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_4839_5864' x1='1' y1='1' x2='64' y2='101.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23564C66'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  BLUE: `url("data:image/svg+xml,%3Csvg width='287' height='170' viewBox='0 0 287 170' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='286' height='169' rx='5' fill='url(%23paint0_linear_4839_5850)'/%3E%3Cpath d='M287 1H6C3.23858 1 1 3.23857 1 6V170' stroke='url(%23paint1_linear_4839_5850)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4839_5850' x1='66' y1='-16' x2='129.094' y2='78.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300D8FF' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_4839_5850' x1='1' y1='1' x2='64' y2='101.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23564C66'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  YELLOW: `url("data:image/svg+xml,%3Csvg width='287' height='170' viewBox='0 0 287 170' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='286' height='169' rx='5' fill='url(%23paint0_linear_4839_5857)'/%3E%3Cpath d='M287 1H6C3.23858 1 1 3.23857 1 6V170' stroke='url(%23paint1_linear_4839_5857)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4839_5857' x1='66' y1='-16' x2='129.094' y2='78.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDE74C' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_4839_5857' x1='1' y1='1' x2='64' y2='101.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23564C66'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
};

export const stepCardItemBGRight: Record<string, string> = {
  PURPLE: `url("data:image/svg+xml,%3Csvg width='286' height='169' viewBox='0 0 286 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='286' height='169' rx='5' transform='matrix(-1 0 0 1 286 0)' fill='url(%23paint0_linear_3147_30333)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3147_30333' x1='65' y1='-17' x2='128.094' y2='77.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237914C5' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  ORANGE: `url("data:image/svg+xml,%3Csvg width='286' height='169' viewBox='0 0 286 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='286' height='169' rx='5' transform='matrix(-1 0 0 1 286 0)' fill='url(%23paint0_linear_3147_30354)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3147_30354' x1='65' y1='-17' x2='128.094' y2='77.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23E3655B' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  BLUE: `url("data:image/svg+xml,%3Csvg width='286' height='169' viewBox='0 0 286 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='286' height='169' rx='5' transform='matrix(-1 0 0 1 286 0)' fill='url(%23paint0_linear_3147_30340)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3147_30340' x1='65' y1='-17' x2='128.094' y2='77.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300D8FF' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
  YELLOW: `url("data:image/svg+xml,%3Csvg width='286' height='169' viewBox='0 0 286 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='286' height='169' rx='5' transform='matrix(-1 0 0 1 286 0)' fill='url(%23paint0_linear_3147_30347)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3147_30347' x1='65' y1='-17' x2='128.094' y2='77.5893' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FDE74C' stop-opacity='0.25'/%3E%3Cstop offset='1' stop-color='%23212121' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
};

// BACKGROUND IMAGES
const bgImagesUrl = `${import.meta.env.VITE_BASE_IMAGES_URL}/backgrounds`;
export const faqBannerBgUrl = `${bgImagesUrl}/faqBanner.webp`;
export const adminSignInBgUrl = `${bgImagesUrl}/signInBg.png`;
export const purpleArcUrl = `${bgImagesUrl}/purpleArc.png`;
export const aboutUsBgUrl = `${bgImagesUrl}/aboutUsBg.svg`;
export const aboutUsDesktopMobileUrl = `${bgImagesUrl}/aboutUsDesktopMobile.svg`;
export const aboutUsVideoBoxBgUrl = `${bgImagesUrl}/aboutUsVideoBoxBg.svg`;
export const appPreviewUrl = `${bgImagesUrl}/appPreview.svg`;
export const companyOurTeamDarkBgUrl = `${bgImagesUrl}/companyOurTeamDarkBg.svg`;
export const companyOurTeamLightBgUrl = `${bgImagesUrl}/companyOurTeamLightBg.svg`;
export const contactUsBgUrl = `${bgImagesUrl}/contactUsBg.svg`;
export const dottedBgUrl = `${bgImagesUrl}/dottedBg.svg`;
export const ellipseUrl = `${bgImagesUrl}/ellipse.svg`;
export const failedPaymentImgUrl = `${bgImagesUrl}/failedPaymentImg.svg`;
export const faqDarkBgUrl = `${bgImagesUrl}/faqDarkBg.svg`;
export const faqLightBgUrl = `${bgImagesUrl}/faqLightBg.svg`;
export const loginRightBgUrl = `${bgImagesUrl}/loginRightBg.svg`;
export const loginRightPageUrl = `${bgImagesUrl}/loginRightPage.svg`;
export const packagesBgUrl = `${bgImagesUrl}/packagesBg.svg`;
export const pageNotFoundBgUrl = `${bgImagesUrl}/pageNotFoundBg.svg`;
export const u1FeatureExampleImgUrl = `${bgImagesUrl}/u1FeatureExampleImg.svg`;

// LANDING PAGES

// FOOTER IMAGES
const lpfImagesUrl = `${import.meta.env.VITE_BASE_IMAGES_URL}/landing-pages/footer`;
export const lpfAndroidImgUrl = `${lpfImagesUrl}/android.svg`;
export const lpfFacebookImgUrl = `${lpfImagesUrl}/facebook.svg`;
export const lpfFlutterImgUrl = `${lpfImagesUrl}/flutter.svg`;
export const lpfReactImgUrl = `${lpfImagesUrl}/react.svg`;
export const lpfTwitterImgUrl = `${lpfImagesUrl}/twitter.svg`;
export const lpfIOSImgUrl = `${lpfImagesUrl}/ios.webp`;
export const lpfISOImgUrl = `${lpfImagesUrl}/iso.webp`;
export const lpfLinkedInImgUrl = `${lpfImagesUrl}/linkedIn.svg`;
export const lpfYoutubeImgUrl = `${lpfImagesUrl}/youtube.svg`;

// Todo: USE THIS FILE ONLY FOR REALLY SMALL IMAGES OR FOR URLS
