import React, { useMemo } from 'react';
import { StyledPolicyText, StyledPolicyTitle } from '../Policies.styles';
import { v4 as uuidv4 } from 'uuid';
import { getListComp, processTextContent } from './PolicySection.utils';
import { PolicyContent, PolicyList, PolicySectionProps } from './PolicySection.consts';
import { StyledPolicySection } from './PolicySection.styles';

export const PolicySection: React.FC<PolicySectionProps> = ({
  title,
  titleLevel,
  content,
  $flex = 'start',
}) => {
  const memoizedContent = useMemo(() => {
    return content?.map((el) => {
      if (typeof el === 'string') {
        return <StyledPolicyText key={uuidv4()}>{processTextContent(el)}</StyledPolicyText>;
      } else if ((el as PolicyList).list) {
        const listEl = el as PolicyList;
        const ListComp = getListComp(listEl);

        const listItems = listEl.list.map((item) => (
          <li key={uuidv4()}>
            {typeof item === 'string' ? (
              processTextContent(item)
            ) : (
              <PolicySection key={uuidv4()} content={[item]} />
            )}
          </li>
        ));

        return <ListComp key={uuidv4()}>{listItems}</ListComp>;
      } else if ((el as PolicyContent).sections) {
        const policyEl = el as PolicyContent;
        return (
          <PolicySection
            key={uuidv4()}
            titleLevel={4}
            title={policyEl.title}
            content={policyEl.sections}
          />
        );
      }
    });
  }, [content]);

  return (
    <StyledPolicySection>
      {title && (
        <StyledPolicyTitle $flex={$flex} level={titleLevel || 1}>
          {title}
        </StyledPolicyTitle>
      )}
      {memoizedContent}
    </StyledPolicySection>
  );
};
