import { PAGES, UserRole } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { ProjectsPage } from './ProjectsPage/ProjectsPage';
import { useUserStore } from '@/stores/UserStore';

export const Projects: React.FC = () => {
  const userRole = useUserStore((state) => state.role);
  const { subscriptionStatus } = useSubscriptionStore();

  return subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION ||
    subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED ||
    userRole === UserRole.ENTERPRISE ? (
    <ProjectsPage />
  ) : (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
