import i18n from '@/services/localization';
import styled from 'styled-components';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const StyledCaret = styled.img.attrs(() => ({
  dir: i18n.dir(i18n.language),
}))`
  margin-left: 4px;

  &[dir='rtl'] {
    margin-right: 4px;
  }
  ${() => !useThemeStore.getState().isDarkMode && `filter: invert(100%);`}
`;
