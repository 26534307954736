import { LeadDetails } from '@/stores/LeadStore/LeadStore.consts';
import { ActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { AdminPermission } from '../../../Admin.consts';
import { StyledTableActionLink } from '@/shared/styles';
import { t } from 'i18next';
import { LeadModal } from '@/shared/components/Modals/LeadModals/LeadModal';
import { LeadForm } from '@/shared/components/Forms/LeadForms/LeadForm';
import { DeleteLeadModal } from '@/shared/components/Modals/LeadModals/DeleteLeadModal';

export type LeadActionsDropdownProps = ActionsProps & {
  lead: LeadDetails;
};

export type LeadActionsProps = {
  lead: LeadDetails;
  getLeads: () => void;
};

export const getLeadActions = ({ lead, getLeads }: LeadActionsProps) => [
  {
    permission: AdminPermission.READ_LEAD,
    component: lead.linkedinUrl ? (
      <StyledTableActionLink target="_blank" className="table-link" href={lead.linkedinUrl}>
        {t('links.go_to_linkedin')}
      </StyledTableActionLink>
    ) : null,
  },
  {
    permission: AdminPermission.UPDATE_LEAD,
    component: (
      <LeadModal
        action="edit"
        btnText={t('admin.actions.edit')}
        form={<LeadForm selectedLead={lead} />}
      />
    ),
  },
  {
    permission: AdminPermission.DELETE_LEAD,
    component: (
      <DeleteLeadModal
        id={lead.id}
        userName={`${lead.firstName} ${lead.lastName}`}
        getLeads={getLeads}
      />
    ),
  },
];
