import { create } from 'zustand';
import { PackageType, VersionsStore } from './VersionsStore.consts';
import { _axiosNoHeader } from '@/services/apiClient';

export const useVersionsStore = create<VersionsStore>((set) => ({
  changelog: '',
  fetchChangelog: (type: PackageType) => {
    _axiosNoHeader
      .get(`${import.meta.env.VITE_BASE_GCP_BUCKET}/changelog/${type}/CHANGELOG.md`)
      .then((res) => {
        const changelog = res.data.replace(/^#/gm, '##'); // replace h1 titles with h2
        set((state) => ({ ...state, changelog }));
      });
  },
}));
