import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import React, { useEffect } from 'react';
import { SearchTree } from './SearchTree/SearchTree';
import { DocsNotFound } from '@/shared/pages/NotFound/DocsNotFound';
import { useSubscription } from '@/hooks/useSubscription';
import { useParams } from 'react-router-dom';
import { DocumentationPageLoading } from './DocumentationPageLoading';
import { useProjects } from '@/hooks/useProjects';
import { U1Tree } from '@u1/react-a11y-hooks';
import { DocsContent } from './DocsContent/DocsContent';
import { useUserStore } from '@/stores/UserStore';
import { USER_TYPE } from '@/shared/consts';
import { StyledDocsTabs } from './DocumentationPage.styles';

export const DocumentationPage: React.FC = () => {
  const docsStore = useDocsStore();
  const userType = useUserStore((state) => state.type);
  const { projectIdParam } = useParams();
  const { getDocsById, adminDocsLoading, getProjectsDocs, projectsDocsLoading } = useProjects();
  const { getSubscription, subscriptionLoading } = useSubscription();

  useEffect(() => {
    if (projectIdParam) {
      docsStore.setProjectId(projectIdParam);
    }
  }, [projectIdParam]);

  useEffect(() => {
    if (docsStore.projectId) {
      if (userType === USER_TYPE.ADMIN) {
        getDocsById({ url: `frameworks/${docsStore.projectId}` });
      } else {
        getProjectsDocs();
      }
    }
  }, [docsStore.projectId, projectIdParam]);

  useEffect(() => {
    if (userType !== USER_TYPE.ADMIN) getSubscription();
  }, []);

  return subscriptionLoading || projectsDocsLoading || adminDocsLoading ? (
    <DocumentationPageLoading />
  ) : docsStore.docsObj.length ? (
    <U1Tree>
      <StyledDocsTabs id="docs-tabs">
        <SearchTree />
        <DocsContent />
      </StyledDocsTabs>
    </U1Tree>
  ) : (
    <DocsNotFound />
  );
};
