import { StyledAdjustableRow } from '@/shared/styles';
import styled, { css } from 'styled-components';
import { PreviewBlockProps } from '../ColorContrastChecker.consts';
import { isDesktop, isMobileOnly } from 'react-device-detect';

export const StyledParametersBox = styled(StyledAdjustableRow)`
  ${(props) => isDesktop && `border: 1px solid ${props.theme.secondBorder};`}
  justify-content: space-between;
  border-radius: 5px;
  padding: ${isMobileOnly ? '0' : '24'}px;
  gap: 24px;
  align-items: ${isMobileOnly ? 'center' : 'end'};
`;

export const StyledColorblock = styled.div`
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: ${(props) => props.theme.inputColor};
  border: 1px solid ${(props) => props.theme.inputBorder};
  border-radius: 5px;
  padding: 5px;
`;

export const StyledSelectedColor = styled.div<PreviewBlockProps>`
  background-color: ${(props) => props.$background} !important;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
`;

export const ColorPickerCss = css`
  .ant-popover-inner {
    background-color: ${(props) => props.theme.cardsColor} !important;
    border: 1px solid ${(props) => props.theme.detailsBorder} !important;
    border-radius: 8px;
  }

  .ant-popover .ant-popover-arrow:before {
    background: ${(props) => props.theme.cardsColor} !important;
  }

  .ant-color-picker-input .ant-input-outlined {
    background: ${(props) => props.theme.inputColor} !important;
    border: 1px solid ${(props) => props.theme.inputBorder} !important;
  }

  .ant-color-picker
    .ant-color-picker-inner
    .ant-color-picker-input-container
    .ant-color-picker-input
    .ant-color-picker-hex-input.ant-input-affix-wrapper
    .ant-input-prefix,
  .ant-input-affix-wrapper > input.ant-input,
  .ant-input-number .ant-input-number-input {
    color: ${(props) => props.theme.textColor} !important;
  }

  .ant-color-picker .ant-color-picker-inner .ant-color-picker-slider-container {
    flex-direction: row-reverse;
  }

  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props.theme.inputBorder} !important;
  }

  .ant-select-selection-search-input {
    visibility: hidden;
  }
`;
