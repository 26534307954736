import { U1Button } from '@/shared/components/Button/U1Button';
import { PageNotFoundProps } from '@/shared/props';
import { NotFound } from './NotFound';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { Helmet } from 'react-helmet-async';

export const AdminNotFound: React.FC<PageNotFoundProps> = ({ $homePath }: PageNotFoundProps) => {
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="robots" content="'noindex, nofollow'" />
      </Helmet>
      <NotFound
        text={t('not_found.main.text')}
        backButton={
          <U1Button
            dataTestId="homepageButton"
            text={t('not_found.main.back_button')}
            $height="50px"
            onClick={() => navigate($homePath)}
          />
        }
      />
    </>
  );
};
