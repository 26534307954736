import React, { useEffect, useState } from 'react';
import { StyledMenuItem } from '@/shared/styles/dropdownMenu.styles';
import { StyledContent, StyledSecondaryText } from '@/shared/styles';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useFormMessages } from '@/hooks/useFormMessages';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { DeleteLeadProps } from './LeadModals.consts';

export const DeleteLeadModal: React.FC<DeleteLeadProps> = ({
  id,
  userName,
  getLeads,
}: DeleteLeadProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ response }, deleteLead] = useAxios(
    { url: `/leads/${id}`, method: 'delete' },
    { manual: true }
  );

  useEffect(() => {
    if (response) {
      if (response?.status === RES_STATUS.NO_CONTENT) {
        setIsOpen(false);
        getLeads();
      } else {
        setError(response?.data.message);
      }
    }
  }, [response]);

  const showModal = () => {
    setIsOpen(true);
  };

  const onConfirm = () => {
    deleteLead();
  };

  const onCancel = () => {
    setIsOpen(false);
    clearAllMessages();
  };

  return (
    <ConfirmModal
      title={`${t('admin.actions.delete')} ${t('admin.table.lead')}`}
      trigger={
        <StyledMenuItem onClick={showModal} data-a11y-ref="confirm" data-a11y-dialog="trigger">
          {t('admin.actions.delete')}
        </StyledMenuItem>
      }
      confirmBtnText={t('admin.delete.yes_delete')}
      cancelBtnText={t('cancel')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      error={serverError}
      isOpen={isOpen}
    >
      <StyledSecondaryText fontWeight={400} fontSize={'16px'}>
        {t('admin.delete_lead.content')}
      </StyledSecondaryText>
      <StyledContent fontWeight={400} fontSize={'16px'}>
        {userName}
      </StyledContent>
    </ConfirmModal>
  );
};
