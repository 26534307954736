import { LeadsTable } from './LeadsTable/LeadsTable';
import { useEffect } from 'react';
import { Panel } from '../../SearchPanel/Panel';
import { PanelType } from '../../SearchPanel/Panel.consts';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '@/hooks/Auth/usePermissions';
import { AdminPermission } from '../Admin.consts';
import { useLeadsStore } from '@/stores/LeadStore/LeadStore';
import { LeadForm } from '@/shared/components/Forms/LeadForms/LeadForm';
import { LeadModal } from '@/shared/components/Modals/LeadModals/LeadModal';

export const LeadsSection = () => {
  const {
    searchValue,
    currentPage,
    isLoading,
    currentLimit,
    pagination,
    getLeads,
    setSearchValue,
    setCurrentPage,
  } = useLeadsStore();

  const { t } = useTranslation();
  const [canCreateLead, canExportLead] = useHasPermissions([
    AdminPermission.CREATE_LEAD,
    AdminPermission.EXPORT_LEAD,
  ]);

  useEffect(() => {
    getLeads();
  }, [currentPage, getLeads, searchValue]);

  const searchLead = (value: string) => {
    setSearchValue(value);
  };

  const changePage = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  return (
    <Panel
      isLoading={isLoading}
      panelType={PanelType.LEADS}
      searchBy="email"
      title={t('admin.leads.title')}
      name={'email'}
      pageSize={currentLimit}
      totalResults={pagination?.total || 0}
      canDownloadTable={canExportLead}
      createButton={
        <LeadModal
          action="add"
          btnText={t('admin.leads.add_lead')}
          btnDisabled={!canCreateLead}
          form={<LeadForm />}
        />
      }
      table={<LeadsTable />}
      changePage={changePage}
      getSearch={searchLead}
    />
  );
};
