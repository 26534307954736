import { IconButton } from '@/shared/components/Button/IconButton';
import { StyledEditIcons } from './ClipboardSyntaxHighlighter.styles';
import { cancelIcon, saveIcon } from '@/assets/images/icons';
import { CodeEditorModal } from '@/shared/components/Modals/EditDocsModal/EditSections/CodeEditorModal';
import { useConfigDataStore, useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { Dispatch, SetStateAction } from 'react';
import { EditIconsProps } from '../../Client/Projects/Projects.consts';

export const EditIcons: React.FC<EditIconsProps> = ({
  index,
  editableContent,
  objkeys,
  isVisible,
  isEditMode,
  originalContent,
  setContent,
  setIsEditMode,
}: EditIconsProps) => {
  const updateDocs = useDocsStore((state) => state.updateDocsObj);
  const updateConfigObj = useConfigDataStore((state) => state.updateConfigObj);

  const cancelEdit = () => {
    setIsEditMode(false);
  };

  const saveEdit = () => {
    if (objkeys) {
      objkeys[0] === 'code'
        ? updateConfigObj(objkeys, editableContent, index)
        : updateDocs(objkeys, editableContent);
      setIsEditMode(false);
    }
  };

  return (
    <StyledEditIcons isVisible={isVisible}>
      {isEditMode ? (
        <>
          <IconButton $alt="cancel edit" onClick={cancelEdit} $icon={cancelIcon} />
          <IconButton $alt="save content" onClick={saveEdit} $icon={saveIcon} />
        </>
      ) : (
        <CodeEditorModal
          docs={originalContent as string}
          setDocs={setContent as Dispatch<SetStateAction<string>>}
          setIsEditMode={setIsEditMode}
        />
      )}
    </StyledEditIcons>
  );
};
