export type VersionsStore = {
  changelog: string;
  fetchChangelog: (type: PackageType) => void;
};

export enum PackageType {
  FIXERS = 'fixers',
  A11Y = 'a11y',
  VALIDATORS = 'validators',
  TOOLBAR = 'toolbar',
}
