import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Icon } from '@/shared/components/Icon';
import { User1stDetails } from '../User1stDetails';
import { StyledFooterList, StyledFooterCol, StyledFooterTitle } from '../Footer.styles';
import { LinksSection } from './Sections/LinksSection';
import { About } from './Sections/About';
import { MobileIcons } from './Sections/MobileIcons';
import { Certifications } from './Sections/Certifications';
import { lpfISOImgUrl } from '@/assets/images';
import { usePageGroupsStore } from '@/stores/PageGroupsStore/PageGroupsStore';
import { useEffect } from 'react';
import { PageGroupName } from '@/stores/PageGroupsStore/PageGroupsStore.consts';

export const FooterSections: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isWideDesktop } = useDeviceDetect();
  const { compliancePages, webPlatformPages, mobilePlatformPages, fetchGoupPages } =
    usePageGroupsStore();

  useEffect(() => {
    fetchGoupPages();
  }, []);

  return (
    <StyledFooterList $direction={isMobile ? 'column' : 'row'}>
      {isWideDesktop && (
        <StyledFooterCol>
          <User1stDetails />
        </StyledFooterCol>
      )}
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.complience.title')}
        </StyledFooterTitle>
        <LinksSection links={compliancePages} pageGroupName={PageGroupName.COMPLIANCE} />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.certifications.title')}
        </StyledFooterTitle>
        <Certifications />
        <Icon
          $src={lpfISOImgUrl}
          $maxWidth={190}
          $alt="iso 27018:2019, iso 27001, iso 2707:2015, W3C"
        />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.web_platforms.title')}
        </StyledFooterTitle>
        <LinksSection links={webPlatformPages} pageGroupName={PageGroupName.WEB_PALTFORM} />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.mobile_platforms.title')}
        </StyledFooterTitle>
        <LinksSection links={mobilePlatformPages} pageGroupName={PageGroupName.MOBILE_PALTFORM} />
        <MobileIcons />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.about.title')}
        </StyledFooterTitle>
        <About />
      </StyledFooterCol>
    </StyledFooterList>
  );
};
