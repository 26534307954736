import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledDealRow = styled(StyledRow)`
  padding: 40px;
  width: 100%;
  align-items: start;
  gap: 32px;
  box-sizing: border-box;
`;
