import styled from 'styled-components';

export const StyledLineChart = styled.div`
  width: 100%;

  .apexcharts-yaxis-label:nth-child(odd) {
    visibility: hidden;
  }

  .apexcharts-tooltip {
    box-shadow: none;
    background: ${(props) => props.theme.marginsColor} !important;
    border: 1px solid ${(props) => props.theme.marginsBorder} !important;
  }

  .apexcharts-tooltip-text-y-value {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
    color: ${(props) => props.theme.textColor} !important;
  }
`;
