import { gradientLinkStyle } from '@/shared/components/Link/Links.styles';
import { StyledCard } from '@/shared/styles';
import styled, { css } from 'styled-components';

export const VersionTabsStyle = css`
  [data-component='tabs'] {
    width: 100%;
  }

  .ant-tabs-tabpane {
    padding: 0px !important;
  }

  .ant-tabs-nav-list {
    padding: 0px !important;
    background: ${(props) => props.theme.backgroundColor} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background: ${(props) => props.theme.cardsColor} !important;
  }
`;

export const StyledTabPanel = styled(StyledCard)`
  padding: 40px;
  color: ${(props) => props.theme.textColor};

  a {
    ${gradientLinkStyle}
  }

  code {
    font-size: 14px !important;
  }
`;
