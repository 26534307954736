import { Switch } from 'antd';
import styled from 'styled-components';
import { darkModeIcon } from '@/assets/images/icons';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const StyledThemeSwitcher = styled(Switch)`
  background: ${(props) =>
    useThemeStore.getState().isDarkMode
      ? props.theme.buttonsBG
      : props.theme.sameColorBorder} !important;
  min-width: 40px;
  height: 20px;

  &.ant-switch-checked .ant-switch-handle::before {
    background: url(\"${darkModeIcon}\");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .ant-switch-handle {
    width: 16px;
    height: 16px;
    top: 2px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 16px - 2px);
  }
`;
