import React from 'react';
import { StyledTableErr, StyledTableWrapper } from './U1Table.styles';
import { TableProps } from '@/shared/props';
import { U1Grid } from '@u1/react-a11y-hooks';
import { ConfigProvider } from 'antd';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const U1Table: React.FC<TableProps> = ({
  dataSource,
  columns,
  dataTestId,
  noDataMsg,
}: TableProps) => {
  const getLangDir = useLangStore((state) => state.getLangDir);

  const props = noDataMsg
    ? {
        locale: {
          emptyText: <StyledTableErr>{noDataMsg}</StyledTableErr>,
        },
      }
    : {};

  return (
    <ConfigProvider direction={getLangDir()}>
      <U1Grid>
        <StyledTableWrapper
          data-testid={dataTestId}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="ant-grid-table"
          {...props}
        />
      </U1Grid>
    </ConfigProvider>
  );
};
