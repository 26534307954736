import { Route, Routes, useLocation } from 'react-router-dom';
import { SignIn } from './SignIn/SSO/SignIn';
import { MainPage } from './MainPage/MainPage';
import { StyledBgLayout } from './BeforeLogin.styles';
import { PAGES } from '@/shared/consts';
import { Header } from './Header/Header';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { noHeaderPages, pagesWithNoFooter } from './BeforeLogin.consts';
import { TermsAndConditions } from '@/shared/pages/Policies/TermsAndConditions';
import { PrivacyPolicy } from '@/shared/pages/Policies/PrivacyPolicy';
import { AccessibilityStatement } from '@/shared/pages/Policies/AccessibilityStatement';
import { AdminSignIn } from './SignIn/AdminSignIn/AdminSignIn';
import { VendorSignIn } from './SignIn/VendorSIgnIn/VendorSignIn';
import { CreatePassword } from './SignIn/CreatePassword/CreatePassword';
import { SecurityAlert } from './Errors/SecurityAlert/SecurityAlert';
import { BeforeLogin404 } from './Errors/BeforeLogin404/BeforeLogin404';
import { Loading } from '@/shared/components/Loading/Loading';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { MainLandingPage } from './LandingPages/MainLandingPage/MainLandingPage';
import { EAALandingPage } from './LandingPages/EAALandingPage/EAALandingPage';
import { EnterpriseLandingPage } from './LandingPages/EnterpriseLandingPage/EnterpriseLandingPage';
import { PlatformType } from './TabsInfo/Platforms/Platform.consts';
import { Footer } from './Footer/Footer';
import { RouterWrapper } from './RouterWrapper';
import { Eula } from '@/shared/pages/Policies/Eula';

export const BeforeLogin: React.FC = () => {
  const location = useLocation().pathname.split('/').slice(-1)[0];
  const [hasHeader, setHasHeader] = useState(true);
  const [hasFooter, setHasFooter] = useState(false);
  const topPageRef = useRef(null);

  useEffect(() => {
    setHasHeader(!noHeaderPages.includes(location.toLocaleLowerCase()));
    setHasFooter(!pagesWithNoFooter.includes(location.toLocaleLowerCase()));
  }, [location]);

  const AboutPageLazy = lazy(() =>
    import('./TheCompany/AboutUs/AboutUs').then((module) => ({ default: module.AboutUs }))
  );
  const OurTeamLazy = lazy(() =>
    import('./TheCompany/OurTeam/OurTeam').then((module) => ({ default: module.OurTeam }))
  );
  const FAQLazy = lazy(() =>
    import('./ThePlatform/Faq/Faq').then((module) => ({ default: module.FAQ }))
  );
  const WhitepapersLazy = lazy(() =>
    import('./ThePlatform/Whitepapers/Whitepapers').then((module) => ({
      default: module.Whitepapers,
    }))
  );
  const PricingLazy = lazy(() =>
    import('./Pricing/PackagesPricing').then((module) => ({ default: module.PackagesPricing }))
  );
  const ComplianceLazy = lazy(() =>
    import('./TabsInfo/Compliance/Compliance').then((module) => ({ default: module.Compliance }))
  );
  const PlatformsLazy = lazy(() =>
    import('./TabsInfo/Platforms/Platform').then((module) => ({
      default: module.Platform,
    }))
  );
  const ColorContrastLazy = lazy(() =>
    import('./FreeTools/ColorContrastChecker/ColorContrastChecker').then((module) => ({
      default: module.ColorContrastChecker,
    }))
  );
  const FontCheckerLazy = lazy(() =>
    import('./FreeTools/FontChecker/FontChecker').then((module) => ({
      default: module.FontChecker,
    }))
  );
  const { availableLangs } = useLangStore();

  return (
    <StyledBgLayout ref={topPageRef}>
      {hasHeader && <Header />}
      <RouterWrapper>
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* lang routes */}
            {availableLangs.map((lang) => (
              <Route key={lang.code} path={`/${lang.code}`}>
                <Route path={PAGES.SHARED.DEFAULT.replace('/', '')} element={<MainPage />} />
                <Route
                  path={PAGES.BEFORE_LOGIN.MAIN_PAGE.replace('/', '')}
                  element={<MainPage />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.ADMIN_SIGN_IN.replace('/', '')}
                  element={<AdminSignIn />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN.replace('/', '')}
                  element={<VendorSignIn />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.PASSWORD.replace('/', '')}
                  element={<CreatePassword />}
                />
                <Route path={PAGES.BEFORE_LOGIN.SIGN_IN.replace('/', '')} element={<SignIn />} />
                <Route
                  path={PAGES.BEFORE_LOGIN.SIGN_IN_INVITE.replace('/', '')}
                  element={<SignIn isFromInvite={true} />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.ABOUT_US.replace('/', '')}
                  element={<AboutPageLazy />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.WHITEPAPERS.replace('/', '')}
                  element={<WhitepapersLazy />}
                />
                <Route path={PAGES.BEFORE_LOGIN.FAQ.replace('/', '')} element={<FAQLazy />} />
                <Route
                  path={PAGES.BEFORE_LOGIN.OUR_TEAM.replace('/', '')}
                  element={<OurTeamLazy />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.PRICING.replace('/', '')}
                  element={<PricingLazy />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.COMPLIANCE.replace('/', '')}`}
                  element={<ComplianceLazy />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.COMPLIANCE.replace('/', '')}/:name`}
                  element={<ComplianceLazy />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.WEB_PLATFORMS.replace('/', '')}`}
                  element={<PlatformsLazy platformType={PlatformType.WEB} />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.WEB_PLATFORMS.replace('/', '')}/:name`}
                  element={<PlatformsLazy platformType={PlatformType.WEB} />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.MOBILE_PLATFORMS.replace('/', '')}`}
                  element={<PlatformsLazy platformType={PlatformType.MOBILE} />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.MOBILE_PLATFORMS.replace('/', '')}/:name`}
                  element={<PlatformsLazy platformType={PlatformType.MOBILE} />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.COLOR_CONTRAST_CHECKER.replace('/', '')}`}
                  element={<ColorContrastLazy />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.COLOR_RATIO_CHECKER.replace('/', '')}`}
                  element={<ColorContrastLazy />}
                />
                <Route
                  path={`${PAGES.BEFORE_LOGIN.FONT_CHECKER.replace('/', '')}`}
                  element={<FontCheckerLazy />}
                />
                <Route
                  path={PAGES.SHARED.TERMS_AND_CONDITIONS.replace('/', '')}
                  element={<TermsAndConditions />}
                />
                <Route
                  path={PAGES.SHARED.PRIVACY_POLICY.replace('/', '')}
                  element={<PrivacyPolicy />}
                />
                <Route
                  path={PAGES.SHARED.ACCESSIBILITY_STATEMENT.replace('/', '')}
                  element={<AccessibilityStatement />}
                />
                <Route path={PAGES.SHARED.EULA.replace('/', '')} element={<Eula />} />
                <Route
                  path={PAGES.BEFORE_LOGIN.MAIN_LANDING_PAGE.replace('/', '')}
                  element={<MainLandingPage />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.EAA_LANDING_PAGE.replace('/', '')}
                  element={<EAALandingPage />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.ENTERPRISE_LANDING_PAGE.replace('/', '')}
                  element={<EnterpriseLandingPage />}
                />
                <Route
                  path={PAGES.SHARED.NOT_FOUND.replace('/', '')}
                  element={<BeforeLogin404 />}
                />
                <Route
                  path={PAGES.BEFORE_LOGIN.SECURITY_ALERT.replace('/', '')}
                  element={<SecurityAlert />}
                />
              </Route>
            ))}

            {/* default routes */}
            <Route path={PAGES.SHARED.DEFAULT} element={<MainPage />} />
            <Route path={PAGES.BEFORE_LOGIN.MAIN_PAGE} element={<MainPage />} />
            <Route path={PAGES.BEFORE_LOGIN.ADMIN_SIGN_IN} element={<AdminSignIn />} />
            <Route path={PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN} element={<VendorSignIn />} />
            <Route path={PAGES.BEFORE_LOGIN.PASSWORD} element={<CreatePassword />} />
            <Route path={PAGES.BEFORE_LOGIN.SIGN_IN} element={<SignIn />} />
            <Route
              path={PAGES.BEFORE_LOGIN.SIGN_IN_INVITE}
              element={<SignIn isFromInvite={true} />}
            />

            <Route path={PAGES.BEFORE_LOGIN.ABOUT_US} element={<AboutPageLazy />} />
            <Route path={PAGES.BEFORE_LOGIN.FAQ} element={<FAQLazy />} />
            <Route path={PAGES.BEFORE_LOGIN.WHITEPAPERS} element={<WhitepapersLazy />} />
            <Route path={PAGES.BEFORE_LOGIN.OUR_TEAM} element={<OurTeamLazy />} />
            <Route path={PAGES.BEFORE_LOGIN.PRICING} element={<PricingLazy />} />
            <Route path={`${PAGES.BEFORE_LOGIN.COMPLIANCE}`} element={<ComplianceLazy />} />
            <Route path={`${PAGES.BEFORE_LOGIN.COMPLIANCE}/:name`} element={<ComplianceLazy />} />
            <Route
              path={`${PAGES.BEFORE_LOGIN.WEB_PLATFORMS}`}
              element={<PlatformsLazy platformType={PlatformType.WEB} />}
            />
            <Route
              path={`${PAGES.BEFORE_LOGIN.WEB_PLATFORMS}/:name`}
              element={<PlatformsLazy platformType={PlatformType.WEB} />}
            />
            <Route
              path={`${PAGES.BEFORE_LOGIN.MOBILE_PLATFORMS}`}
              element={<PlatformsLazy platformType={PlatformType.MOBILE} />}
            />
            <Route
              path={`${PAGES.BEFORE_LOGIN.MOBILE_PLATFORMS}/:name`}
              element={<PlatformsLazy platformType={PlatformType.MOBILE} />}
            />
            <Route
              path={`${PAGES.BEFORE_LOGIN.COLOR_CONTRAST_CHECKER}`}
              element={<ColorContrastLazy />}
            />
            <Route
              path={`${PAGES.BEFORE_LOGIN.COLOR_RATIO_CHECKER}`}
              element={<ColorContrastLazy />}
            />
            <Route path={`${PAGES.BEFORE_LOGIN.FONT_CHECKER}`} element={<FontCheckerLazy />} />
            <Route path={PAGES.SHARED.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
            <Route path={PAGES.SHARED.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route
              path={PAGES.SHARED.ACCESSIBILITY_STATEMENT}
              element={<AccessibilityStatement />}
            />
            <Route path={PAGES.SHARED.EULA} element={<Eula />} />
            <Route path={PAGES.BEFORE_LOGIN.MAIN_LANDING_PAGE} element={<MainLandingPage />} />
            <Route path={PAGES.BEFORE_LOGIN.EAA_LANDING_PAGE} element={<EAALandingPage />} />
            <Route
              path={PAGES.BEFORE_LOGIN.ENTERPRISE_LANDING_PAGE}
              element={<EnterpriseLandingPage />}
            />
            <Route path={PAGES.SHARED.NOT_FOUND} element={<BeforeLogin404 />} />
            <Route path={PAGES.BEFORE_LOGIN.SECURITY_ALERT} element={<SecurityAlert />} />
          </Routes>
        </Suspense>
      </RouterWrapper>
      {hasFooter && <Footer />}
    </StyledBgLayout>
  );
};
