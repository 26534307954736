import { Icon } from '@/shared/components/Icon';
import React, { useEffect } from 'react';

import { U1Button } from '@u1/react-a11y-hooks';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useNewProjectStore } from '@/stores/ProjectStore/ProjectStore';
import { useTranslation } from 'react-i18next';
import {
  StyledFrameworkCard,
  StyledFrameworkCardInner,
  StyledFrameworkCardItem,
  StyledFrameworkStateText,
} from './CreateNewProject.styles';

export const FrameworkCardItems: React.FC = () => {
  const subscription = useSubscriptionStore();
  const {
    id: subscriptionId,
    frameworks: subscriptionFrameworks,
    supportedFrameworks,
  } = subscription;
  const { projectFramework, setProjectFramework } = useNewProjectStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (subscriptionId) setProjectFramework(subscriptionFrameworks[0]);
  }, [subscription]);

  const getIcon = (currentFw: string) => {
    return supportedFrameworks?.find((fw) => fw.name === currentFw)?.icon;
  };

  return subscriptionFrameworks.map((fw) => (
    <StyledFrameworkCardItem key={fw}>
      <U1Button>
        <StyledFrameworkCard
          data-testid="frameworkCard"
          onClick={() => setProjectFramework(fw)}
          $width="119px"
          $height="116px"
          $isCurrent={projectFramework === fw}
          data-u1-button="button"
          aria-label={fw}
        >
          <StyledFrameworkCardInner $isCurrent={projectFramework === fw}>
            <Icon $size={38} $src={getIcon(fw)} />
          </StyledFrameworkCardInner>
        </StyledFrameworkCard>
      </U1Button>

      <StyledFrameworkStateText $isCurrent={projectFramework === fw}>
        {projectFramework === fw
          ? t('projects.new_project.project_details.choose_framework.current')
          : ''}
      </StyledFrameworkStateText>
    </StyledFrameworkCardItem>
  ));
};
