import { buildLangPath, isValidLanguage } from '@/shared/utils';
import { useNavigate } from 'react-router-dom';

export const useU1Navigation = () => {
  const navigateTo = useNavigate();

  const navigate = (path: string, language?: string, replace = false) => {
    navigateTo(buildLangPath(path, language), { replace });
  };

  const removeLanguageFromPath = (pathname: string): string => {
    const removeDoubleSlashesRegExp = /^\/|\/$/g;
    const pathLangArray: string[] = pathname.replace(removeDoubleSlashesRegExp, '').split('/');
    if (isValidLanguage(pathLangArray[0])) {
      pathLangArray.shift();
    }

    return pathLangArray.join('/');
  };

  return { navigate, removeLanguageFromPath };
};
