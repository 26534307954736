import { AdminPermission } from '@/Views/AfterLogin/Main/Admin/Admin.consts';
import { ProtectedRouteOption } from '../components/Auth/ProtectedRoute.consts';
import { PAGES, USER_TYPE } from '../consts';

export const checkPermission = (
  userType: string,
  userPermissions?: string[],
  requiredPermission?: string
): boolean =>
  !!userPermissions &&
  !!requiredPermission &&
  ((userType === USER_TYPE.ADMIN && userPermissions.includes(AdminPermission.MANAGE_ALL)) ||
    userPermissions.includes(requiredPermission) ||
    userPermissions.includes(`manage_${requiredPermission.split('_')[1]}s`) ||
    userPermissions.includes(`manage_${requiredPermission.split('_')[1]}`));

export const detectDefaultRoute = (
  userType: string,
  userPermissions?: string[],
  protectedRoutes: ProtectedRouteOption[] = []
): ProtectedRouteOption | undefined => {
  for (const route of protectedRoutes) {
    if (checkPermission(userType, userPermissions, route.permission)) {
      return {
        path: PAGES.SHARED.DEFAULT,
        element: route.element,
        permission: route.permission,
      };
    }
  }
};
