import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledModal } from '@/shared/components/Modals/Modals.styles';
import { PAGES } from '@/shared/consts';
import { StyledSecondaryText } from '@/shared/styles';
import React from 'react';
import { UpgradeModalProps } from '../../ProjectsPage.consts';
import {
  StyledOrangeGradSubTitle,
  StyledUpgradeModalBody,
  StyledUpgradeModalTextFrame,
} from '../../ProjectsPage.styles';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const UpgradeModal: React.FC<UpgradeModalProps> = ({ handleCancel, isOpen }) => {
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <StyledModal width={550} open={isOpen} onCancel={handleCancel} footer={[]}>
      <StyledUpgradeModalBody>
        <StyledUpgradeModalTextFrame>
          <StyledOrangeGradSubTitle>{t('projects.upgrade_modal.title')}</StyledOrangeGradSubTitle>
          <StyledSecondaryText fontSize="16" fontWeight={400}>
            {t('projects.upgrade_modal.content')}
          </StyledSecondaryText>
        </StyledUpgradeModalTextFrame>
        <U1Button
          onClick={() => {
            navigate(PAGES.AFTER_LOGIN.PRICING);
          }}
          text={t('projects.freemium.check_packages')}
          $height="40px"
        />
      </StyledUpgradeModalBody>
    </StyledModal>
  );
};
