import { ColumnsType } from 'antd/es/table';
import { LeadActions } from './LeadActions/LeadActions';
import { t } from 'i18next';
import { LeadDetails } from '@/stores/LeadStore/LeadStore.consts';
import { getUTCTableDateFormat } from '@/shared/utils';
import { StyledLink } from '@/shared/styles';
import { AdminPermission } from '../../Admin.consts';

export interface LeadsDataType {
  key: string;
  fullName: string;
  email: string;
  companyName: string;
  phone?: string;
  country?: string;
  createdAt?: string;
  techStack?: string[];
  message?: string;
  actions: string;
}

export const getColumnsPermissions = (): Record<string, string> => ({
  creatorEmail: AdminPermission.READ_ADMIN,
});

export const getColumns = (): ColumnsType<LeadsDataType> => [
  {
    title: `${t('admin.table.date')} (UTC)`,
    key: 'createdAt',
    className: 'leads-table-cell',
    render: (lead: LeadDetails) => getUTCTableDateFormat(lead.createdAt),
  },
  {
    title: t('admin.table.created_by'),
    dataIndex: 'creatorEmail',
    className: 'leads-table-cell',
    key: 'creatorEmail',
  },
  {
    title: t('admin.table.name'),
    className: 'leads-table-cell',
    key: 'fullName',
    render: (lead: LeadDetails) => (
      <StyledLink href={lead.linkedinUrl} target="_blank" style={{ textDecoration: 'underline' }}>
        {lead.firstName} {lead.lastName}
      </StyledLink>
    ),
  },
  {
    title: t('admin.table.email'),
    dataIndex: 'email',
    className: 'leads-table-cell',
    key: 'email',
  },
  {
    title: t('admin.table.phone'),
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: t('vendor.deals.country'),
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: t('admin.table.company'),
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: t('form.tech_stack'),
    key: 'techStack',
    className: 'leads-table-cell',
    render: (lead: LeadDetails) => {
      return (
        <ul>
          {lead.techStack?.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      );
    },
  },
  {
    title: t('form.message'),
    dataIndex: 'message',
    key: 'message',
  },
  {
    key: 'actions',
    render: (lead: LeadDetails) => {
      return <LeadActions lead={lead} />;
    },
  },
];
