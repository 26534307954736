import React from 'react';
import { StyledFreemiumRow } from '../../ProjectsPage.styles';
import { FreeVersionFrame } from './FreeVersionFrame';
import { UpgradeFrame } from './UpgradeFrame';

export const FreemiumView: React.FC = () => {
  return (
    <StyledFreemiumRow>
      <UpgradeFrame />
      <FreeVersionFrame />
    </StyledFreemiumRow>
  );
};
