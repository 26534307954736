import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import { MetaTags } from '@/shared/seo';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import circleGradient from '@/assets/images/landingPage/circleGradient.svg';
import { OurSolution } from './Areas/OurSolution/OurSolution';
import { Institutions } from './Areas/Institutions/Institutions';
import { Intro } from './Areas/Intro/Intro';
import { GetStarted } from './Areas/GetStarted/GetStarted';
import { Importance } from './Areas/Importance/Importance';
import { OurFeatures } from '../Common/Features/Features';
import { LandingPageTemplate } from '../LandingPageTemplate';
import { Trusted } from '../Common/Trusted/Trusted';
import { isMobileOnly } from 'react-device-detect';

export const MainLandingPage: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);

  return (
    <>
      <MetaTags
        title="landing_page.intro.title2.empowering"
        description="landing_page.home.description"
      />
      <LandingPageTemplate>
        <StyledCol $gap={isMobile ? 80 : 140}>
          <Intro />
          <Trusted
            $title={t('landing_page.trusted.title')}
            $isStretched={true}
            $isSlider={!isMobileOnly}
          />
        </StyledCol>
        <OurSolution />
        <Institutions />
        <OurFeatures $bgImg={isDarkTheme ? circleGradient : undefined} />
        <Importance />
        <GetStarted />
      </LandingPageTemplate>
    </>
  );
};
