import { useEffect, useState } from 'react';
import { useAdminsStore } from '@/stores/AdminsStore';
import { Panel } from '../../SearchPanel/Panel';
import { VendorsTable } from './VendorsTable/VendorsTable';
import { AddUserModal } from '@/shared/components/Modals/UserActionModals/AddUserModal';
import { AddVendorForm } from '@/shared/components/Forms/VendorForms/AddVendor';
import { PanelType } from '../../SearchPanel/Panel.consts';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '@/hooks/Auth/usePermissions';
import { AdminPermission } from '../Admin.consts';

export const VendorsSection = () => {
  const adminsStore = useAdminsStore();
  const [vendorName, setName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    adminsStore.getVendors();
  }, []);

  const getVendor = (vendorName: string) => {
    setName(vendorName);
    adminsStore.getVendorByName(vendorName);
  };

  const changePage = (currentPage: number) => {
    adminsStore.getVendors(currentPage, vendorName);
  };
  const canCreateVendor = useHasPermission(AdminPermission.CREATE_VENDOR);

  return (
    <Panel
      isLoading={adminsStore.isLoading}
      panelType={PanelType.VENDORS}
      title={t('admin.vendros.title')}
      name={vendorName}
      totalResults={adminsStore.totalVendors}
      createButton={
        <AddUserModal
          btnText={t('admin.vendros.add_vendor')}
          btnDisabled={!canCreateVendor}
          form={<AddVendorForm />}
        />
      }
      table={<VendorsTable />}
      changePage={changePage}
      getSearch={getVendor}
    />
  );
};
