import React from 'react';
import type { MenuProps } from 'antd';
import { SIDEMENU_NAVIGATION, UserRole } from '@/shared/consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useSubscription } from '@/hooks/useSubscription';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useLocalStorage } from '@/hooks/Storage/useLocalStorage';
import { SideMenu } from './SideMenu';
import { useUserStore } from '@/stores/UserStore';
import { StorageKeys } from '@/hooks/Storage/Storage.consts';

export const ClientSideMenu: React.FC = () => {
  const subscription = useSubscriptionStore();
  const { getSubscription } = useSubscription();
  const { removeStorage } = useLocalStorage();
  const userRole = useUserStore((state) => state.role);

  const defaultPage =
    subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION ||
    userRole === UserRole.ENTERPRISE
      ? SIDEMENU_NAVIGATION.PROJECTS
      : SIDEMENU_NAVIGATION.PRICING;

  const beforeOnClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case SIDEMENU_NAVIGATION.PRICING:
        removeStorage(StorageKeys.SELECTED_PACKAGE);
        break;
      case SIDEMENU_NAVIGATION.PROJECTS:
      case SIDEMENU_NAVIGATION.BILLING:
      case SIDEMENU_NAVIGATION.USERS:
        getSubscription();
        break;
    }
  };

  return subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ? (
    <></>
  ) : (
    <SideMenu beforeOnClick={beforeOnClick} defaultPage={defaultPage} />
  );
};
