import React, { useEffect, useState } from 'react';
import { StyledModal } from '../Modals.styles';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { U1Button } from '../../Button/U1Button';
import { StyledCol, StyledRow, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { StyledPackageTitle } from '@/shared/styles';
import { useSubscription } from '@/hooks/useSubscription';
import { RenewSubscriptionModalProps } from '../Modals.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { getRenewDate } from '@/shared/utils';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';

export const RenewSubscriptionModal: React.FC<RenewSubscriptionModalProps> = ({
  $buttonType,
}: RenewSubscriptionModalProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { activateSubscription, activateResponse } = useSubscription();
  const subscription = useSubscriptionStore();
  const { t } = useTranslation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    activateSubscription();
  };

  useEffect(() => {
    if (activateResponse?.status === 200) {
      setOpen(false);
      setConfirmLoading(false);
    }
  }, [activateResponse]);

  return (
    <U1Dialog name={ComponentName.RENEW_SUBSCRIPTION_DIALOG}>
      {$buttonType === 'renew' ? (
        <U1Button
          dataTestId="renewSubscriptionButton"
          text={t('billing.no_active_packages.renew_subscription')}
          $height="45px"
          $width="100%"
          onClick={showModal}
          triggerRef="renew-subscription"
        />
      ) : (
        <GradientBorderButton
          dataTestId="activateSubscriptionButton"
          text={t('billing.package_details.activate_subscription')}
          $width="100%"
          $padding="12px 60px"
          onClick={showModal}
          triggerRef="renew-subscription"
        />
      )}

      <StyledModal
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
        data-a11y-ref="renew-subscription"
        $width={557}
      >
        <StyledCol $gap={44}>
          <StyledCol $gap={24}>
            <StyledRow $justify="center">
              <StyledTitle $size={17} $margin="0px">
                {t('billing.no_active_packages.renew_subscription.modal.title1')}
                <StyledPackageTitle $size={17}> {subscription.name} </StyledPackageTitle>
                {t('billing.no_active_packages.renew_subscription.modal.title2')}
              </StyledTitle>
            </StyledRow>
            <StyledSecondaryText fontSize="15" fontWeight={400}>
              {t('billing.no_active_packages.renew_subscription.modal.content')}{' '}
              {getRenewDate(subscription.expiryDate)}
            </StyledSecondaryText>
          </StyledCol>

          <StyledRow $gap={20}>
            <GradientBorderButton
              dataTestId="cancelRenewButton"
              text={t('cancel')}
              $height="40px"
              $width="100%"
              $padding="0px 40px"
              onClick={handleCancel}
            />
            <U1Button
              dataTestId="confirmRenewButton"
              text={t('billing.no_active_packages.renew_subscription.modal.confirm_renew')}
              $height="40px"
              $width="100%"
              $padding="0px 30px"
              htmlType="submit"
              onClick={handleOk}
            />
          </StyledRow>
        </StyledCol>
      </StyledModal>
    </U1Dialog>
  );
};
