export const PolicyListType = {
  NUMBERS: 'NUMBERS',
  BULLETS: 'BULLETS',
  ROMAN: 'ROMAN',
  UNSTYLED: 'UNSTYLED',
};

export type PolicyList = {
  type: string;
  list: (string | PolicyList)[]; // Allow for nested lists
};

export type PolicyContent = {
  title?: string;
  sections: (string | PolicyList | PolicyContent)[];
};

export type PolicySectionProps = {
  title?: string;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  $flex?: 'start' | 'center' | 'end';
  content?: (string | PolicyList | PolicyContent)[];
};
