import { Route } from 'react-router-dom';
import { RoleRoute } from './RoleRoute';
import { PermissionRoute } from './PermissionRoute';
import { ProtectedRouteOption } from './ProtectedRoute.consts';

export const ProtectedRoute = ({ path, element, role, permission }: ProtectedRouteOption) => {
  return (
    <Route
      key={path}
      path={path.replace('/', '')}
      element={
        role ? (
          <RoleRoute role={role}>{element}</RoleRoute>
        ) : permission ? (
          <PermissionRoute permission={permission}>{element}</PermissionRoute>
        ) : (
          element
        )
      }
    />
  );
};

export const ProtectedRoutes = (protectedRoutes: ProtectedRouteOption[], langPrefix?: string) => (
  <Route key={langPrefix || 'default'} path={`/${langPrefix || ''}`}>
    {protectedRoutes.map(({ path, element, permission }) =>
      ProtectedRoute({
        path,
        element,
        permission,
      })
    )}
  </Route>
);
