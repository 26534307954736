import { U1Button } from '@/shared/components/Button/U1Button';
import { Icon } from '@/shared/components/Icon';
import { StyledCol, StyledContent, StyledOrangeGradTitle } from '@/shared/styles';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import React from 'react';
import { StyledPayment, StyledPaymentCard, StyledPaymentFailed } from '../Payment.styles';
import { useTranslation } from 'react-i18next';
import { failedPaymentImgUrl } from '@/assets/images';

export const PaymentFailed: React.FC = () => {
  const setPaymentStatus = usePaymentStore((state) => state.setPaymentStatus);
  const { t } = useTranslation();

  return (
    <StyledPayment>
      <StyledPaymentCard>
        <StyledPaymentFailed>
          <Icon $src={failedPaymentImgUrl} />
          <StyledCol $gap={10} $align="center">
            <StyledOrangeGradTitle $size={20}>{t('payment.payment_failed')}</StyledOrangeGradTitle>
            <StyledContent fontSize={'17px'}>{t('payment.payment_failed.content')}</StyledContent>
            <U1Button
              dataTestId="tryAgainButton"
              text={t('payment.payment_failed.try_again')}
              $width={'370px'}
              $height={'50px'}
              onClick={() => setPaymentStatus(Payment_Status.NOT_PAYED)}
            />
          </StyledCol>
        </StyledPaymentFailed>
      </StyledPaymentCard>
    </StyledPayment>
  );
};
