import { create } from 'zustand';
import { _axios } from '@/services/apiClient';
import { LeadDetails, LeadStore, TABLE_PAGINATION_LIMIT } from './LeadStore.consts';
import { buildQueryString } from '@/shared/utils';

export const useLeadsStore = create<LeadStore>((set, get) => ({
  leads: [],
  isLoading: true,
  currentPage: 1,
  searchColumn: 'email',
  currentLimit: TABLE_PAGINATION_LIMIT,
  getLeads: () => {
    const { currentPage, currentLimit, searchColumn, searchValue } = get();

    const query = buildQueryString({
      skip: (currentPage - 1) * currentLimit,
      limit: currentLimit,
      [searchColumn]: searchValue,
    });

    _axios.get(`leads?${query}`).then((res) => {
      set((state) => ({
        ...state,
        leads: res.data.data.map((item: LeadDetails) => ({
          ...item,
          key: item.id,
        })),
        pagination: res.data.pagination,
        isLoading: false,
      }));
    });
  },
  setCurrentPage(value) {
    if (get().currentPage != value) {
      set((state) => ({ ...state, currentPage: value }));
    }
  },
  setSearchValue(value: string) {
    const searchValue = get().searchValue;
    if (searchValue !== value) {
      set((state) => ({ ...state, searchValue: value }));
    }
  },
}));
