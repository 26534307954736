import React from 'react';
import { ProtectedActionsDropdown } from '@/shared/components/Dropdown/ProtectedActionsDropdown';
import { getLeadActions, LeadActionsDropdownProps } from './LeadActions.consts';
import { useLeadsStore } from '@/stores/LeadStore/LeadStore';

export const LeadActionsDropdown: React.FC<LeadActionsDropdownProps> = ({
  setIsOpen,
  lead,
}: LeadActionsDropdownProps) => {
  const getLeads = useLeadsStore((state) => state.getLeads);

  return (
    <ProtectedActionsDropdown actions={getLeadActions({ lead, getLeads })} setIsOpen={setIsOpen} />
  );
};
