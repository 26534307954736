import { PAGES, UserRole } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { StyledWhiteTitle } from '@/shared/pages/Pricing/Intro/Intro.styles';
import { Pricing } from '@/shared/pages/Pricing/Pricing';
import { StyledOrangeGradTitle } from '@/shared/styles';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useUserStore } from '@/stores/UserStore';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const PricingPage: React.FC = () => {
  const subscription = useSubscriptionStore();
  const userRole = useUserStore((state) => state.role);
  const { t } = useTranslation();

  return userRole !== UserRole.ENTERPRISE &&
    (subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ||
      subscription.subscriptionStatus === SUB_STATUS.NO_SUBSCRIPTION ||
      subscription.subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED ||
      subscription.isFreemium) ? (
    <Pricing>
      <StyledOrangeGradTitle $size={24}>
        {subscription.isFreemium
          ? t('pricing.package.upgrade.title')
          : t('pricing.package.no_packages.title')}
      </StyledOrangeGradTitle>
      <StyledWhiteTitle level={2} $size={16} $width={isMobile ? '100%' : '410px'}>
        {t('pricing.package.no_packages.content')}
      </StyledWhiteTitle>
    </Pricing>
  ) : (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
