import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { getCookie } from './cookieService';
import { COOKIES } from '@/shared/consts';
import { getLanguage } from '@/shared/utils';

export const _axios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}`,
});

export const _axiosNoHeader = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}`,
});

_axios.interceptors.request.use(
  (request) => {
    const accessToken = getCookie(COOKIES.ACCESS_TOKEN);
    if (accessToken && request.headers) request.headers.Authorization = `Bearer ${accessToken}`;

    request.headers['Accept-Language'] = getLanguage();

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useAxios = makeUseAxios({
  axios: _axios,
});
