import { PageGroupDto, PageGroupName, PageProps } from './PageGroupsStore.consts';

export const getPages = (data: PageGroupDto[], pageGroupName: PageGroupName): PageProps[] => {
  return (data.find((item: PageGroupDto) => item.code === pageGroupName) as PageGroupDto).pages.map(
    (page: PageProps) => ({
      ...page,
      to: `${pageGroupName}/${page.code}`,
    })
  );
};
