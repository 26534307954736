import { LinkProps } from '@/shared/props';
import { StyledPinkGradientLink } from './Links.styles';
import { v4 as uuidv4 } from 'uuid';
import { U1Button } from '@u1/react-a11y-hooks';

export const PinkGradientLink: React.FC<LinkProps> = ({
  text,
  href,
  dataTestId,
  children,
  inRow,
  weight,
  onClick,
  isButton,
  triggerRef,
  className,
  shouldOpenTab = true,
  excludeEl = false,
  ...rest
}: LinkProps) => {
  const openTab = shouldOpenTab ? { target: '_blank' } : {};
  const trigger = triggerRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': triggerRef } : {};
  const props = {
    'data-testid': dataTestId,
    href,
    inRow,
    weight,
    className,
    onClick,
    'data-u1-exclude': excludeEl,
    ...openTab,
    ...trigger,
    ...rest,
  };

  return isButton ? (
    <U1Button key={uuidv4()}>
      <StyledPinkGradientLink {...props} data-u1-button="button">
        {children && children}
        <span>{text}</span>
      </StyledPinkGradientLink>
    </U1Button>
  ) : (
    <StyledPinkGradientLink {...props} key={uuidv4()}>
      {children && children}
      <span>{text}</span>
    </StyledPinkGradientLink>
  );
};
