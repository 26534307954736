import { StyledCol, StyledOrangeGradTitle, StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import {
  StyledCursor,
  StyledIntro,
  StyledSecondRow,
  StyledTypewriterContainer,
} from '../MainPage.styles';
import Typewriter from 'typewriter-effect';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Icon } from '@/shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { appPreviewUrl } from '@/assets/images';

export const MainPageIntro: React.FC = () => {
  const [isDoneTyping, setIsDoneTyping] = useState(false);
  const [hidecursor, setHideCursor] = useState(false);
  const { t, i18n } = useTranslation();
  const [typeWriterKey, setTypeWriterKey] = useState(0);

  const typeWriterTitle = t('homepage.app_preview.type_writer');
  const typeWriterTitle2 = t('homepage.app_preview.type_writer2');
  const fullTitle = typeWriterTitle + typeWriterTitle2;

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      if (isDoneTyping) {
        setHideCursor((prev) => !prev);
      }
    }, 500);
    return () => clearInterval(cursorInterval);
  }, [isDoneTyping]);

  useEffect(() => {
    setIsDoneTyping(false);
    setTypeWriterKey((prevKey) => prevKey + 1);
  }, [i18n.language]);

  return (
    <StyledCol $width="100%" $align="center" $gap={70}>
      <StyledIntro>
        {/* intro */}
        <StyledOrangeGradTitle $size={34}>{t('homepage.app_preview.title')}</StyledOrangeGradTitle>
        <StyledTitle level={2} aria-label={fullTitle}>
          <MobileView>{fullTitle}</MobileView>
          <BrowserView>
            <StyledTypewriterContainer>
              <Typewriter
                key={typeWriterKey}
                options={{
                  cursorClassName: 'type-cursor',
                  wrapperClassName: 'type-wrapper',
                  delay: 50,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(typeWriterTitle)
                    .callFunction(() => setIsDoneTyping(true))
                    .start();
                }}
              />

              <StyledSecondRow>
                <Typewriter
                  key={typeWriterKey + 1}
                  options={{
                    cursorClassName: 'type-cursor',
                    wrapperClassName: 'type-wrapper',
                    delay: 50,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(2630)
                      .typeString(typeWriterTitle2)
                      .callFunction(() => setIsDoneTyping(true))
                      .start();
                  }}
                />
                <StyledCursor hidecursor={hidecursor}>{isDoneTyping ? '_' : ''}</StyledCursor>
              </StyledSecondRow>
            </StyledTypewriterContainer>
          </BrowserView>
        </StyledTitle>
      </StyledIntro>

      {/* app preview + background shape */}
      <Icon
        $alt={t('homepage.app_preview.alt')}
        $src={appPreviewUrl}
        $width={isMobile ? 328 : 854}
        $maxWidth={854}
        $height={isMobile ? 184 : 478}
        $loading="lazy"
      />
    </StyledCol>
  );
};
