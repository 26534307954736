import { StyledCard, StyledText, StyledTitle } from '@/shared/styles';
import {
  StyledCircleFlag,
  StyledClientText,
  StyledClientTitle,
  StyledDealList,
  StyledDealListItem,
} from '../DealsPayment.styles';
import { hasFlag } from 'country-flag-icons';
import countryList from 'react-select-country-list';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { FlagsList, loadFlags } from '@/shared/utils/flags';

export const ClientDetailsSection: React.FC = () => {
  const deals = useDealStore();
  const [flags, setFlags] = useState<{
    rectangle?: FlagsList;
    square?: FlagsList;
  }>({});

  useEffect(() => {
    const load = async () => {
      const { rectangle, square } = await loadFlags(['rectangle', 'square']);
      setFlags({ rectangle, square });
    };

    load();
  }, []);

  const clientCountryCode = deals.client?.country as string | undefined;

  const PhoneFlag = useMemo(
    () => (flags.rectangle && clientCountryCode ? flags.rectangle[clientCountryCode] : null),
    [flags.rectangle, clientCountryCode]
  );
  const CountryFlag = useMemo(
    () => (flags.square && clientCountryCode ? flags.square[clientCountryCode] : null),
    [flags.square, clientCountryCode]
  );
  const isFlagAvailable = useMemo(
    () => clientCountryCode && hasFlag(clientCountryCode),
    [clientCountryCode]
  );

  const clientCountry = useMemo(
    () => clientCountryCode && countryList().getLabel(clientCountryCode),
    [clientCountryCode]
  );
  const { t } = useTranslation();

  return (
    <StyledCard $width="100%" $padding={40} $align="start" $gap={32}>
      <StyledTitle $size={18} level={2}>
        {t('vendor.panel.deals.create_deal.client_details')}:
      </StyledTitle>
      <StyledDealList>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.company_name')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.company}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.admin_name')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.fullname}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.email')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.email}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.phone_number')}</StyledClientTitle>
          <StyledClientText>
            {isFlagAvailable && PhoneFlag && (
              <PhoneFlag aria-hidden="true" title={clientCountryCode} width={25} />
            )}
            {deals.client?.phone}
          </StyledClientText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.country')}</StyledClientTitle>
          <StyledClientText>
            {isFlagAvailable && CountryFlag && (
              <StyledCircleFlag aria-hidden="true">
                <CountryFlag title={clientCountryCode} width={25} />
              </StyledCircleFlag>
            )}
            {clientCountry}
          </StyledClientText>
        </StyledDealListItem>
      </StyledDealList>
    </StyledCard>
  );
};
