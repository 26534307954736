import React, { useState } from 'react';
import { StyledCard, StyledCol, StyledText, StyledTitle } from '@/shared/styles';
import { U1Logo } from '@/shared/components/U1Logo';
import { SignInForm } from '@/shared/components/Forms/SignIn/SignInForm';
import { Mfa } from './MFA/Mfa';
import { SIGN_IN_VIEW, SignInProps } from './SignIn.consts';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { EmailSent } from './ForgotPassword/EmailSent';
import { StyledSignIn } from './SignIn.styles';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

export const SignIn: React.FC<SignInProps> = ({ userType, children }: SignInProps) => {
  const [signInView, setView] = useState(SIGN_IN_VIEW.MAIN);
  const { t } = useTranslation();

  return signInView === SIGN_IN_VIEW.RESET_PASSWORD ? (
    <ForgotPassword userType={userType} setView={setView} />
  ) : signInView === SIGN_IN_VIEW.EMAIL_SENT ? (
    <EmailSent setView={setView} />
  ) : signInView === SIGN_IN_VIEW.MFA ? (
    <Mfa />
  ) : (
    <StyledSignIn>
      <StyledCard
        $self="center"
        $padding={40}
        $gap={32}
        $width={isMobileOnly ? '100%' : 'fit-content'}
      >
        <U1Logo $size={42} $autofocus={true} />
        <StyledCol $align="center" $gap={8}>
          <StyledTitle $size={28}>{t('header.sign_in')}</StyledTitle>
          <StyledText fontSize={'16px'} fontWeight={400}>
            {children}
          </StyledText>
        </StyledCol>
        <SignInForm userType={userType} setView={setView} />
      </StyledCard>
    </StyledSignIn>
  );
};
