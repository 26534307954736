import React, { useState } from 'react';
import { UserActions } from '../../../../SearchPanel/Actions/UserActions';
import { LeadActionsDropdown } from './LeadActionsDropdown';
import { LeadActionsDropdownProps } from './LeadActions.consts';

export const LeadActions: React.FC<LeadActionsDropdownProps> = ({
  lead,
}: LeadActionsDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UserActions
      actionsDropdown={<LeadActionsDropdown setIsOpen={setIsOpen} lead={lead} />}
      name={lead.companyName}
      dataTestId="manageUserButton"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
