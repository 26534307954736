import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { emailExp, fullNameExp, phoneNumberRegex } from '@/shared/regex';
import { StyledCol, StyledInput, StyledTextArea } from '@/shared/styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledForm, StyledLabel } from '../Forms.styles';
import { FormProps } from '../Forms.consts';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useTranslation } from 'react-i18next';
import { extractErrors, scrollToRef } from '@/shared/utils';
import { FormError } from '../FormError';
import { getResource } from './ContactUs.utils';

export const ContactUsForm: React.FC<FormProps> = ({ onOk }: FormProps) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();
  const errorRef = useRef<HTMLDivElement>(null);

  const [{ response: contactUsRes, error: contactUsErr }, sendMessage] = useAxios(
    { method: 'post', url: 'support/contact-us' },
    { manual: true }
  );

  useEffect(() => {
    if (contactUsRes?.status === RES_STATUS.CREATED && onOk) {
      onOk();
    }
  }, [contactUsRes]);

  useEffect(() => {
    if (contactUsErr) {
      if (contactUsErr.status === RES_STATUS.UNPROCESSABLE) {
        setErrorMsg(extractErrors(contactUsErr.response));
      } else {
        setErrorMsg(t('form.something_went_wrong'));
      }
      scrollToRef(errorRef);
    }
  }, [contactUsErr]);

  const onFinish = (values: any) => {
    sendMessage({
      data: {
        fullName: values.fullname.trim(),
        email: values.email.trim(),
        phone: values.phone.trim(),
        companyName: values.company?.trim(),
        message: values.message,
        resource: getResource(),
      },
    });
  };

  return (
    <StyledForm $marginTop={'20px'} name="basic" onFinish={onFinish} autoComplete="off">
      {errorMsg && <FormError $ref={errorRef} message={errorMsg} />}
      <StyledCol $width="100%" $gap={8}>
        <StyledLabel>{t('form.full_name')}*</StyledLabel>
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: `${t('form.full_name')} ${t('form.required')}`,
              pattern: fullNameExp,
            },
          ]}
        >
          <StyledInput data-testid="nameField" placeholder={t('form.placeholder.name')} />
        </Form.Item>

        <StyledLabel>{t('form.email')}*</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('form.email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput data-testid="emailField" placeholder={t('form.email.placeholder')} />
        </Form.Item>

        <StyledLabel>{t('vendor.deals.phone_number')}*</StyledLabel>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: t('vendor.deals.phone_number.required'),
              pattern: phoneNumberRegex,
            },
          ]}
        >
          <StyledInput placeholder={t('vendor.deals.phone_number.placeholder')} />
        </Form.Item>

        <StyledLabel>{t('vendor.deals.company_name')}</StyledLabel>
        <Form.Item name="company">
          <StyledInput placeholder={t('vendor.deals.company_name')} />
        </Form.Item>

        <StyledLabel>{t('form.message')}</StyledLabel>
        <Form.Item
          name="message"
          rules={[{ required: true, message: `${t('form.message')} ${t('form.required')}` }]}
        >
          <StyledTextArea data-testid="messageField" placeholder={t('form.placeholder.message')} />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <U1Button dataTestId="sendMessageButton" text={t('form.send_message')} htmlType="submit" />
      </Form.Item>
    </StyledForm>
  );
};
