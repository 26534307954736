export const fullNameExp = /^\p{L}{1,20}(\s+\p{L}{1,20})+$/u;
export const emailExp = /[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}[\s]*$/;
export const domainRegex =
  /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+)$/;
export const comaRegex = /,([^,]*)$/;
export const removeProtocolPrefixRegex = /^(https?:\/\/)/;
export const phoneNumberRegex =
  /^\+(?:\d{1,3}\s?)?(?:\d{1,4}\s?)?(?:\(\d{1,5}\)\s?)?\d{1,10}[\s\-]?\d{1,10}$/;
export const eightCharsExp = /.{8,}/;
export const oneLowerCaseExp = /^(?=.*[a-z]).*$/;
export const oneUpperCaseExp = /^(?=.*[A-Z]).*$/;
export const oneNumberExp = /^(?=.*\d).*/;
export const appIdExp =
  /^[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])*(\.[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])*)+$/;
export const boldWordsRegex = /(\*\*.*?\*\*)/;
export const mailtoRegex = /\{\{mailto\}\}/g;
export const linkRegex = /\{\{([^{}|]+)\|([^{}|]+)\}\}/;
export const linkedinUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[A-Za-z0-9_-]+\/?$/;
export const hexColorRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
export const rgbColorRegex = /^rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\)$/;
export const hslColorRegex = /^hsl\(\d{1,3},\s*\d{1,3}%,\s*\d{1,3}%\)$/;

//px
export const digitsOnly200MaxRegex = /^(?:[1-9]|[1-9][0-9]|1[0-9]{2}|200)$/;
export const digitsOnly900MaxRegex = /^(?:[0-9]|[1-9][0-9]|[1-8][0-9]{2}|900)$/;
export const digitsOnly15MaxRegex = /^(?:[1-9]|1[0-5])$/;
export const digitsOnly5MaxRegex = /^[0-5]$/;
//rem
export const decimalNum5MaxRegex = /^(?:[0-4](?:\.\d{0,2})?|5(\.0{1,2})?|0(\.\d{0,2})?)$/;
export const decimalNum1MaxRegex = /^(0(\.\d{0,2})?|1(\.0{1,2})?)$/;
