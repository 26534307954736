import { useHasPermission } from '@/hooks/Auth/usePermissions';
import { PAGES } from '@/shared/consts';
import { AdminNotFound } from '@/shared/pages/NotFound/AdminNotFound';

export const PermissionRoute = ({
  permission,
  children,
}: {
  permission: string;
  children: JSX.Element;
}) => {
  return useHasPermission(permission) ? (
    children
  ) : (
    <AdminNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
