import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Trusted } from '../../../Common/Trusted/Trusted';
import { isMobileOnly } from 'react-device-detect';

export const Organizations: React.FC = () => {
  const { isDesktop, isWideDesktop } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <Trusted
      $title={t('landing_page.enterprise.organizations.title')}
      $isSlider={!isDesktop && !isWideDesktop && !isMobileOnly}
      $isStretched={true}
    />
  );
};
