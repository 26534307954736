import { StyledCol, StyledSeparator } from '@/shared/styles';
import styled from 'styled-components';
import { ArrowDownIcon } from '@/assets/images/icons';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { AccordionItemProps } from './Accordion.consts';

export const StyledAccordionList = styled(StyledCol)`
  width: 100%;
  align-items: flex-start;
`;

export const StyledAccordionItem = styled(StyledCol)`
  width: 100%;
  font-size: 16px;

  hr {
    margin: 32px 0px;
  }
`;

export const StyledAccordionButton = styled.button<Pick<AccordionItemProps, 'showDescription'>>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
  flex-direction: row;
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'U1Poppins';
  color: ${(props) => props.theme.textColor};

  &::after {
    content: url('${ArrowDownIcon}');
    margin-right: 17px;
    transform: ${(props) => (props.showDescription ? 'rotate(180deg)' : '')};
    transition: transform 200ms ease-out;
    ${() => !useThemeStore.getState().isDarkMode && `filter: invert(100%);`}
  }
`;

export const StyledAccordionContentContainer = styled.div`
  overflow: hidden;
`;

export const StyledAccordionContentText = styled.p<Pick<AccordionItemProps, 'showDescription'>>`
  margin: ${(props) => (props.showDescription ? '16px 0px' : '0')};
  padding: ${(props) => (props.showDescription ? '0 0 0 4px' : '0')};
  height: ${(props) => (props.showDescription ? 'auto' : '0')};
  overflow: hidden;
  transition: all 200ms ease-out;
  font-weight: 400;
`;

export const StyledAccordionItemSeparator = styled(StyledSeparator)`
  border-top: solid ${(props) => props.theme.accordionBorder};
  width: 100%;
`;
