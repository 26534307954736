import { SelectionProps } from '@/shared/props';
import styled from 'styled-components';

export const StyledDonutChart = styled.div`
  width: 265px;
  height: 265px;
`;

export const StyledOuterCircle = styled.circle<SelectionProps>`
  fill: transparent;
  ${(props) => !props.isSelected && `stroke: ${props.theme.marginsBorder}`};
`;

export const StyledInnerCircle = styled.circle<SelectionProps>`
  fill: transparent;
  stroke: ${(props) => (props.isSelected ? props.theme.marginsBorder : props.theme.marginsColor)};
`;

export const StyledSvgGroup = styled.g<{ $color: string }>`
  ${(props) => `&:hover {
    filter: drop-shadow(0px 0px 3px rgb(${props.$color}, 0.3));
  }`}
`;

export const StyledDonutSVG = styled.svg`
  height: 100%;
  width: 100%;
`;

export const StyledDonutText = styled.text`
  fill: ${(props) => props.theme.textColor};
  font-size: 8px;
`;

export const StyledTopText = styled.tspan`
  font-weight: 600;
`;

export const StyledBottomText = styled.tspan`
  font-weight: 400;
`;
