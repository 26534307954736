import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import React, { useState } from 'react';
import { U1Button } from '../../Button/U1Button';
import { StyledModal } from '../Modals.styles';
import { AddUserProps } from './UserModals.consts';

export const AddUserModal: React.FC<AddUserProps> = ({
  form,
  btnText,
  btnDisabled,
}: AddUserProps) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <U1Dialog name={ComponentName.ADD_DIALOG}>
      <U1Button
        text={btnText}
        $height="50px"
        $width="188px"
        onClick={showModal}
        disabled={btnDisabled || false}
        triggerRef="add-user"
      />
      <StyledModal
        data-a11y-ref="add-user"
        open={open}
        onCancel={handleCancel}
        footer={[]}
        $width={557}
      >
        {React.cloneElement(form, {
          onCancel: handleCancel,
        })}
      </StyledModal>
    </U1Dialog>
  );
};
