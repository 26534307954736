import { getFirstDayOfMonth, numOfDaysInMonth } from '../Datepicker.utils';
import { StyledTable } from '../Datepicker.styles';
import { DatepickerDay } from './DatepickerDay';
import { useDatepickerStore } from '@/stores/DatepickerStore/DatepickerStore';
import { weekDays } from '../Datepicker.consts';

export const DatepickerGrid: React.FC = () => {
  const { year, month } = useDatepickerStore();

  const getDays = () => {
    const firstDay = getFirstDayOfMonth(year, month);
    const daysInMonth = numOfDaysInMonth(year, month);
    const daysInPrevMonth = numOfDaysInMonth(year, month - 1);
    const startDayIndex = firstDay === 0 ? 6 : firstDay - 1; // Starting from Monday

    const components = [];
    let dayCounter = daysInPrevMonth - startDayIndex + 1;

    // Previous month
    for (let i = 0; i < startDayIndex; i++) {
      components.push({ key: dayCounter, className: 'disabled-day prev-month' });
      dayCounter++;
    }

    // Current month
    for (let i = 1; i <= daysInMonth; i++) {
      components.push({ key: i, className: '' });
      dayCounter++;
    }

    // Next month
    const remainingDays = 35 - components.length;
    for (let i = 1; i <= remainingDays; i++) {
      components.push({ key: i, className: 'disabled-day next-month' });
    }
    return components;
  };

  const getWeekDays = () => {
    const allDays = getDays();
    const chunkedArray = [];
    for (let i = 0; i < allDays.length; i += 7) {
      chunkedArray.push(allDays.slice(i, i + 7));
    }
    return chunkedArray;
  };

  return (
    <StyledTable>
      <tr>
        {weekDays.map((day, i) => (
          <th key={`day-${i}`}>{day}</th>
        ))}
      </tr>
      {getWeekDays().map((days, i) => (
        <tr key={`row-${i}`}>
          {days.map((day) => (
            <DatepickerDay key={day.key} day={day.key} className={day.className} />
          ))}
        </tr>
      ))}
    </StyledTable>
  );
};
