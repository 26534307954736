import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper {
    color: ${(props) => props.theme.secondaryTextColor};
    font-weight: 400;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked {
    color: ${(props) => props.theme.textColor} !important;
  }

  .ant-radio-inner {
    border-radius: 12px;
    border: transparent 1px solid;
    background: linear-gradient(
          ${(props) => props.theme.cardsColor},
          ${(props) => props.theme.cardsColor}
        )
        padding-box,
      ${(props) => props.theme.buttonsBG} border-box;
  }

  .ant-radio-wrapper-checked .ant-radio-inner {
    background: ${(props) => props.theme.buttonsBG} border-box;
  }

  .ant-radio-inner::after {
    background: ${(props) => props.theme.textColor};
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-wrapper:hover {
    border-color: transparent !important;
  }

  img {
    margin-left: 5px;
  }
`;
